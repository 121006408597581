export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
}

export type Query = {
  __typename?: 'Query'
  activeFilter?: Maybe<SavedFilter>
  aiOperatorProcessedIncidentCount?: Maybe<Scalars['Int']>
  alarms?: Maybe<AlarmConnection>
  alerts?: Maybe<AlertConnection>
  audioClips?: Maybe<AudioClipConnection>
  audioMessages?: Maybe<AudioMessageConnection>
  cameraLiveFeedDetail?: Maybe<CameraLiveFeedDetail>
  cameraLiveFeedDetails?: Maybe<CameraLiveFeedDetailConnection>
  case?: Maybe<Case>
  cases?: Maybe<CaseConnection>
  charges?: Maybe<ChargeConnection>
  checkedInUsers?: Maybe<UserConnection>
  contributingEvents?: Maybe<EventConnection>
  /** Organization of authenticated user. */
  currentOrganization?: Maybe<Organization>
  device?: Maybe<Device>
  deviceGroups?: Maybe<DeviceGroupConnection>
  devices?: Maybe<DeviceConnection>
  door?: Maybe<Door>
  edgeDevice?: Maybe<EdgeDevice>
  edgeDevices?: Maybe<EdgeDeviceConnection>
  emergencyCall?: Maybe<EmergencyCall>
  emergencyCallingAccessToken?: Maybe<EmergencyCallingAccessToken>
  emergencyCalls?: Maybe<EmergencyCallConnection>
  events?: Maybe<EventConnection>
  externalAccount?: Maybe<ExternalAccount>
  externalAccountApiKey?: Maybe<ExternalAccountApiKey>
  externalAccountApiKeys?: Maybe<ExternalAccountApiKeyConnection>
  externalAccounts?: Maybe<ExternalAccountConnection>
  facilities?: Maybe<FacilityConnection>
  facility?: Maybe<Facility>
  floor?: Maybe<Floor>
  floorGroups?: Maybe<FloorGroupConnection>
  floorPlan?: Maybe<FloorPlan>
  floorPlans?: Maybe<FloorPlanConnection>
  floors?: Maybe<FloorConnection>
  guardTour: GuardTour
  guardTours: GuardTourConnection
  incident?: Maybe<Incident>
  incidentAction?: Maybe<IncidentAction>
  /**
   * See the counts at the bottom right of
   * <https://projects.invisionapp.com/d/main/default/#/console/21014207/451074845/preview>.
   */
  incidentCounts?: Maybe<IncidentCountMap>
  incidentDeviceMedia?: Maybe<IncidentDeviceMediaConnection>
  incidentGroups?: Maybe<IncidentGroupConnection>
  incidentNotes?: Maybe<IncidentNoteConnection>
  incidents?: Maybe<IncidentConnection>
  incidentTimePeriodStats?: Maybe<IncidentTimePeriodStats>
  kpi?: Maybe<Kpi>
  lastDatabaseReset?: Maybe<DatabaseReset>
  manualIncidentCategories?: Maybe<ManualIncidentCategoryConnection>
  me: User
  messages?: Maybe<SubscriberMessageConnection>
  nearestFacility?: Maybe<Facility>
  outstandingIncidents?: Maybe<IncidentConnection>
  pacsSourceInstance?: Maybe<PacsSourceInstance>
  pacsSourceInstances?: Maybe<PacsSourceInstanceConnection>
  pacsSources?: Maybe<PacsSourceConnection>
  partialCreditCard?: Maybe<PartialCreditCard>
  personOfInterest?: Maybe<PersonOfInterest>
  personsOfInterest?: Maybe<PersonOfInterestConnection>
  /** Not used directly by GraphQL clients. */
  pushedMessage?: Maybe<SubscriberMessage>
  quickReport?: Maybe<QuickReport>
  quickReportCode?: Maybe<QuickReportCode>
  quickReportCodes?: Maybe<QuickReportCodeConnection>
  roles?: Maybe<RoleConnection>
  rule?: Maybe<Rule>
  ruleLogics?: Maybe<RuleLogicConnection>
  rules?: Maybe<RuleConnection>
  savedFilter?: Maybe<SavedFilter>
  savedFilters: SavedFilterConnection
  session?: Maybe<Session>
  standardOperatingProcedure?: Maybe<StandardOperatingProcedure>
  standardOperatingProcedures?: Maybe<StandardOperatingProcedureConnection>
  streamingUrlWrapper?: Maybe<StreamingUrlWrapperInterface>
  tailgateGrid?: Maybe<TailgateGrid>
  technician?: Maybe<Technician>
  technicians?: Maybe<TechnicianConnection>
  tourPlan: TourPlan
  tourPlans: TourPlanConnection
  user?: Maybe<User>
  users?: Maybe<UserConnection>
}

export type QueryActiveFilterArgs = {
  context?: InputMaybe<FilterContext>
}

export type QueryAlertsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<AlertFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<AlertOrderInput>>>
}

export type QueryAudioClipsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<AudioClipFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<AudioClipOrderInput>>>
}

export type QueryAudioMessagesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<AudioMessageFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<AudioMessageOrderInput>>>
}

export type QueryCameraLiveFeedDetailArgs = {
  id: Scalars['ID']
}

export type QueryCameraLiveFeedDetailsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<CameraLiveFeedDetailFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<CameraLiveFeedDetailOrderInput>>>
}

export type QueryCaseArgs = {
  id: Scalars['ID']
}

export type QueryCasesArgs = {
  filter?: InputMaybe<CaseFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryChargesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<ChargeFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<ChargeOrderInput>>>
}

export type QueryCheckedInUsersArgs = {
  facilityId: Scalars['ID']
  orderBy?: InputMaybe<Array<InputMaybe<UserOrderInput>>>
}

export type QueryContributingEventsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<EventFilterInput>
  first?: InputMaybe<Scalars['Int']>
  incidentId: Scalars['ID']
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<EventOrderInput>>>
}

export type QueryDeviceArgs = {
  id: Scalars['ID']
}

export type QueryDeviceGroupsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<DeviceFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<DeviceOrderInput>>>
}

export type QueryDevicesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<DeviceFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<DeviceOrderInput>>>
}

export type QueryDoorArgs = {
  externalId: Scalars['ID']
}

export type QueryEdgeDeviceArgs = {
  id: Scalars['ID']
}

export type QueryEdgeDevicesArgs = {
  filter?: InputMaybe<EdgeDeviceFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<EdgeDeviceOrderInput>>>
}

export type QueryEmergencyCallArgs = {
  id: Scalars['ID']
}

export type QueryEmergencyCallsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<EmergencyCallFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<EmergencyCallOrderInput>>>
}

export type QueryEventsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<EventFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<EventOrderInput>>>
}

export type QueryExternalAccountArgs = {
  id: Scalars['ID']
}

export type QueryExternalAccountApiKeyArgs = {
  id: Scalars['ID']
}

export type QueryExternalAccountApiKeysArgs = {
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryFacilitiesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<FacilityFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<FacilityOrderInput>>>
}

export type QueryFacilityArgs = {
  id: Scalars['ID']
}

export type QueryFloorArgs = {
  id: Scalars['ID']
}

export type QueryFloorGroupsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<FloorFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<FloorOrderInput>>>
}

export type QueryFloorPlanArgs = {
  id: Scalars['ID']
}

export type QueryFloorPlansArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<FloorPlanFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<FloorPlanOrderInput>>>
}

export type QueryFloorsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<FloorFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<FloorOrderInput>>>
}

export type QueryGuardTourArgs = {
  id: Scalars['ID']
}

export type QueryGuardToursArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<GuardTourFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<GuardTourOrderInput>>>
}

export type QueryIncidentArgs = {
  id: Scalars['ID']
}

export type QueryIncidentActionArgs = {
  id: Scalars['ID']
}

export type QueryIncidentDeviceMediaArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<IncidentDeviceMediaFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<IncidentDeviceMediaOrderInput>>>
}

export type QueryIncidentGroupsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<IncidentFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<IncidentOrderInput>>>
}

export type QueryIncidentNotesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<IncidentNoteFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<IncidentNoteOrderInput>>>
}

export type QueryIncidentsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<IncidentFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<IncidentOrderInput>>>
}

export type QueryIncidentTimePeriodStatsArgs = {
  endTime: Scalars['String']
  startTime: Scalars['String']
}

export type QueryManualIncidentCategoriesArgs = {
  input?: InputMaybe<ManualIncidentCategoryFilterInput>
}

export type QueryMessagesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<SubscriberMessageFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<SubscriberMessageOrderInput>>>
  since?: InputMaybe<Scalars['String']>
}

export type QueryNearestFacilityArgs = {
  latitude: Scalars['Float']
  longitude: Scalars['Float']
}

export type QueryOutstandingIncidentsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<IncidentFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<IncidentOrderInput>>>
}

export type QueryPacsSourceInstanceArgs = {
  id: Scalars['ID']
}

export type QueryPersonOfInterestArgs = {
  id: Scalars['ID']
}

export type QueryPersonsOfInterestArgs = {
  filter?: InputMaybe<PersonOfInterestFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<PersonOfInterestOrderInput>>
}

export type QueryQuickReportCodeArgs = {
  id: Scalars['ID']
}

export type QueryQuickReportCodesArgs = {
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<QuickReportCodeOrderInput>>>
}

export type QueryRolesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<RoleFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<RoleOrderInput>>>
}

export type QueryRuleArgs = {
  id: Scalars['ID']
}

export type QueryRuleLogicsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<RuleLogicFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<RuleLogicOrderInput>>>
}

export type QueryRulesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<RuleFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<RuleOrderInput>>>
}

export type QuerySavedFilterArgs = {
  id: Scalars['ID']
}

export type QueryStandardOperatingProcedureArgs = {
  id: Scalars['ID']
}

export type QueryStandardOperatingProceduresArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<StandardOperatingProcedureFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<StandardOperatingProcedureOrderInput>>>
}

export type QueryStreamingUrlWrapperArgs = {
  deviceId: Scalars['ID']
  interval?: InputMaybe<IntervalInput>
}

export type QueryTailgateGridArgs = {
  cameraId: Scalars['ID']
  doorId: Scalars['ID']
}

export type QueryTechnicianArgs = {
  id: Scalars['ID']
}

export type QueryTechniciansArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<TechnicianFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<TechnicianOrderInput>>>
}

export type QueryTourPlanArgs = {
  id: Scalars['ID']
}

export type QueryTourPlansArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<TourPlanFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<TourPlanOrderInput>>>
}

export type QueryUserArgs = {
  id: Scalars['ID']
}

export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<UserFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<UserOrderInput>>>
}

export enum FilterContext {
  Incident = 'INCIDENT',
}

export type SavedFilter = {
  __typename?: 'SavedFilter'
  context: FilterContext
  createdAt: Scalars['String']
  filterCriteria: FilterCriteria
  id: Scalars['ID']
  isActive: Scalars['Boolean']
  name: Scalars['String']
  updatedAt: Scalars['String']
  user?: Maybe<User>
  userId: Scalars['ID']
}

export type FilterCriteria = {
  __typename?: 'FilterCriteria'
  incident?: Maybe<IncidentFilterCriteria>
}

export type IncidentFilterCriteria = {
  __typename?: 'IncidentFilterCriteria'
  AND?: Maybe<Array<Maybe<IncidentFilterCriteria>>>
  createdAtGteInterval?: Maybe<Scalars['Int']>
  deviceIdIn?: Maybe<Array<Scalars['ID']>>
  deviceTypeIn?: Maybe<Array<DeviceType>>
  facilityIdIn?: Maybe<Array<Scalars['ID']>>
  floorIdIn?: Maybe<Array<Scalars['ID']>>
  manualIncidentCategoryIdIn?: Maybe<Array<Scalars['ID']>>
  mutedStatus?: Maybe<MutedStatus>
  OR?: Maybe<Array<IncidentFilterCriteria>>
  ownerId?: Maybe<Scalars['ID']>
  priorityIn?: Maybe<Array<IncidentPriority>>
  sourceIn?: Maybe<Array<IncidentSource>>
  statusIn?: Maybe<Array<IncidentStatus>>
  typeIn?: Maybe<Array<IncidentType>>
}

/** Probably incomplete. */
export enum DeviceType {
  Alarm = 'ALARM',
  Camera = 'CAMERA',
  Checkpoint = 'CHECKPOINT',
  Door = 'DOOR',
}

export enum MutedStatus {
  All = 'ALL',
  Muted = 'MUTED',
  NotMuted = 'NOT_MUTED',
}

export enum IncidentPriority {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
}

export enum IncidentSource {
  Employee = 'EMPLOYEE',
  Guard = 'GUARD',
  /**
   * The source of the incident is the HiveWatch software itself.
   *
   * This value should not be exposed to a user in the
   * ["Incident Source"](https://hivewatch.atlassian.net/browse/PRODUCT-224)
   * field
   * in the "Create Incident" form.
   */
  Internal = 'INTERNAL',
  /**
   * The source of the incident is QuickReport application.
   *
   * These incidents are submitted by external users.
   */
  QuickReport = 'QUICK_REPORT',
}

export enum IncidentStatus {
  /**
   * See the
   * [glossary](https://hivewatch.atlassian.net/wiki/spaces/EN/pages/166002738/Terms+Glossary#Active-incident).
   */
  Active = 'ACTIVE',
  /**
   * See the
   * [glossary](https://hivewatch.atlassian.net/wiki/spaces/EN/pages/166002738/Terms+Glossary#In-progress-incident).
   */
  InProgress = 'IN_PROGRESS',
  Resolved = 'RESOLVED',
}

export enum IncidentType {
  Alarm = 'ALARM',
  DeviceHealth = 'DEVICE_HEALTH',
  DeviceOffline = 'DEVICE_OFFLINE',
  DoorHeldClose = 'DOOR_HELD_CLOSE',
  DoorHeldOpen = 'DOOR_HELD_OPEN',
  ForcedEntry = 'FORCED_ENTRY',
  LineCrossing = 'LINE_CROSSING',
  Tailgating = 'TAILGATING',
}

export type User = {
  __typename?: 'User'
  /** A small photo, suitable for use as an avatar. */
  avatar?: Maybe<FlexibleContent>
  /** *Automatically generated.* */
  createdAt: Scalars['String']
  /**
   * Implements the following requirement in
   * [ENG-971](https://hivewatch.atlassian.net/browse/ENG-971):
   *
   * > The facility they [the user] are typically located at [...].
   * > This is also the facility that should be displayed on
   * > the settings page.
   */
  designatedFacility?: Maybe<Facility>
  /** The ID for `designatedFacility`. */
  designatedFacilityId: Scalars['ID']
  email?: Maybe<Scalars['String']>
  /**
   * The facility that the user is currently checked into
   * (if there is such a facility).
   */
  facility?: Maybe<Facility>
  /**
   * The ID of the facility that the user is currently checked into
   * (if there is such a facility).
   */
  facilityId?: Maybe<Scalars['ID']>
  firstName?: Maybe<Scalars['String']>
  id: Scalars['ID']
  lastName?: Maybe<Scalars['String']>
  /** May be unavailable for users other than guards. */
  onDuty?: Maybe<Scalars['Boolean']>
  photo?: Maybe<FlexibleContent>
  role?: Maybe<Role>
  /**
   * *For background on why `null` is allowed, see
   * [this Jira comment](https://hivewatch.atlassian.net/browse/ENG-975?focusedCommentId=13226)
   * from 2022-03-11.*
   */
  roleName?: Maybe<Scalars['String']>
  /** *Automatically generated.* */
  updatedAt: Scalars['String']
}

/**
 * This union exists to model data which might be provided as a URL
 * or might be sent directly by the GraphQL web service.
 */
export type FlexibleContent = MimeTypedObject | UrlContent

export type MimeTypedObject = {
  __typename?: 'MimeTypedObject'
  /**
   * The object's data, encoded with
   * [base64](https://datatracker.ietf.org/doc/html/rfc4648#section-4) encoding.
   */
  base64Content?: Maybe<Scalars['String']>
  /**
   * The [Mime type](https://en.wikipedia.org/wiki/Media_type)
   * of the object.
   */
  mimeType?: Maybe<Scalars['String']>
}

export type UrlContent = {
  __typename?: 'UrlContent'
  /**
   * The URL is valid until at least this time.
   *
   * If this field is `null`, then the URL has no expiry time.
   */
  minExpiryTime?: Maybe<Scalars['String']>
  url: Scalars['String']
}

export type Facility = {
  __typename?: 'Facility'
  /** The street address */
  address?: Maybe<Array<Scalars['String']>>
  /** *Automatically generated.* */
  archived?: Maybe<Scalars['Boolean']>
  createdAt: Scalars['String']
  defaultFloorId?: Maybe<Scalars['ID']>
  devices?: Maybe<DeviceConnection>
  /** Optional feature which is created/edited via a different API. */
  emergencyCalling?: Maybe<EmergencyCalling>
  emergencyNumber: Scalars['String']
  floors?: Maybe<FloorConnection>
  /** Count of guards currently checked into the facility. */
  guardCount?: Maybe<Scalars['Int']>
  guardTours?: Maybe<GuardTourConnection>
  id: Scalars['ID']
  incidents?: Maybe<IncidentConnection>
  latitude?: Maybe<Scalars['Float']>
  longitude?: Maybe<Scalars['Float']>
  name?: Maybe<Scalars['String']>
  /**
   * For 2 examples, see
   * [this Invision mock-up](https://projects.invisionapp.com/d/main/default/#/console/21014207/447586943/preview).
   * (The examples are the strings "GHQ" and "WH", each inside a
   * circle on the map.)
   *
   * Minimum length: 3 characters. \
   * Maximum length: 5 characters.
   */
  shortName?: Maybe<Scalars['String']>
  siteSnapshot?: Maybe<FlexibleContent>
  /** This time zone should match entries in the IANA Time Zone Database e.g. America/Los_Angeles. */
  timeZone?: Maybe<Scalars['String']>
  tourPlans?: Maybe<TourPlanConnection>
  type?: Maybe<FacilityType>
  /** *Automatically generated.* */
  updatedAt: Scalars['String']
  /** The users currently checked into the facility. */
  users?: Maybe<UserConnection>
}

export type FacilityDevicesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<DeviceFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<DeviceOrderInput>>>
}

export type FacilityFloorsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<FloorFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<FloorOrderInput>>>
}

export type FacilityGuardToursArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<GuardTourFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<GuardTourOrderInput>>>
}

export type FacilityIncidentsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<IncidentFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<IncidentOrderInput>>>
}

export type FacilityTourPlansArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<TourPlanFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<TourPlanOrderInput>>>
}

export type FacilityUsersArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<UserFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<UserOrderInput>>>
}

/** *Automatically generated.* */
export type DeviceFilterInput = {
  AND?: InputMaybe<Array<DeviceFilterInput>>
  archived?: InputMaybe<Scalars['Boolean']>
  bearing?: InputMaybe<Scalars['Float']>
  bearingGt?: InputMaybe<Scalars['Float']>
  bearingGte?: InputMaybe<Scalars['Float']>
  bearingIn?: InputMaybe<Array<Scalars['Float']>>
  bearingLike?: InputMaybe<Scalars['Float']>
  bearingLt?: InputMaybe<Scalars['Float']>
  bearingLte?: InputMaybe<Scalars['Float']>
  bearingNot?: InputMaybe<Scalars['Float']>
  bearingNotIn?: InputMaybe<Array<Scalars['Float']>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  edgeDeviceName?: InputMaybe<Scalars['String']>
  externalId?: InputMaybe<Scalars['String']>
  /** *Not implemented.* */
  externalIdIn?: InputMaybe<Array<Scalars['String']>>
  /** *Not implemented.* */
  externalIdNot?: InputMaybe<Scalars['String']>
  /** *Not implemented.* */
  externalIdNotIn?: InputMaybe<Array<Scalars['String']>>
  facilityId?: InputMaybe<Scalars['ID']>
  facilityIdIn?: InputMaybe<Array<Scalars['ID']>>
  facilityIdNot?: InputMaybe<Scalars['ID']>
  facilityIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  fieldOfView?: InputMaybe<Scalars['Float']>
  fieldOfViewGt?: InputMaybe<Scalars['Float']>
  fieldOfViewGte?: InputMaybe<Scalars['Float']>
  fieldOfViewIn?: InputMaybe<Array<Scalars['Float']>>
  fieldOfViewLike?: InputMaybe<Scalars['Float']>
  fieldOfViewLt?: InputMaybe<Scalars['Float']>
  fieldOfViewLte?: InputMaybe<Scalars['Float']>
  fieldOfViewNot?: InputMaybe<Scalars['Float']>
  fieldOfViewNotIn?: InputMaybe<Array<Scalars['Float']>>
  floorId?: InputMaybe<Scalars['ID']>
  floorIdIn?: InputMaybe<Array<Scalars['ID']>>
  floorIdNot?: InputMaybe<Scalars['ID']>
  floorIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  floorPlanId?: InputMaybe<Scalars['ID']>
  floorPlanIdIn?: InputMaybe<Array<Scalars['ID']>>
  floorPlanIdNot?: InputMaybe<Scalars['ID']>
  floorPlanIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  gatewayId?: InputMaybe<Scalars['ID']>
  gatewayIdIn?: InputMaybe<Array<Scalars['ID']>>
  gatewayIdNot?: InputMaybe<Scalars['ID']>
  gatewayIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  hasEdgeDevice?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  isPlaced?: InputMaybe<Scalars['Boolean']>
  macAddress?: InputMaybe<Scalars['String']>
  macAddressGt?: InputMaybe<Scalars['String']>
  macAddressGte?: InputMaybe<Scalars['String']>
  macAddressIn?: InputMaybe<Array<Scalars['String']>>
  macAddressLike?: InputMaybe<Scalars['String']>
  macAddressLt?: InputMaybe<Scalars['String']>
  macAddressLte?: InputMaybe<Scalars['String']>
  macAddressNot?: InputMaybe<Scalars['String']>
  macAddressNotIn?: InputMaybe<Array<Scalars['String']>>
  manufacturerName?: InputMaybe<Scalars['String']>
  manufacturerNameGt?: InputMaybe<Scalars['String']>
  manufacturerNameGte?: InputMaybe<Scalars['String']>
  manufacturerNameIn?: InputMaybe<Array<Scalars['String']>>
  manufacturerNameLike?: InputMaybe<Scalars['String']>
  manufacturerNameLt?: InputMaybe<Scalars['String']>
  manufacturerNameLte?: InputMaybe<Scalars['String']>
  manufacturerNameNot?: InputMaybe<Scalars['String']>
  manufacturerNameNotIn?: InputMaybe<Array<Scalars['String']>>
  modelName?: InputMaybe<Scalars['String']>
  modelNameGt?: InputMaybe<Scalars['String']>
  modelNameGte?: InputMaybe<Scalars['String']>
  modelNameIn?: InputMaybe<Array<Scalars['String']>>
  modelNameLike?: InputMaybe<Scalars['String']>
  modelNameLt?: InputMaybe<Scalars['String']>
  modelNameLte?: InputMaybe<Scalars['String']>
  modelNameNot?: InputMaybe<Scalars['String']>
  modelNameNotIn?: InputMaybe<Array<Scalars['String']>>
  name?: InputMaybe<Scalars['String']>
  nameGt?: InputMaybe<Scalars['String']>
  nameGte?: InputMaybe<Scalars['String']>
  nameIn?: InputMaybe<Array<Scalars['String']>>
  nameLike?: InputMaybe<Scalars['String']>
  nameLt?: InputMaybe<Scalars['String']>
  nameLte?: InputMaybe<Scalars['String']>
  nameNot?: InputMaybe<Scalars['String']>
  nameNotIn?: InputMaybe<Array<Scalars['String']>>
  OR?: InputMaybe<Array<DeviceFilterInput>>
  status?: InputMaybe<DeviceStatus>
  statusIn?: InputMaybe<Array<DeviceStatus>>
  statusNot?: InputMaybe<DeviceStatus>
  statusNotIn?: InputMaybe<Array<DeviceStatus>>
  type?: InputMaybe<DeviceType>
  typeIn?: InputMaybe<Array<DeviceType>>
  typeNot?: InputMaybe<DeviceType>
  typeNotIn?: InputMaybe<Array<DeviceType>>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
  visibilityRange?: InputMaybe<Scalars['Float']>
  visibilityRangeGt?: InputMaybe<Scalars['Float']>
  visibilityRangeGte?: InputMaybe<Scalars['Float']>
  visibilityRangeIn?: InputMaybe<Array<Scalars['Float']>>
  visibilityRangeLike?: InputMaybe<Scalars['Float']>
  visibilityRangeLt?: InputMaybe<Scalars['Float']>
  visibilityRangeLte?: InputMaybe<Scalars['Float']>
  visibilityRangeNot?: InputMaybe<Scalars['Float']>
  visibilityRangeNotIn?: InputMaybe<Array<Scalars['Float']>>
  x?: InputMaybe<Scalars['Float']>
  xGt?: InputMaybe<Scalars['Float']>
  xGte?: InputMaybe<Scalars['Float']>
  xIn?: InputMaybe<Array<Scalars['Float']>>
  xLike?: InputMaybe<Scalars['Float']>
  xLt?: InputMaybe<Scalars['Float']>
  xLte?: InputMaybe<Scalars['Float']>
  xNot?: InputMaybe<Scalars['Float']>
  xNotIn?: InputMaybe<Array<Scalars['Float']>>
  y?: InputMaybe<Scalars['Float']>
  yGt?: InputMaybe<Scalars['Float']>
  yGte?: InputMaybe<Scalars['Float']>
  yIn?: InputMaybe<Array<Scalars['Float']>>
  yLike?: InputMaybe<Scalars['Float']>
  yLt?: InputMaybe<Scalars['Float']>
  yLte?: InputMaybe<Scalars['Float']>
  yNot?: InputMaybe<Scalars['Float']>
  yNotIn?: InputMaybe<Array<Scalars['Float']>>
}

export enum DeviceStatus {
  Healthy = 'HEALTHY',
  Unhealthy = 'UNHEALTHY',
}

/** *Automatically generated.* */
export type DeviceOrderInput = {
  direction: OrderDirection
  field: DeviceOrderField
}

/** *Automatically generated.* */
export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

/** *Automatically generated.* */
export enum DeviceOrderField {
  Bearing = 'BEARING',
  CreatedAt = 'CREATED_AT',
  FacilityId = 'FACILITY_ID',
  FieldOfView = 'FIELD_OF_VIEW',
  FloorId = 'FLOOR_ID',
  FloorPlanId = 'FLOOR_PLAN_ID',
  GatewayId = 'GATEWAY_ID',
  Id = 'ID',
  IsPlaced = 'IS_PLACED',
  MacAddress = 'MAC_ADDRESS',
  ManufacturerName = 'MANUFACTURER_NAME',
  ModelName = 'MODEL_NAME',
  Name = 'NAME',
  Status = 'STATUS',
  Type = 'TYPE',
  UpdatedAt = 'UPDATED_AT',
  VisibilityRange = 'VISIBILITY_RANGE',
  X = 'X',
  Y = 'Y',
}

/** *Automatically generated.* */
export type DeviceConnection = {
  __typename?: 'DeviceConnection'
  edges?: Maybe<Array<Maybe<DeviceEdge>>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** *Automatically generated.* */
export type DeviceEdge = {
  __typename?: 'DeviceEdge'
  cursor: Scalars['String']
  node?: Maybe<Device>
}

export type Device = {
  __typename?: 'Device'
  alarm?: Maybe<Alarm>
  archived?: Maybe<Scalars['Boolean']>
  /**
   * The direction in which the device is "looking".
   *
   * Not applicable to all device types.
   *
   * The unit is the
   * [degree](https://en.wikipedia.org/wiki/Degree_(angle)).
   * 0 is north, 90 is east.
   */
  bearing?: Maybe<Scalars['Float']>
  camera?: Maybe<Camera>
  checkpoint?: Maybe<Checkpoint>
  /** *Automatically generated.* */
  createdAt: Scalars['String']
  door?: Maybe<Door>
  edgeDevice?: Maybe<EdgeDevice>
  edgeDeviceId?: Maybe<Scalars['ID']>
  facility?: Maybe<Facility>
  facilityId?: Maybe<Scalars['ID']>
  /**
   * Not applicable to all device types.
   * Added to this GraphQL type with cameras in mind.
   *
   * The unit is the
   * [degree](https://en.wikipedia.org/wiki/Degree_(angle)).
   */
  fieldOfView?: Maybe<Scalars['Float']>
  floor?: Maybe<Floor>
  floorId?: Maybe<Scalars['ID']>
  floorPlan?: Maybe<FloorPlan>
  floorPlanId?: Maybe<Scalars['ID']>
  gateway?: Maybe<Gateway>
  gatewayId?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  incidents?: Maybe<IncidentConnection>
  isPlaced?: Maybe<Scalars['Boolean']>
  macAddress?: Maybe<Scalars['String']>
  manufacturerName?: Maybe<Scalars['String']>
  modelName?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  status?: Maybe<DeviceStatus>
  tailgateConns?: Maybe<TailgateConnConnection>
  thumbnail?: Maybe<FlexibleContent>
  type?: Maybe<DeviceType>
  /** *Automatically generated.* */
  updatedAt: Scalars['String']
  /**
   * How far the device can see.
   *
   * Not applicable to all device types.
   *
   * Can vary according to location.  May be an Operator's
   * subjective estimate.
   *
   * The unit is the meter.
   *
   * Called 'distance' in some sources.
   */
  visibilityRange?: Maybe<Scalars['Float']>
  /**
   * The x-coordinate of the device relative
   * to the top left corner of
   * the floor plan (see the `floorPlan` attribute).
   *
   * The unit is the meter.
   */
  x?: Maybe<Scalars['Float']>
  /**
   * The y-coordinate of the device relative
   * to the top left corner of
   * the floor plan (see the `floorPlan` attribute).
   *
   * The unit is the meter.
   */
  y?: Maybe<Scalars['Float']>
}

export type DeviceIncidentsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<IncidentFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<IncidentOrderInput>>>
}

export type DeviceTailgateConnsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<TailgateConnFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<TailgateConnOrderInput>>>
}

export type Alarm = {
  __typename?: 'Alarm'
  createdAt: Scalars['String']
  device?: Maybe<Device>
  externalId?: Maybe<Scalars['String']>
  id: Scalars['ID']
  updatedAt: Scalars['String']
}

export type Camera = {
  __typename?: 'Camera'
  /** *Automatically generated.* */
  createdAt: Scalars['String']
  defaultExternalAccount?: Maybe<ExternalAccount>
  device?: Maybe<Device>
  externalAccount?: Maybe<ExternalAccount>
  externalAccountId?: Maybe<Scalars['ID']>
  externalId?: Maybe<Scalars['String']>
  id: Scalars['ID']
  rtspIp?: Maybe<Scalars['String']>
  rtspPassword?: Maybe<Scalars['String']>
  rtspPath?: Maybe<Scalars['String']>
  rtspPort?: Maybe<Scalars['Int']>
  rtspUsername?: Maybe<Scalars['String']>
  streamingProtocol?: Maybe<StreamingProtocol>
  streamingResolutionHeight?: Maybe<Scalars['Int']>
  streamingResolutionWidth?: Maybe<Scalars['Int']>
  streamingType?: Maybe<StreamingType>
  tailgateGrids?: Maybe<Array<Maybe<TailgateGrid>>>
  /** *Automatically generated.* */
  updatedAt: Scalars['String']
}

export type ExternalAccount = {
  __typename?: 'ExternalAccount'
  accountId: Scalars['String']
  accountName: Scalars['String']
  apiKeyIds: Array<Scalars['ID']>
  apiKeys: Array<ExternalAccountApiKey>
  createdAt: Scalars['String']
  defaultAccount: Scalars['Boolean']
  externalSystemType?: Maybe<ExternalSystemType>
  id: Scalars['ID']
  updatedAt: Scalars['String']
}

export type ExternalAccountApiKey = {
  __typename?: 'ExternalAccountApiKey'
  createdAt: Scalars['String']
  externalAccount?: Maybe<ExternalAccount>
  externalAccountId: Scalars['ID']
  id: Scalars['ID']
  permissionName: ApiKeyPermission
  secretName: Scalars['String']
  updatedAt: Scalars['String']
}

export enum ApiKeyPermission {
  StreamingHistorical = 'STREAMING_HISTORICAL',
  StreamingLive = 'STREAMING_LIVE',
}

export enum ExternalSystemType {
  Avigilon = 'AVIGILON',
  Verkada = 'VERKADA',
}

export enum StreamingProtocol {
  Rtsp = 'RTSP',
  Rtsps = 'RTSPS',
}

export enum StreamingType {
  AvaCloud = 'AVA_CLOUD',
  AvigilonCloud = 'AVIGILON_CLOUD',
  OnPremise = 'ON_PREMISE',
  VerkadaCloud = 'VERKADA_CLOUD',
}

export type TailgateGrid = {
  __typename?: 'TailgateGrid'
  camera?: Maybe<Device>
  door?: Maybe<Device>
  inBox?: Maybe<Box>
  outBox?: Maybe<Box>
}

export type Box = {
  __typename?: 'Box'
  bottomLeft: Point
  bottomRight: Point
  topLeft: Point
  topRight: Point
}

export type Point = {
  __typename?: 'Point'
  x: Scalars['Float']
  y: Scalars['Float']
}

export type Checkpoint = {
  __typename?: 'Checkpoint'
  /** *Automatically generated.* */
  createdAt: Scalars['String']
  device?: Maybe<Device>
  displayId: Scalars['String']
  id: Scalars['ID']
  /** *Automatically generated.* */
  updatedAt: Scalars['String']
}

export type Door = {
  __typename?: 'Door'
  /** *Automatically generated.* */
  createdAt: Scalars['String']
  device?: Maybe<Device>
  externalId?: Maybe<Scalars['String']>
  id: Scalars['ID']
  tailgateGrids?: Maybe<Array<Maybe<TailgateGrid>>>
  /** *Automatically generated.* */
  updatedAt: Scalars['String']
}

export type EdgeDevice = {
  __typename?: 'EdgeDevice'
  devices?: Maybe<DeviceConnection>
  id: Scalars['ID']
  name: Scalars['String']
}

export type Floor = {
  __typename?: 'Floor'
  archived?: Maybe<Scalars['Boolean']>
  /** *Automatically generated.* */
  createdAt: Scalars['String']
  devices?: Maybe<DeviceConnection>
  facility?: Maybe<Facility>
  facilityId: Scalars['ID']
  floorPlan?: Maybe<FloorPlan>
  floorPlanId: Scalars['ID']
  id: Scalars['ID']
  incidents?: Maybe<IncidentConnection>
  name?: Maybe<Scalars['String']>
  /** *Automatically generated.* */
  updatedAt: Scalars['String']
}

export type FloorDevicesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<DeviceFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<DeviceOrderInput>>>
}

export type FloorIncidentsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<IncidentFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<IncidentOrderInput>>>
}

/**
 * **Data modelling thoughts**
 *
 * A Floor Plan can exist independently of any Floors.
 *
 * A Floor will ordinarily have a Floor Plan, but might not
 * if the Floor has recently been created.
 *
 * A Floor Plan might be used by many Floors, quite possibly
 * in different Facilities.
 *
 * A Device ordinarily has a Floor, but might not if the Device has
 * recently been created.
 *
 * If a Device has been "placed", it has a Floor Plan;
 * otherwise, it doesn't.
 *
 * The physical layout of a Floor could change, in which case
 * the security staff will probably want to associate it with a new Floor Plan.
 * After this happens, there will be an interim period during which
 * Devices are associated with the old Floor Plan.
 *
 * **To be decided**
 *
 * How this type actually represents a Floor Plan.
 */
export type FloorPlan = {
  __typename?: 'FloorPlan'
  /** *Automatically generated.* */
  createdAt: Scalars['String']
  floorId: Scalars['ID']
  id: Scalars['ID']
  image?: Maybe<FlexibleContent>
  name: Scalars['String']
  s3Key?: Maybe<Scalars['String']>
  /** *Automatically generated.* */
  updatedAt: Scalars['String']
}

/** *Automatically generated.* */
export type IncidentFilterInput = {
  actionTypeIn?: InputMaybe<Array<IncidentActionType>>
  actionUpdatedAtLte?: InputMaybe<Scalars['String']>
  AND?: InputMaybe<Array<IncidentFilterInput>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtGteInterval?: InputMaybe<Scalars['Int']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  creatorId?: InputMaybe<Scalars['ID']>
  creatorIdIn?: InputMaybe<Array<Scalars['ID']>>
  creatorIdNot?: InputMaybe<Scalars['ID']>
  creatorIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  description?: InputMaybe<Scalars['String']>
  descriptionGt?: InputMaybe<Scalars['String']>
  descriptionGte?: InputMaybe<Scalars['String']>
  descriptionIn?: InputMaybe<Array<Scalars['String']>>
  descriptionLt?: InputMaybe<Scalars['String']>
  descriptionLte?: InputMaybe<Scalars['String']>
  descriptionNot?: InputMaybe<Scalars['String']>
  descriptionNotIn?: InputMaybe<Array<Scalars['String']>>
  deviceIdIn?: InputMaybe<Array<Scalars['ID']>>
  deviceTypeIn?: InputMaybe<Array<DeviceType>>
  deviceTypesContain?: InputMaybe<DeviceType>
  deviceTypesDoNotContain?: InputMaybe<DeviceType>
  deviceTypesNull?: InputMaybe<Scalars['Boolean']>
  dispatched?: InputMaybe<Scalars['Boolean']>
  dispatchedTo?: InputMaybe<DispatchTargetType>
  displayId?: InputMaybe<Scalars['String']>
  displayIdLike?: InputMaybe<Scalars['String']>
  facilityId?: InputMaybe<Scalars['ID']>
  facilityIdIn?: InputMaybe<Array<Scalars['ID']>>
  facilityIdNot?: InputMaybe<Scalars['ID']>
  facilityIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  facilityType?: InputMaybe<FacilityType>
  facilityTypeIn?: InputMaybe<Array<FacilityType>>
  facilityTypeNot?: InputMaybe<FacilityType>
  facilityTypeNotIn?: InputMaybe<Array<FacilityType>>
  floorId?: InputMaybe<Scalars['ID']>
  floorIdIn?: InputMaybe<Array<Scalars['ID']>>
  floorIdNot?: InputMaybe<Scalars['ID']>
  floorIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  guardId?: InputMaybe<Scalars['ID']>
  guardIdIn?: InputMaybe<Array<Scalars['ID']>>
  guardIdNot?: InputMaybe<Scalars['ID']>
  guardIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  hasAudioMessages?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  manualIncidentCategoryIdIn?: InputMaybe<Array<Scalars['ID']>>
  mutedStatus?: InputMaybe<MutedStatus>
  name?: InputMaybe<Scalars['String']>
  nameGt?: InputMaybe<Scalars['String']>
  nameGte?: InputMaybe<Scalars['String']>
  nameIn?: InputMaybe<Array<Scalars['String']>>
  nameLike?: InputMaybe<Scalars['String']>
  nameLt?: InputMaybe<Scalars['String']>
  nameLte?: InputMaybe<Scalars['String']>
  nameNot?: InputMaybe<Scalars['String']>
  nameNotIn?: InputMaybe<Array<Scalars['String']>>
  OR?: InputMaybe<Array<IncidentFilterInput>>
  ownerId?: InputMaybe<Scalars['ID']>
  ownerIdIn?: InputMaybe<Array<Scalars['ID']>>
  ownerIdNot?: InputMaybe<Scalars['ID']>
  ownerIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  priority?: InputMaybe<IncidentPriority>
  priorityIn?: InputMaybe<Array<IncidentPriority>>
  priorityNot?: InputMaybe<IncidentPriority>
  priorityNotIn?: InputMaybe<Array<IncidentPriority>>
  real?: InputMaybe<Scalars['Boolean']>
  source?: InputMaybe<IncidentSource>
  sourceIn?: InputMaybe<Array<IncidentSource>>
  sourceNot?: InputMaybe<IncidentSource>
  sourceNotIn?: InputMaybe<Array<IncidentSource>>
  status?: InputMaybe<IncidentStatus>
  statusIn?: InputMaybe<Array<IncidentStatus>>
  statusNot?: InputMaybe<IncidentStatus>
  statusNotIn?: InputMaybe<Array<IncidentStatus>>
  type?: InputMaybe<IncidentType>
  typeIn?: InputMaybe<Array<IncidentType>>
  typeNot?: InputMaybe<IncidentType>
  typeNotIn?: InputMaybe<Array<IncidentType>>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
}

export enum IncidentActionType {
  /**
   * A guard
   * [acknowledges](https://hivewatch.atlassian.net/browse/HM-355) an
   * incident.
   */
  AcknowledgementByGuard = 'ACKNOWLEDGEMENT_BY_GUARD',
  /**
   * An operator
   * [acknowledges an incident](https://hivewatch.atlassian.net/wiki/spaces/EN/pages/166002738/Glossary#Acknowledge-an-Incident).
   */
  AcknowledgementByOperator = 'ACKNOWLEDGEMENT_BY_OPERATOR',
  /**
   * A guard declares that an
   * [incident is cleared](https://hivewatch.atlassian.net/browse/HM-355).
   */
  Cleared = 'CLEARED',
  /** An operator/program manager has dispatched an incident to a facility's guards. */
  Dispatch = 'DISPATCH',
  /** An operator/program manager has ended an emergency call for an incident. */
  EndEmergencyCall = 'END_EMERGENCY_CALL',
  /** AI Operator flagged incident for further review and analysis. */
  Flag = 'FLAG',
  /**
   * A guard declares that he is [on the
   * scene](https://hivewatch.atlassian.net/browse/HM-355) of an
   * incident.
   */
  OnScene = 'ON_SCENE',
  /** An operator orders a device repair. */
  OrderDeviceRepair = 'ORDER_DEVICE_REPAIR',
  /** A guard releases an incident. */
  ReleaseByGuard = 'RELEASE_BY_GUARD',
  /**
   * An operator releases an incident.
   * (More information: [ENG-746](https://hivewatch.atlassian.net/browse/ENG-746).)
   */
  ReleaseByOperator = 'RELEASE_BY_OPERATOR',
  /**
   * An operator resolves an incident.
   * (Example:
   * [screenshot](https://hivewatch.atlassian.net/wiki/pages/viewpage.action?pageId=775979012&pageVersion=9&preview=/775979012/775323662/IncidentTimeline.png#ImageC)
   * from web app,
   * showing green box containing "Alert resolved by Adam West".)
   */
  Resolved = 'RESOLVED',
  /**
   * An operator/program manager has sent the incident to ontic's external system
   * [ENG-18121](https://hivewatch.atlassian.net/browse/ENG-18121)
   */
  SendToOntic = 'SEND_TO_ONTIC',
  /** An operator marks the incident as false. */
  SetRealToFalse = 'SET_REAL_TO_FALSE',
  /** An operator marks the incident as real. */
  SetRealToTrue = 'SET_REAL_TO_TRUE',
  /** An operator/program manager has started an emergency call for an incident. */
  StartEmergencyCall = 'START_EMERGENCY_CALL',
  /** A Guard need to be able to take over ownership to get to a resolution. */
  TakeoverByGuard = 'TAKEOVER_BY_GUARD',
}

export enum DispatchTargetType {
  /** Incidents dispatched to all guards */
  Broadcast = 'BROADCAST',
  /** Incidents dispatched to a user making request and not all guards */
  SpecificOnly = 'SPECIFIC_ONLY',
  /** Incidents dispatched to a user making request or all guards */
  SpecificOrBroadcast = 'SPECIFIC_OR_BROADCAST',
}

export enum FacilityType {
  Market = 'MARKET',
  Normal = 'NORMAL',
}

/** *Automatically generated.* */
export type IncidentOrderInput = {
  direction: OrderDirection
  field: IncidentOrderField
}

export enum IncidentOrderField {
  Acknowledged = 'ACKNOWLEDGED',
  ActionType = 'ACTION_TYPE',
  ActionUpdatedAt = 'ACTION_UPDATED_AT',
  ConcernStartTime = 'CONCERN_START_TIME',
  CreatedAt = 'CREATED_AT',
  CreatorId = 'CREATOR_ID',
  Description = 'DESCRIPTION',
  Dispatched = 'DISPATCHED',
  DisplayId = 'DISPLAY_ID',
  FacilityId = 'FACILITY_ID',
  FacilityName = 'FACILITY_NAME',
  FacilityType = 'FACILITY_TYPE',
  FloorId = 'FLOOR_ID',
  FloorName = 'FLOOR_NAME',
  GuardId = 'GUARD_ID',
  HasAudioMessages = 'HAS_AUDIO_MESSAGES',
  Id = 'ID',
  InformationSolicited = 'INFORMATION_SOLICITED',
  Latitude = 'LATITUDE',
  Longitude = 'LONGITUDE',
  MatchedRule = 'MATCHED_RULE',
  Muted = 'MUTED',
  Name = 'NAME',
  NextPossibleGuardAction = 'NEXT_POSSIBLE_GUARD_ACTION',
  Offered = 'OFFERED',
  OwnerId = 'OWNER_ID',
  Priority = 'PRIORITY',
  Real = 'REAL',
  Sensor = 'SENSOR',
  Source = 'SOURCE',
  Status = 'STATUS',
  Type = 'TYPE',
  UpdatedAt = 'UPDATED_AT',
}

/** *Automatically generated.* */
export type IncidentConnection = {
  __typename?: 'IncidentConnection'
  edges?: Maybe<Array<Maybe<IncidentEdge>>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** *Automatically generated.* */
export type IncidentEdge = {
  __typename?: 'IncidentEdge'
  cursor: Scalars['String']
  node?: Maybe<Incident>
}

export type Incident = SubscriberMessage & {
  __typename?: 'Incident'
  /**
   * Whether the incident has been
   * [acknowledged](https://hivewatch.atlassian.net/wiki/spaces/EN/pages/166002738/Glossary#Acknowledge-an-Incident)
   * by a guard (not an Operator).
   */
  acknowledged?: Maybe<Scalars['Boolean']>
  actions?: Maybe<Array<Maybe<IncidentAction>>>
  /**
   * Introduced for filtering outstanding incidents (OI) defined in https://hivewatch.atlassian.net/browse/ENG-4629
   * used to filter by requested incident action types.
   */
  actionType?: Maybe<IncidentActionType>
  /**
   * Introduced for filtering outstanding incidents (OI) defined in https://hivewatch.atlassian.net/browse/ENG-4629
   * to received not updated incidents for requested time.
   */
  actionUpdatedAt?: Maybe<Scalars['String']>
  /** The street address */
  address?: Maybe<Array<Scalars['String']>>
  audioMessages?: Maybe<AudioMessageConnection>
  /**
   * This field is applicable when the incident results from a worrying
   * condition that has persisted long enough to justify an incident.
   *
   * We can't use `createdAt`, because that is the time when the
   * Incident was created, which is later than the time at which the
   * worrying condition appeared.
   *
   * Here is an example of when this field is useful. Suppose that a
   * camera goes offline. We don't create an incident immediately,
   * because that policy would probably result in lots of trivial
   * incidents.
   *
   * If the incident persists long enough, however, we do create an
   * incident - and when we do, we are interested in knowing when the
   * camera went offline, which we can get from the `concernStartTime`
   * field.
   */
  concernStartTime?: Maybe<Scalars['String']>
  /** *Automatically generated.* */
  createdAt: Scalars['String']
  creator?: Maybe<User>
  creatorId?: Maybe<Scalars['ID']>
  description?: Maybe<Scalars['String']>
  deviceIds?: Maybe<Array<Maybe<Scalars['ID']>>>
  devices?: Maybe<Array<Maybe<Device>>>
  deviceTypes?: Maybe<Array<Maybe<DeviceType>>>
  dispatched?: Maybe<Scalars['Boolean']>
  dispatchedGuardIds?: Maybe<Array<Scalars['ID']>>
  dispatchedGuards?: Maybe<UserConnection>
  /** Human-readable incident identifier. */
  displayId: Scalars['String']
  emergencyCalls?: Maybe<EmergencyCallConnection>
  events?: Maybe<Array<Maybe<IncidentEvent>>>
  externalIncidentAdditionalDetail?: Maybe<ExternalIncidentAdditionalDetail>
  facility?: Maybe<Facility>
  facilityId: Scalars['ID']
  facilityName?: Maybe<Scalars['String']>
  facilityType?: Maybe<FacilityType>
  floor?: Maybe<Floor>
  floorId?: Maybe<Scalars['ID']>
  floorName?: Maybe<Scalars['String']>
  guard?: Maybe<User>
  guardId?: Maybe<Scalars['ID']>
  hasAudioMessages?: Maybe<Scalars['Boolean']>
  id: Scalars['ID']
  /**
   * Whether information has been solicited on this incident.
   * (For more information, see the section headed
   * "Sharing" in
   * [this comment](https://hivewatch.atlassian.net/browse/HM-306?focusedCommentId=10467).)
   */
  imageUrls?: Maybe<Array<Scalars['String']>>
  informationSolicited?: Maybe<Scalars['Boolean']>
  /**
   * An incident is considered flagged after the AI Operator performs the FLAG operation but before a real Operator
   * acknowledges the flagged incident. Flagged incidents are marked for review by real Operators.
   */
  isFlagged?: Maybe<Scalars['Boolean']>
  isSentToOntic?: Maybe<Scalars['Boolean']>
  lastIncidentDispatchType?: Maybe<LastIncidentDispatchType>
  latitude?: Maybe<Scalars['Float']>
  longitude?: Maybe<Scalars['Float']>
  manualIncidentCategory?: Maybe<ManualIncidentCategory>
  matchedRule?: Maybe<Scalars['ID']>
  muted: Scalars['Boolean']
  name?: Maybe<Scalars['String']>
  /**
   * *Design note: this field is unnecessary, because its value can
   * be inferred from other fields of this type — but it makes life
   * easier for the developer of the Patrol app.*
   */
  nextPossibleGuardAction?: Maybe<IncidentGuardActionType>
  notes?: Maybe<IncidentNoteConnection>
  occurrencesWithJudgement?: Maybe<IncidentOccurrenceWithJudgementConnection>
  /**
   * Whether this incident has been offered to an Operator.
   * (For more information, see the section headed
   * "Transfer of Responsibility" in
   * [this comment](https://hivewatch.atlassian.net/browse/HM-306?focusedCommentId=10467).)
   */
  offered?: Maybe<Scalars['Boolean']>
  /** The Operator who is in charge of the incident. */
  owner?: Maybe<User>
  ownerId?: Maybe<Scalars['ID']>
  priority?: Maybe<IncidentPriority>
  /**
   * If this is false, the incident
   * is not a real incident and should
   * not have been created.
   *
   * Note: operators may toggle this any number
   * of times, as their judgement changes.
   */
  real?: Maybe<Scalars['Boolean']>
  sensor?: Maybe<DeviceType>
  source?: Maybe<IncidentSource>
  status?: Maybe<IncidentStatus>
  systemActions?: Maybe<Array<Maybe<SystemAction>>>
  type?: Maybe<IncidentType>
  /** *Automatically generated.* */
  updatedAt: Scalars['String']
  video?: Maybe<StreamingUrlWrapperInterface>
}

export type IncidentAudioMessagesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<AudioMessageFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<AudioMessageOrderInput>>>
}

export type IncidentEmergencyCallsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<EmergencyCallFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<EmergencyCallOrderInput>>>
}

export type IncidentNotesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<IncidentNoteFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<IncidentNoteOrderInput>>>
}

export type IncidentOccurrencesWithJudgementArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<IncidentOccurrenceWithJudgementFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<
    Array<InputMaybe<IncidentOccurrenceWithJudgementOrderInput>>
  >
}

export type IncidentVideoArgs = {
  offsetAfter?: InputMaybe<Scalars['Float']>
  offsetBefore?: InputMaybe<Scalars['Float']>
}

export type SubscriberMessage = {
  createdAt: Scalars['String']
  id: Scalars['ID']
}

export type IncidentAction = IncidentEvent &
  IncidentOccurrence &
  SubscriberMessage & {
    __typename?: 'IncidentAction'
    audioClipId?: Maybe<Scalars['ID']>
    audioMessage?: Maybe<AudioMessage>
    audioMessageId?: Maybe<Scalars['ID']>
    /** *Automatically generated.* */
    createdAt: Scalars['String']
    creator?: Maybe<User>
    creatorId: Scalars['ID']
    deviceRepairOrder?: Maybe<DeviceRepairOrder>
    dispatchedGuardIds: Array<Scalars['ID']>
    dispatchedGuards: Array<User>
    id: Scalars['ID']
    incident?: Maybe<Incident>
    incidentId: Scalars['ID']
    incidentNote?: Maybe<IncidentNote>
    incidentNoteId?: Maybe<Scalars['ID']>
    /**
     * Implements the following requirement in
     * [ENG-687](https://hivewatch.atlassian.net/browse/ENG-687):
     *
     * > Must have [...] some text in the “Order Repair”
     * > textbox to be able to press the “Send Request” Button
     */
    message?: Maybe<Scalars['String']>
    /**
     * `null`, unless `type` is `ORDER_DEVICE_REPAIR`,
     * in which case it is the ID of the technician doing the repair.
     */
    technicianId?: Maybe<Scalars['ID']>
    type: IncidentActionType
    /** *Automatically generated.* */
    updatedAt: Scalars['String']
  }

export type IncidentEvent = {
  createdAt: Scalars['String']
  updatedAt: Scalars['String']
}

export type IncidentOccurrence = {
  createdAt: Scalars['String']
  id: Scalars['ID']
}

export type AudioMessage = IncidentEvent &
  SubscriberMessage & {
    __typename?: 'AudioMessage'
    acknowledged?: Maybe<Scalars['Boolean']>
    acknowledgements?: Maybe<Array<Maybe<Acknowledgement>>>
    audioClip?: Maybe<AudioClip>
    audioClipId: Scalars['ID']
    /** *Automatically generated.* */
    createdAt: Scalars['String']
    creator?: Maybe<User>
    creatorId?: Maybe<Scalars['ID']>
    facility?: Maybe<Facility>
    facilityId?: Maybe<Scalars['ID']>
    id: Scalars['ID']
    incident?: Maybe<Incident>
    incidentId?: Maybe<Scalars['ID']>
    incidentOwner?: Maybe<User>
    incidentOwnerId?: Maybe<Scalars['ID']>
    target?: Maybe<MessageTarget>
    /** *Automatically generated.* */
    updatedAt: Scalars['String']
  }

export type Acknowledgement = {
  __typename?: 'Acknowledgement'
  audioClip?: Maybe<AudioClip>
  audioClipId?: Maybe<Scalars['ID']>
  /** *Automatically generated.* */
  createdAt: Scalars['String']
  creator?: Maybe<User>
  creatorId: Scalars['ID']
  id: Scalars['ID']
  /** *Automatically generated.* */
  updatedAt: Scalars['String']
}

/** To *send* a message, use `AudioMessage`. */
export type AudioClip = {
  __typename?: 'AudioClip'
  /** *Automatically generated.* */
  createdAt: Scalars['String']
  creator?: Maybe<User>
  creatorId?: Maybe<Scalars['ID']>
  /**
   * The time at which the recording of the audio clip ended.
   * Example: 2021-10-29T08:05:47.978Z.
   */
  endTime: Scalars['String']
  /**
   * The client creates the voice message with some external
   * service, receiving a unique ID, then uses that ID for this
   * field.
   */
  externalId: Scalars['String']
  facility?: Maybe<Facility>
  facilityId?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  incident?: Maybe<Incident>
  incidentId?: Maybe<Scalars['ID']>
  /**
   * The time at which the recording of the audio clip started.
   * Example: 2021-10-29T08:05:47.978Z.
   */
  startTime: Scalars['String']
  /** *Automatically generated.* */
  updatedAt: Scalars['String']
  /** A file in a format such as MP3. */
  url?: Maybe<Scalars['String']>
}

export type MessageTarget = {
  __typename?: 'MessageTarget'
  AND?: Maybe<Array<MessageTarget>>
  facilityId?: Maybe<Scalars['ID']>
  incidentId?: Maybe<Scalars['ID']>
  onShift?: Maybe<Scalars['Boolean']>
  OR?: Maybe<Array<MessageTarget>>
  recipientIds?: Maybe<Array<Scalars['ID']>>
}

export type DeviceRepairOrder = {
  __typename?: 'DeviceRepairOrder'
  /** *Automatically generated.* */
  createdAt: Scalars['String']
  creator?: Maybe<User>
  id: Scalars['ID']
  message: Scalars['String']
  technician?: Maybe<Technician>
  /** *Automatically generated.* */
  updatedAt: Scalars['String']
}

export type Technician = {
  __typename?: 'Technician'
  /** *Automatically generated.* */
  createdAt: Scalars['String']
  /** The device types that the technician supports */
  deviceTypes?: Maybe<Array<Maybe<DeviceType>>>
  email?: Maybe<Scalars['String']>
  /** The facilities that the technician is associated with */
  facilities?: Maybe<Array<Maybe<Facility>>>
  facilityIds?: Maybe<Array<Maybe<Scalars['ID']>>>
  firstName?: Maybe<Scalars['String']>
  id: Scalars['ID']
  lastName?: Maybe<Scalars['String']>
  /** *Automatically generated.* */
  updatedAt: Scalars['String']
}

export type IncidentNote = {
  __typename?: 'IncidentNote'
  /** *Automatically generated.* */
  createdAt: Scalars['String']
  creator?: Maybe<User>
  creatorId?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  incident?: Maybe<Incident>
  incidentId: Scalars['ID']
  media: Array<IncidentNoteMedia>
  mediaIds: Array<Scalars['ID']>
  message: Scalars['String']
  /** *Automatically generated.* */
  updatedAt: Scalars['String']
}

export type IncidentNoteMedia = {
  __typename?: 'IncidentNoteMedia'
  id: Scalars['ID']
  incidentNoteId?: Maybe<Scalars['ID']>
  mimeType?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
}

/** *Automatically generated.* */
export type AudioMessageFilterInput = {
  acknowledged?: InputMaybe<Scalars['Boolean']>
  AND?: InputMaybe<Array<AudioMessageFilterInput>>
  audioClipId?: InputMaybe<Scalars['ID']>
  audioClipIdIn?: InputMaybe<Array<Scalars['ID']>>
  audioClipIdNot?: InputMaybe<Scalars['ID']>
  audioClipIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  creatorId?: InputMaybe<Scalars['ID']>
  creatorIdIn?: InputMaybe<Array<Scalars['ID']>>
  creatorIdNot?: InputMaybe<Scalars['ID']>
  creatorIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  facilityId?: InputMaybe<Scalars['ID']>
  facilityIdIn?: InputMaybe<Array<Scalars['ID']>>
  facilityIdNot?: InputMaybe<Scalars['ID']>
  facilityIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  incidentId?: InputMaybe<Scalars['ID']>
  incidentIdIn?: InputMaybe<Array<Scalars['ID']>>
  incidentIdNot?: InputMaybe<Scalars['ID']>
  incidentIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  incidentOwnerId?: InputMaybe<Scalars['ID']>
  incidentOwnerIdIn?: InputMaybe<Array<Scalars['ID']>>
  incidentOwnerIdNot?: InputMaybe<Scalars['ID']>
  incidentOwnerIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  OR?: InputMaybe<Array<AudioMessageFilterInput>>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
}

/** *Automatically generated.* */
export type AudioMessageOrderInput = {
  direction: OrderDirection
  field: AudioMessageOrderField
}

/** *Automatically generated.* */
export enum AudioMessageOrderField {
  Acknowledged = 'ACKNOWLEDGED',
  AudioClipId = 'AUDIO_CLIP_ID',
  CreatedAt = 'CREATED_AT',
  CreatorId = 'CREATOR_ID',
  FacilityId = 'FACILITY_ID',
  Id = 'ID',
  IncidentId = 'INCIDENT_ID',
  IncidentOwnerId = 'INCIDENT_OWNER_ID',
  UpdatedAt = 'UPDATED_AT',
}

/** *Automatically generated.* */
export type AudioMessageConnection = {
  __typename?: 'AudioMessageConnection'
  edges?: Maybe<Array<Maybe<AudioMessageEdge>>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** *Automatically generated.* */
export type AudioMessageEdge = {
  __typename?: 'AudioMessageEdge'
  cursor: Scalars['String']
  node?: Maybe<AudioMessage>
}

export type PageInfo = {
  __typename?: 'PageInfo'
  endCursor?: Maybe<Scalars['String']>
  hasNextPage?: Maybe<Scalars['Boolean']>
  hasPreviousPage?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  pageSize?: Maybe<Scalars['Int']>
  startCursor?: Maybe<Scalars['String']>
}

/** *Automatically generated.* */
export type UserConnection = {
  __typename?: 'UserConnection'
  edges?: Maybe<Array<Maybe<UserEdge>>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** *Automatically generated.* */
export type UserEdge = {
  __typename?: 'UserEdge'
  cursor: Scalars['String']
  node?: Maybe<User>
}

/** *Automatically generated.* */
export type EmergencyCallFilterInput = {
  AND?: InputMaybe<Array<EmergencyCallFilterInput>>
  callDuration?: InputMaybe<Scalars['String']>
  callDurationGt?: InputMaybe<Scalars['String']>
  callDurationGte?: InputMaybe<Scalars['String']>
  callDurationIn?: InputMaybe<Array<Scalars['String']>>
  callDurationLike?: InputMaybe<Scalars['String']>
  callDurationLt?: InputMaybe<Scalars['String']>
  callDurationLte?: InputMaybe<Scalars['String']>
  callDurationNot?: InputMaybe<Scalars['String']>
  callDurationNotIn?: InputMaybe<Array<Scalars['String']>>
  callEndTime?: InputMaybe<Scalars['String']>
  callEndTimeGt?: InputMaybe<Scalars['String']>
  callEndTimeGte?: InputMaybe<Scalars['String']>
  callEndTimeIn?: InputMaybe<Array<Scalars['String']>>
  callEndTimeLike?: InputMaybe<Scalars['String']>
  callEndTimeLt?: InputMaybe<Scalars['String']>
  callEndTimeLte?: InputMaybe<Scalars['String']>
  callEndTimeNot?: InputMaybe<Scalars['String']>
  callEndTimeNotIn?: InputMaybe<Array<Scalars['String']>>
  callStartTime?: InputMaybe<Scalars['String']>
  callStartTimeGt?: InputMaybe<Scalars['String']>
  callStartTimeGte?: InputMaybe<Scalars['String']>
  callStartTimeIn?: InputMaybe<Array<Scalars['String']>>
  callStartTimeLike?: InputMaybe<Scalars['String']>
  callStartTimeLt?: InputMaybe<Scalars['String']>
  callStartTimeLte?: InputMaybe<Scalars['String']>
  callStartTimeNot?: InputMaybe<Scalars['String']>
  callStartTimeNotIn?: InputMaybe<Array<Scalars['String']>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  externalCallId?: InputMaybe<Scalars['String']>
  externalCallIdGt?: InputMaybe<Scalars['String']>
  externalCallIdGte?: InputMaybe<Scalars['String']>
  externalCallIdIn?: InputMaybe<Array<Scalars['String']>>
  externalCallIdLike?: InputMaybe<Scalars['String']>
  externalCallIdLt?: InputMaybe<Scalars['String']>
  externalCallIdLte?: InputMaybe<Scalars['String']>
  externalCallIdNot?: InputMaybe<Scalars['String']>
  externalCallIdNotIn?: InputMaybe<Array<Scalars['String']>>
  facilityName?: InputMaybe<Scalars['String']>
  facilityNameGt?: InputMaybe<Scalars['String']>
  facilityNameGte?: InputMaybe<Scalars['String']>
  facilityNameIn?: InputMaybe<Array<Scalars['String']>>
  facilityNameLike?: InputMaybe<Scalars['String']>
  facilityNameLt?: InputMaybe<Scalars['String']>
  facilityNameLte?: InputMaybe<Scalars['String']>
  facilityNameNot?: InputMaybe<Scalars['String']>
  facilityNameNotIn?: InputMaybe<Array<Scalars['String']>>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  incidentDisplayId?: InputMaybe<Scalars['String']>
  incidentDisplayIdGt?: InputMaybe<Scalars['String']>
  incidentDisplayIdGte?: InputMaybe<Scalars['String']>
  incidentDisplayIdIn?: InputMaybe<Array<Scalars['String']>>
  incidentDisplayIdLike?: InputMaybe<Scalars['String']>
  incidentDisplayIdLt?: InputMaybe<Scalars['String']>
  incidentDisplayIdLte?: InputMaybe<Scalars['String']>
  incidentDisplayIdNot?: InputMaybe<Scalars['String']>
  incidentDisplayIdNotIn?: InputMaybe<Array<Scalars['String']>>
  incidentId?: InputMaybe<Scalars['ID']>
  incidentIdIn?: InputMaybe<Array<Scalars['ID']>>
  incidentIdNot?: InputMaybe<Scalars['ID']>
  incidentIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  OR?: InputMaybe<Array<EmergencyCallFilterInput>>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
  userId?: InputMaybe<Scalars['ID']>
  userIdIn?: InputMaybe<Array<Scalars['ID']>>
  userIdNot?: InputMaybe<Scalars['ID']>
  userIdNotIn?: InputMaybe<Array<Scalars['ID']>>
}

/** *Automatically generated.* */
export type EmergencyCallOrderInput = {
  direction: OrderDirection
  field: EmergencyCallOrderField
}

/** *Automatically generated.* */
export enum EmergencyCallOrderField {
  CallDuration = 'CALL_DURATION',
  CallEndTime = 'CALL_END_TIME',
  CallStartTime = 'CALL_START_TIME',
  CreatedAt = 'CREATED_AT',
  ExternalCallId = 'EXTERNAL_CALL_ID',
  FacilityName = 'FACILITY_NAME',
  Id = 'ID',
  IncidentDisplayId = 'INCIDENT_DISPLAY_ID',
  IncidentId = 'INCIDENT_ID',
  UpdatedAt = 'UPDATED_AT',
  UserId = 'USER_ID',
}

/** *Automatically generated.* */
export type EmergencyCallConnection = {
  __typename?: 'EmergencyCallConnection'
  edges?: Maybe<Array<Maybe<EmergencyCallEdge>>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** *Automatically generated.* */
export type EmergencyCallEdge = {
  __typename?: 'EmergencyCallEdge'
  cursor: Scalars['String']
  node?: Maybe<EmergencyCall>
}

export type EmergencyCall = {
  __typename?: 'EmergencyCall'
  callDuration?: Maybe<Scalars['String']>
  callEndTime?: Maybe<Scalars['String']>
  callStartTime: Scalars['String']
  /** *Automatically generated.* */
  createdAt: Scalars['String']
  externalCallId?: Maybe<Scalars['String']>
  /** Used to allow for nested filtering. */
  facilityName?: Maybe<Scalars['String']>
  id: Scalars['ID']
  incident?: Maybe<Incident>
  /** Used to allow for nested filtering. */
  incidentDisplayId?: Maybe<Scalars['String']>
  incidentId: Scalars['ID']
  /** *Automatically generated.* */
  updatedAt: Scalars['String']
  user?: Maybe<User>
  userId: Scalars['ID']
}

export type ExternalIncidentAdditionalDetail = {
  __typename?: 'ExternalIncidentAdditionalDetail'
  additionalContactInformation?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  locationOfConcern?: Maybe<Scalars['String']>
  partiesInvolved?: Maybe<Scalars['String']>
  phoneNumber?: Maybe<Scalars['String']>
  timeReported: Scalars['String']
}

export enum LastIncidentDispatchType {
  Broadcast = 'BROADCAST',
  NotYetDispatched = 'NOT_YET_DISPATCHED',
  Specific = 'SPECIFIC',
}

export type ManualIncidentCategory = {
  __typename?: 'ManualIncidentCategory'
  creatorId?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  isQuickReportCategory: Scalars['Boolean']
  name: Scalars['String']
}

/**
 * The same as `IncidentActionType`, except that
 * all enum values which don't apply to guards have been
 * removed.
 */
export enum IncidentGuardActionType {
  /**
   * A guard
   * [acknowledges](https://hivewatch.atlassian.net/browse/HM-355) an
   * incident.
   */
  AcknowledgementByGuard = 'ACKNOWLEDGEMENT_BY_GUARD',
  /**
   * A guard declares that an
   * [incident is cleared](https://hivewatch.atlassian.net/browse/HM-355).
   */
  Cleared = 'CLEARED',
  /**
   * No actions are possible (probably because the incident has
   * been cleared already).
   */
  None = 'NONE',
  /**
   * A guard declares that he is [on the
   * scene](https://hivewatch.atlassian.net/browse/HM-355) of an
   * incident.
   */
  OnScene = 'ON_SCENE',
}

/** *Automatically generated.* */
export type IncidentNoteFilterInput = {
  AND?: InputMaybe<Array<IncidentNoteFilterInput>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  creatorId?: InputMaybe<Scalars['ID']>
  creatorIdIn?: InputMaybe<Array<Scalars['ID']>>
  creatorIdNot?: InputMaybe<Scalars['ID']>
  creatorIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  incidentId?: InputMaybe<Scalars['ID']>
  incidentIdIn?: InputMaybe<Array<Scalars['ID']>>
  incidentIdNot?: InputMaybe<Scalars['ID']>
  incidentIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  message?: InputMaybe<Scalars['String']>
  messageGt?: InputMaybe<Scalars['String']>
  messageGte?: InputMaybe<Scalars['String']>
  messageIn?: InputMaybe<Array<Scalars['String']>>
  messageLike?: InputMaybe<Scalars['String']>
  messageLt?: InputMaybe<Scalars['String']>
  messageLte?: InputMaybe<Scalars['String']>
  messageNot?: InputMaybe<Scalars['String']>
  messageNotIn?: InputMaybe<Array<Scalars['String']>>
  OR?: InputMaybe<Array<IncidentNoteFilterInput>>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
}

/** *Automatically generated.* */
export type IncidentNoteOrderInput = {
  direction: OrderDirection
  field: IncidentNoteOrderField
}

/** *Automatically generated.* */
export enum IncidentNoteOrderField {
  CreatedAt = 'CREATED_AT',
  CreatorId = 'CREATOR_ID',
  Id = 'ID',
  IncidentId = 'INCIDENT_ID',
  Message = 'MESSAGE',
  UpdatedAt = 'UPDATED_AT',
}

/** *Automatically generated.* */
export type IncidentNoteConnection = {
  __typename?: 'IncidentNoteConnection'
  edges?: Maybe<Array<Maybe<IncidentNoteEdge>>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** *Automatically generated.* */
export type IncidentNoteEdge = {
  __typename?: 'IncidentNoteEdge'
  cursor: Scalars['String']
  node?: Maybe<IncidentNote>
}

/** *Automatically generated.* */
export type IncidentOccurrenceWithJudgementFilterInput = {
  AND?: InputMaybe<Array<IncidentOccurrenceWithJudgementFilterInput>>
  judgement?: InputMaybe<IncidentOccurrenceJudgement>
  judgementIn?: InputMaybe<Array<IncidentOccurrenceJudgement>>
  judgementNot?: InputMaybe<IncidentOccurrenceJudgement>
  judgementNotIn?: InputMaybe<Array<IncidentOccurrenceJudgement>>
  OR?: InputMaybe<Array<IncidentOccurrenceWithJudgementFilterInput>>
}

export enum IncidentOccurrenceJudgement {
  Bad = 'BAD',
  Good = 'GOOD',
  Neutral = 'NEUTRAL',
}

/** *Automatically generated.* */
export type IncidentOccurrenceWithJudgementOrderInput = {
  direction: OrderDirection
  field: IncidentOccurrenceWithJudgementOrderField
}

/** *Automatically generated.* */
export enum IncidentOccurrenceWithJudgementOrderField {
  Judgement = 'JUDGEMENT',
}

/** *Automatically generated.* */
export type IncidentOccurrenceWithJudgementConnection = {
  __typename?: 'IncidentOccurrenceWithJudgementConnection'
  edges?: Maybe<Array<Maybe<IncidentOccurrenceWithJudgementEdge>>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** *Automatically generated.* */
export type IncidentOccurrenceWithJudgementEdge = {
  __typename?: 'IncidentOccurrenceWithJudgementEdge'
  cursor: Scalars['String']
  node?: Maybe<IncidentOccurrenceWithJudgement>
}

export type IncidentOccurrenceWithJudgement = {
  __typename?: 'IncidentOccurrenceWithJudgement'
  judgement?: Maybe<IncidentOccurrenceJudgement>
  occurrence?: Maybe<IncidentOccurrence>
}

export type SystemAction = IncidentEvent & {
  __typename?: 'SystemAction'
  createdAt: Scalars['String']
  systemMessage?: Maybe<Scalars['String']>
  updatedAt: Scalars['String']
}

/**
 * *TODO: check that this allows the client to initially fill
 * the video content area with a still of the first frame.*
 */
export type StreamingUrlWrapperInterface = {
  /**
   * The time at which the URL will be invalid
   * and needs to be requested again.
   * Example: 2021-10-29T08:05:47.978Z.
   */
  expiryTime?: Maybe<Scalars['String']>
  url: Scalars['String']
}

/**
 * Quoting [HM-248](https://hivewatch.atlassian.net/browse/HM-248):
 * this type represents a "physical device" or Virtual Machine:
 *
 * > that can be deployed into customers' data centers
 * > for connecting and processing video camera and access control data
 *
 * Customers get VMs from us.  (See
 * [HM-270](https://hivewatch.atlassian.net/browse/HM-270), which
 * refers to "Secure portal for customer specific downloads" and
 * says: "I want to ensure that we are properly tracking VM downloads
 * and associations for each customer's environment".)
 *
 * *To get `Device` objects for a `Gateway`,
 * use `Query.devices`, filtering by `gatewayId`.*
 */
export type Gateway = {
  __typename?: 'Gateway'
  /** *Automatically generated.* */
  createdAt: Scalars['String']
  /**
   * [HM-345](https://hivewatch.atlassian.net/browse/HM-345) calls
   * for "the ability to associate an organization's gateways with
   * facilities".
   */
  facilities?: Maybe<Array<Maybe<Facility>>>
  facilityIds?: Maybe<Array<Scalars['ID']>>
  floorIds?: Maybe<Array<Scalars['ID']>>
  /**
   * [HM-345](https://hivewatch.atlassian.net/browse/HM-345) calls
   * for "the ability to associate an organization's gateways with
   * [...] floors".
   */
  floors?: Maybe<Array<Maybe<Floor>>>
  id: Scalars['ID']
  /**
   * * `true` if the gateway is a physical device.
   * * `false` if the gateway is a Virtual Machine.
   */
  physical: Scalars['Boolean']
  /** *Automatically generated.* */
  updatedAt: Scalars['String']
}

/** *Automatically generated.* */
export type TailgateConnFilterInput = {
  AND?: InputMaybe<Array<TailgateConnFilterInput>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  OR?: InputMaybe<Array<TailgateConnFilterInput>>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
}

/** *Automatically generated.* */
export type TailgateConnOrderInput = {
  direction: OrderDirection
  field: TailgateConnOrderField
}

/** *Automatically generated.* */
export enum TailgateConnOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT',
}

/** *Automatically generated.* */
export type TailgateConnConnection = {
  __typename?: 'TailgateConnConnection'
  edges?: Maybe<Array<Maybe<TailgateConnEdge>>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** *Automatically generated.* */
export type TailgateConnEdge = {
  __typename?: 'TailgateConnEdge'
  cursor: Scalars['String']
  node?: Maybe<TailgateConn>
}

/**
 * *We can't call it `TailgateConnection`, because
 * that would make it a Connection Type, as defined by the
 * [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm).*
 */
export type TailgateConn = {
  __typename?: 'TailgateConn'
  cameras: DeviceConnection
  /** *Automatically generated.* */
  createdAt: Scalars['String']
  /** The device connected to cameras, not necessarily a door. */
  door: Device
  id: Scalars['ID']
  /** *Automatically generated.* */
  updatedAt: Scalars['String']
}

/**
 * *We can't call it `TailgateConnection`, because
 * that would make it a Connection Type, as defined by the
 * [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm).*
 */
export type TailgateConnCamerasArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<DeviceFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<DeviceOrderInput>>>
}

export type EmergencyCalling = {
  __typename?: 'EmergencyCalling'
  /** *Automatically generated.* */
  createdAt: Scalars['String']
  facility: Facility
  facilityId: Scalars['ID']
  /** Should match the id of the facility it belongs to. */
  id: Scalars['ID']
  outboundPhoneNumber: Scalars['String']
  outboundPhoneNumberSid: Scalars['String']
  /** *Automatically generated.* */
  updatedAt: Scalars['String']
}

/** *Automatically generated.* */
export type FloorFilterInput = {
  AND?: InputMaybe<Array<FloorFilterInput>>
  archived?: InputMaybe<Scalars['Boolean']>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  facilityId?: InputMaybe<Scalars['ID']>
  facilityIdIn?: InputMaybe<Array<Scalars['ID']>>
  facilityIdNot?: InputMaybe<Scalars['ID']>
  facilityIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  floorPlanId?: InputMaybe<Scalars['ID']>
  floorPlanIdIn?: InputMaybe<Array<Scalars['ID']>>
  floorPlanIdNot?: InputMaybe<Scalars['ID']>
  floorPlanIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  name?: InputMaybe<Scalars['String']>
  nameGt?: InputMaybe<Scalars['String']>
  nameGte?: InputMaybe<Scalars['String']>
  nameIn?: InputMaybe<Array<Scalars['String']>>
  nameLike?: InputMaybe<Scalars['String']>
  nameLt?: InputMaybe<Scalars['String']>
  nameLte?: InputMaybe<Scalars['String']>
  nameNot?: InputMaybe<Scalars['String']>
  nameNotIn?: InputMaybe<Array<Scalars['String']>>
  OR?: InputMaybe<Array<FloorFilterInput>>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
}

/** *Automatically generated.* */
export type FloorOrderInput = {
  direction: OrderDirection
  field: FloorOrderField
}

/** *Automatically generated.* */
export enum FloorOrderField {
  CreatedAt = 'CREATED_AT',
  FacilityId = 'FACILITY_ID',
  FloorPlanId = 'FLOOR_PLAN_ID',
  Id = 'ID',
  Name = 'NAME',
  UpdatedAt = 'UPDATED_AT',
}

/** *Automatically generated.* */
export type FloorConnection = {
  __typename?: 'FloorConnection'
  edges?: Maybe<Array<Maybe<FloorEdge>>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** *Automatically generated.* */
export type FloorEdge = {
  __typename?: 'FloorEdge'
  cursor: Scalars['String']
  node?: Maybe<Floor>
}

/** *Automatically generated.* */
export type GuardTourFilterInput = {
  AND?: InputMaybe<Array<GuardTourFilterInput>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  guardId?: InputMaybe<Scalars['ID']>
  guardIdIn?: InputMaybe<Array<Scalars['ID']>>
  guardIdNot?: InputMaybe<Scalars['ID']>
  guardIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  OR?: InputMaybe<Array<GuardTourFilterInput>>
  status?: InputMaybe<GuardTourStatus>
  statusIn?: InputMaybe<Array<GuardTourStatus>>
  statusNot?: InputMaybe<GuardTourStatus>
  statusNotIn?: InputMaybe<Array<GuardTourStatus>>
  tourPlanId?: InputMaybe<Scalars['ID']>
  tourPlanIdIn?: InputMaybe<Array<Scalars['ID']>>
  tourPlanIdNot?: InputMaybe<Scalars['ID']>
  tourPlanIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  tourPlanName?: InputMaybe<Scalars['String']>
  tourPlanNameGt?: InputMaybe<Scalars['String']>
  tourPlanNameGte?: InputMaybe<Scalars['String']>
  tourPlanNameIn?: InputMaybe<Array<Scalars['String']>>
  tourPlanNameLike?: InputMaybe<Scalars['String']>
  tourPlanNameLt?: InputMaybe<Scalars['String']>
  tourPlanNameLte?: InputMaybe<Scalars['String']>
  tourPlanNameNot?: InputMaybe<Scalars['String']>
  tourPlanNameNotIn?: InputMaybe<Array<Scalars['String']>>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
}

export enum GuardTourStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
}

/** *Automatically generated.* */
export type GuardTourOrderInput = {
  direction: OrderDirection
  field: GuardTourOrderField
}

/** *Automatically generated.* */
export enum GuardTourOrderField {
  CreatedAt = 'CREATED_AT',
  GuardId = 'GUARD_ID',
  Id = 'ID',
  Status = 'STATUS',
  TourPlanId = 'TOUR_PLAN_ID',
  TourPlanName = 'TOUR_PLAN_NAME',
  UpdatedAt = 'UPDATED_AT',
}

/** *Automatically generated.* */
export type GuardTourConnection = {
  __typename?: 'GuardTourConnection'
  edges?: Maybe<Array<Maybe<GuardTourEdge>>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** *Automatically generated.* */
export type GuardTourEdge = {
  __typename?: 'GuardTourEdge'
  cursor: Scalars['String']
  node?: Maybe<GuardTour>
}

export type GuardTour = {
  __typename?: 'GuardTour'
  completedCheckpoints?: Maybe<GuardTourCompletedCheckpointConnection>
  /** *Automatically generated.* */
  createdAt: Scalars['String']
  guard?: Maybe<User>
  guardId: Scalars['ID']
  id: Scalars['ID']
  status: GuardTourStatus
  tourPlan?: Maybe<TourPlan>
  tourPlanId: Scalars['ID']
  tourPlanName?: Maybe<Scalars['String']>
  /** *Automatically generated.* */
  updatedAt: Scalars['String']
}

export type GuardTourCompletedCheckpointsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<GuardTourCompletedCheckpointFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<
    Array<InputMaybe<GuardTourCompletedCheckpointOrderInput>>
  >
}

/** *Automatically generated.* */
export type GuardTourCompletedCheckpointFilterInput = {
  AND?: InputMaybe<Array<GuardTourCompletedCheckpointFilterInput>>
  OR?: InputMaybe<Array<GuardTourCompletedCheckpointFilterInput>>
  scannedAt?: InputMaybe<Scalars['String']>
  scannedAtGt?: InputMaybe<Scalars['String']>
  scannedAtGte?: InputMaybe<Scalars['String']>
  scannedAtIn?: InputMaybe<Array<Scalars['String']>>
  scannedAtLike?: InputMaybe<Scalars['String']>
  scannedAtLt?: InputMaybe<Scalars['String']>
  scannedAtLte?: InputMaybe<Scalars['String']>
  scannedAtNot?: InputMaybe<Scalars['String']>
  scannedAtNotIn?: InputMaybe<Array<Scalars['String']>>
}

/** *Automatically generated.* */
export type GuardTourCompletedCheckpointOrderInput = {
  direction: OrderDirection
  field: GuardTourCompletedCheckpointOrderField
}

/** *Automatically generated.* */
export enum GuardTourCompletedCheckpointOrderField {
  ScannedAt = 'SCANNED_AT',
}

/** *Automatically generated.* */
export type GuardTourCompletedCheckpointConnection = {
  __typename?: 'GuardTourCompletedCheckpointConnection'
  edges?: Maybe<Array<Maybe<GuardTourCompletedCheckpointEdge>>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** *Automatically generated.* */
export type GuardTourCompletedCheckpointEdge = {
  __typename?: 'GuardTourCompletedCheckpointEdge'
  cursor: Scalars['String']
  node?: Maybe<GuardTourCompletedCheckpoint>
}

export type GuardTourCompletedCheckpoint = {
  __typename?: 'GuardTourCompletedCheckpoint'
  checkpoint: Checkpoint
  scannedAt: Scalars['String']
}

export type TourPlan = {
  __typename?: 'TourPlan'
  checkpointIds: Array<Scalars['ID']>
  checkpoints?: Maybe<CheckpointConnection>
  /** *Automatically generated.* */
  createdAt: Scalars['String']
  facility?: Maybe<Facility>
  facilityId: Scalars['ID']
  id: Scalars['ID']
  name: Scalars['String']
  /** *Automatically generated.* */
  updatedAt: Scalars['String']
}

export type TourPlanCheckpointsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<CheckpointFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<CheckpointOrderInput>>>
}

/** *Automatically generated.* */
export type CheckpointFilterInput = {
  AND?: InputMaybe<Array<CheckpointFilterInput>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  displayId?: InputMaybe<Scalars['String']>
  displayIdGt?: InputMaybe<Scalars['String']>
  displayIdGte?: InputMaybe<Scalars['String']>
  displayIdIn?: InputMaybe<Array<Scalars['String']>>
  displayIdLike?: InputMaybe<Scalars['String']>
  displayIdLt?: InputMaybe<Scalars['String']>
  displayIdLte?: InputMaybe<Scalars['String']>
  displayIdNot?: InputMaybe<Scalars['String']>
  displayIdNotIn?: InputMaybe<Array<Scalars['String']>>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  OR?: InputMaybe<Array<CheckpointFilterInput>>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
}

/** *Automatically generated.* */
export type CheckpointOrderInput = {
  direction: OrderDirection
  field: CheckpointOrderField
}

/** *Automatically generated.* */
export enum CheckpointOrderField {
  CreatedAt = 'CREATED_AT',
  DisplayId = 'DISPLAY_ID',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT',
}

/** *Automatically generated.* */
export type CheckpointConnection = {
  __typename?: 'CheckpointConnection'
  edges?: Maybe<Array<Maybe<CheckpointEdge>>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** *Automatically generated.* */
export type CheckpointEdge = {
  __typename?: 'CheckpointEdge'
  cursor: Scalars['String']
  node?: Maybe<Checkpoint>
}

/** *Automatically generated.* */
export type TourPlanFilterInput = {
  AND?: InputMaybe<Array<TourPlanFilterInput>>
  checkpointIdsContain?: InputMaybe<Scalars['ID']>
  checkpointIdsDoNotContain?: InputMaybe<Scalars['ID']>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  facilityId?: InputMaybe<Scalars['ID']>
  facilityIdIn?: InputMaybe<Array<Scalars['ID']>>
  facilityIdNot?: InputMaybe<Scalars['ID']>
  facilityIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  name?: InputMaybe<Scalars['String']>
  nameGt?: InputMaybe<Scalars['String']>
  nameGte?: InputMaybe<Scalars['String']>
  nameIn?: InputMaybe<Array<Scalars['String']>>
  nameLike?: InputMaybe<Scalars['String']>
  nameLt?: InputMaybe<Scalars['String']>
  nameLte?: InputMaybe<Scalars['String']>
  nameNot?: InputMaybe<Scalars['String']>
  nameNotIn?: InputMaybe<Array<Scalars['String']>>
  OR?: InputMaybe<Array<TourPlanFilterInput>>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
}

/** *Automatically generated.* */
export type TourPlanOrderInput = {
  direction: OrderDirection
  field: TourPlanOrderField
}

/** *Automatically generated.* */
export enum TourPlanOrderField {
  CreatedAt = 'CREATED_AT',
  FacilityId = 'FACILITY_ID',
  Id = 'ID',
  Name = 'NAME',
  UpdatedAt = 'UPDATED_AT',
}

/** *Automatically generated.* */
export type TourPlanConnection = {
  __typename?: 'TourPlanConnection'
  edges?: Maybe<Array<Maybe<TourPlanEdge>>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** *Automatically generated.* */
export type TourPlanEdge = {
  __typename?: 'TourPlanEdge'
  cursor: Scalars['String']
  node?: Maybe<TourPlan>
}

/** *Automatically generated.* */
export type UserFilterInput = {
  AND?: InputMaybe<Array<UserFilterInput>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  designatedFacilityId?: InputMaybe<Scalars['ID']>
  designatedFacilityIdIn?: InputMaybe<Array<Scalars['ID']>>
  designatedFacilityIdNot?: InputMaybe<Scalars['ID']>
  designatedFacilityIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  email?: InputMaybe<Scalars['String']>
  emailGt?: InputMaybe<Scalars['String']>
  emailGte?: InputMaybe<Scalars['String']>
  emailIn?: InputMaybe<Array<Scalars['String']>>
  emailLike?: InputMaybe<Scalars['String']>
  emailLt?: InputMaybe<Scalars['String']>
  emailLte?: InputMaybe<Scalars['String']>
  emailNot?: InputMaybe<Scalars['String']>
  emailNotIn?: InputMaybe<Array<Scalars['String']>>
  facilityId?: InputMaybe<Scalars['ID']>
  facilityIdIn?: InputMaybe<Array<Scalars['ID']>>
  facilityIdNot?: InputMaybe<Scalars['ID']>
  facilityIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  firstName?: InputMaybe<Scalars['String']>
  firstNameGt?: InputMaybe<Scalars['String']>
  firstNameGte?: InputMaybe<Scalars['String']>
  firstNameIn?: InputMaybe<Array<Scalars['String']>>
  firstNameLike?: InputMaybe<Scalars['String']>
  firstNameLt?: InputMaybe<Scalars['String']>
  firstNameLte?: InputMaybe<Scalars['String']>
  firstNameNot?: InputMaybe<Scalars['String']>
  firstNameNotIn?: InputMaybe<Array<Scalars['String']>>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  lastName?: InputMaybe<Scalars['String']>
  lastNameGt?: InputMaybe<Scalars['String']>
  lastNameGte?: InputMaybe<Scalars['String']>
  lastNameIn?: InputMaybe<Array<Scalars['String']>>
  lastNameLike?: InputMaybe<Scalars['String']>
  lastNameLt?: InputMaybe<Scalars['String']>
  lastNameLte?: InputMaybe<Scalars['String']>
  lastNameNot?: InputMaybe<Scalars['String']>
  lastNameNotIn?: InputMaybe<Array<Scalars['String']>>
  onDuty?: InputMaybe<Scalars['Boolean']>
  OR?: InputMaybe<Array<UserFilterInput>>
  roleName?: InputMaybe<Scalars['String']>
  roleNameGt?: InputMaybe<Scalars['String']>
  roleNameGte?: InputMaybe<Scalars['String']>
  roleNameIn?: InputMaybe<Array<Scalars['String']>>
  roleNameLike?: InputMaybe<Scalars['String']>
  roleNameLt?: InputMaybe<Scalars['String']>
  roleNameLte?: InputMaybe<Scalars['String']>
  roleNameNot?: InputMaybe<Scalars['String']>
  roleNameNotIn?: InputMaybe<Array<Scalars['String']>>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
}

/** *Automatically generated.* */
export type UserOrderInput = {
  direction: OrderDirection
  field: UserOrderField
}

/** *Automatically generated.* */
export enum UserOrderField {
  CreatedAt = 'CREATED_AT',
  DesignatedFacilityId = 'DESIGNATED_FACILITY_ID',
  Email = 'EMAIL',
  FacilityId = 'FACILITY_ID',
  FirstName = 'FIRST_NAME',
  Id = 'ID',
  LastName = 'LAST_NAME',
  OnDuty = 'ON_DUTY',
  RoleName = 'ROLE_NAME',
  UpdatedAt = 'UPDATED_AT',
}

export type Role = {
  __typename?: 'Role'
  /** *Automatically generated.* */
  createdAt: Scalars['String']
  id: Scalars['ID']
  /**
   * Possible values, showing the format for this field:
   *
   * * `GUARD`
   * * `OPERATOR`
   * * `PROGRAM_MANAGER`
   */
  name?: Maybe<Scalars['String']>
  permissions?: Maybe<Array<Maybe<Permission>>>
  /** *Automatically generated.* */
  updatedAt: Scalars['String']
}

export type Permission = {
  __typename?: 'Permission'
  /** *Automatically generated.* */
  createdAt: Scalars['String']
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  /** *Automatically generated.* */
  updatedAt: Scalars['String']
}

export type AlarmConnection = {
  __typename?: 'AlarmConnection'
  edges?: Maybe<Array<Maybe<AlarmEdge>>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

export type AlarmEdge = {
  __typename?: 'AlarmEdge'
  cursor: Scalars['String']
  node?: Maybe<Alarm>
}

/** *Automatically generated.* */
export type AlertFilterInput = {
  AND?: InputMaybe<Array<AlertFilterInput>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  incidentId?: InputMaybe<Scalars['ID']>
  incidentIdIn?: InputMaybe<Array<Scalars['ID']>>
  incidentIdNot?: InputMaybe<Scalars['ID']>
  incidentIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  OR?: InputMaybe<Array<AlertFilterInput>>
  priority?: InputMaybe<AlertPriority>
  priorityIn?: InputMaybe<Array<AlertPriority>>
  priorityNot?: InputMaybe<AlertPriority>
  priorityNotIn?: InputMaybe<Array<AlertPriority>>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
}

export enum AlertPriority {
  Placeholder = 'PLACEHOLDER',
}

/** *Automatically generated.* */
export type AlertOrderInput = {
  direction: OrderDirection
  field: AlertOrderField
}

/** *Automatically generated.* */
export enum AlertOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  IncidentId = 'INCIDENT_ID',
  Priority = 'PRIORITY',
  UpdatedAt = 'UPDATED_AT',
}

/** *Automatically generated.* */
export type AlertConnection = {
  __typename?: 'AlertConnection'
  edges?: Maybe<Array<Maybe<AlertEdge>>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** *Automatically generated.* */
export type AlertEdge = {
  __typename?: 'AlertEdge'
  cursor: Scalars['String']
  node?: Maybe<Alert>
}

export type Alert = {
  __typename?: 'Alert'
  /** *Automatically generated.* */
  createdAt: Scalars['String']
  id: Scalars['ID']
  incidentId: Scalars['ID']
  priority?: Maybe<AlertPriority>
  /** *Automatically generated.* */
  updatedAt: Scalars['String']
}

/** *Automatically generated.* */
export type AudioClipFilterInput = {
  AND?: InputMaybe<Array<AudioClipFilterInput>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  creatorId?: InputMaybe<Scalars['ID']>
  creatorIdIn?: InputMaybe<Array<Scalars['ID']>>
  creatorIdNot?: InputMaybe<Scalars['ID']>
  creatorIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  endTime?: InputMaybe<Scalars['String']>
  endTimeGt?: InputMaybe<Scalars['String']>
  endTimeGte?: InputMaybe<Scalars['String']>
  endTimeIn?: InputMaybe<Array<Scalars['String']>>
  endTimeLike?: InputMaybe<Scalars['String']>
  endTimeLt?: InputMaybe<Scalars['String']>
  endTimeLte?: InputMaybe<Scalars['String']>
  endTimeNot?: InputMaybe<Scalars['String']>
  endTimeNotIn?: InputMaybe<Array<Scalars['String']>>
  externalId?: InputMaybe<Scalars['String']>
  externalIdGt?: InputMaybe<Scalars['String']>
  externalIdGte?: InputMaybe<Scalars['String']>
  externalIdIn?: InputMaybe<Array<Scalars['String']>>
  externalIdLike?: InputMaybe<Scalars['String']>
  externalIdLt?: InputMaybe<Scalars['String']>
  externalIdLte?: InputMaybe<Scalars['String']>
  externalIdNot?: InputMaybe<Scalars['String']>
  externalIdNotIn?: InputMaybe<Array<Scalars['String']>>
  facilityId?: InputMaybe<Scalars['ID']>
  facilityIdIn?: InputMaybe<Array<Scalars['ID']>>
  facilityIdNot?: InputMaybe<Scalars['ID']>
  facilityIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  incidentId?: InputMaybe<Scalars['ID']>
  incidentIdIn?: InputMaybe<Array<Scalars['ID']>>
  incidentIdNot?: InputMaybe<Scalars['ID']>
  incidentIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  OR?: InputMaybe<Array<AudioClipFilterInput>>
  startTime?: InputMaybe<Scalars['String']>
  startTimeGt?: InputMaybe<Scalars['String']>
  startTimeGte?: InputMaybe<Scalars['String']>
  startTimeIn?: InputMaybe<Array<Scalars['String']>>
  startTimeLike?: InputMaybe<Scalars['String']>
  startTimeLt?: InputMaybe<Scalars['String']>
  startTimeLte?: InputMaybe<Scalars['String']>
  startTimeNot?: InputMaybe<Scalars['String']>
  startTimeNotIn?: InputMaybe<Array<Scalars['String']>>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
  url?: InputMaybe<Scalars['String']>
  urlGt?: InputMaybe<Scalars['String']>
  urlGte?: InputMaybe<Scalars['String']>
  urlIn?: InputMaybe<Array<Scalars['String']>>
  urlLike?: InputMaybe<Scalars['String']>
  urlLt?: InputMaybe<Scalars['String']>
  urlLte?: InputMaybe<Scalars['String']>
  urlNot?: InputMaybe<Scalars['String']>
  urlNotIn?: InputMaybe<Array<Scalars['String']>>
}

/** *Automatically generated.* */
export type AudioClipOrderInput = {
  direction: OrderDirection
  field: AudioClipOrderField
}

/** *Automatically generated.* */
export enum AudioClipOrderField {
  CreatedAt = 'CREATED_AT',
  CreatorId = 'CREATOR_ID',
  EndTime = 'END_TIME',
  ExternalId = 'EXTERNAL_ID',
  FacilityId = 'FACILITY_ID',
  Id = 'ID',
  IncidentId = 'INCIDENT_ID',
  StartTime = 'START_TIME',
  UpdatedAt = 'UPDATED_AT',
  Url = 'URL',
}

/** *Automatically generated.* */
export type AudioClipConnection = {
  __typename?: 'AudioClipConnection'
  edges?: Maybe<Array<Maybe<AudioClipEdge>>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** *Automatically generated.* */
export type AudioClipEdge = {
  __typename?: 'AudioClipEdge'
  cursor: Scalars['String']
  node?: Maybe<AudioClip>
}

export type CameraLiveFeedDetail = {
  __typename?: 'CameraLiveFeedDetail'
  /** *Automatically generated.* */
  createdAt: Scalars['String']
  externalId?: Maybe<Scalars['String']>
  id: Scalars['ID']
  rtspIp?: Maybe<Scalars['String']>
  rtspPath?: Maybe<Scalars['String']>
  rtspPort?: Maybe<Scalars['Int']>
  streamingProtocol?: Maybe<StreamingProtocol>
  streamingResolutionHeight?: Maybe<Scalars['Int']>
  streamingResolutionWidth?: Maybe<Scalars['Int']>
  /** *Automatically generated.* */
  updatedAt: Scalars['String']
}

/** *Automatically generated.* */
export type CameraLiveFeedDetailFilterInput = {
  AND?: InputMaybe<Array<CameraLiveFeedDetailFilterInput>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  externalId?: InputMaybe<Scalars['String']>
  externalIdGt?: InputMaybe<Scalars['String']>
  externalIdGte?: InputMaybe<Scalars['String']>
  externalIdIn?: InputMaybe<Array<Scalars['String']>>
  externalIdLike?: InputMaybe<Scalars['String']>
  externalIdLt?: InputMaybe<Scalars['String']>
  externalIdLte?: InputMaybe<Scalars['String']>
  externalIdNot?: InputMaybe<Scalars['String']>
  externalIdNotIn?: InputMaybe<Array<Scalars['String']>>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  OR?: InputMaybe<Array<CameraLiveFeedDetailFilterInput>>
  rtspIp?: InputMaybe<Scalars['String']>
  rtspIpGt?: InputMaybe<Scalars['String']>
  rtspIpGte?: InputMaybe<Scalars['String']>
  rtspIpIn?: InputMaybe<Array<Scalars['String']>>
  rtspIpLike?: InputMaybe<Scalars['String']>
  rtspIpLt?: InputMaybe<Scalars['String']>
  rtspIpLte?: InputMaybe<Scalars['String']>
  rtspIpNot?: InputMaybe<Scalars['String']>
  rtspIpNotIn?: InputMaybe<Array<Scalars['String']>>
  rtspPath?: InputMaybe<Scalars['String']>
  rtspPathGt?: InputMaybe<Scalars['String']>
  rtspPathGte?: InputMaybe<Scalars['String']>
  rtspPathIn?: InputMaybe<Array<Scalars['String']>>
  rtspPathLike?: InputMaybe<Scalars['String']>
  rtspPathLt?: InputMaybe<Scalars['String']>
  rtspPathLte?: InputMaybe<Scalars['String']>
  rtspPathNot?: InputMaybe<Scalars['String']>
  rtspPathNotIn?: InputMaybe<Array<Scalars['String']>>
  rtspPort?: InputMaybe<Scalars['Int']>
  rtspPortGt?: InputMaybe<Scalars['Int']>
  rtspPortGte?: InputMaybe<Scalars['Int']>
  rtspPortIn?: InputMaybe<Array<Scalars['Int']>>
  rtspPortLike?: InputMaybe<Scalars['Int']>
  rtspPortLt?: InputMaybe<Scalars['Int']>
  rtspPortLte?: InputMaybe<Scalars['Int']>
  rtspPortNot?: InputMaybe<Scalars['Int']>
  rtspPortNotIn?: InputMaybe<Array<Scalars['Int']>>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
}

/** *Automatically generated.* */
export type CameraLiveFeedDetailOrderInput = {
  direction: OrderDirection
  field: CameraLiveFeedDetailOrderField
}

/** *Automatically generated.* */
export enum CameraLiveFeedDetailOrderField {
  CreatedAt = 'CREATED_AT',
  ExternalId = 'EXTERNAL_ID',
  Id = 'ID',
  RtspIp = 'RTSP_IP',
  RtspPath = 'RTSP_PATH',
  RtspPort = 'RTSP_PORT',
  UpdatedAt = 'UPDATED_AT',
}

/** *Automatically generated.* */
export type CameraLiveFeedDetailConnection = {
  __typename?: 'CameraLiveFeedDetailConnection'
  edges?: Maybe<Array<Maybe<CameraLiveFeedDetailEdge>>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** *Automatically generated.* */
export type CameraLiveFeedDetailEdge = {
  __typename?: 'CameraLiveFeedDetailEdge'
  cursor: Scalars['String']
  node?: Maybe<CameraLiveFeedDetail>
}

export type Case = {
  __typename?: 'Case'
  actions?: Maybe<CaseActionConnection>
  createdAt: Scalars['String']
  createdBy: Scalars['ID']
  description: Scalars['String']
  detailedReport?: Maybe<Scalars['String']>
  displayId: Scalars['String']
  facilities: Array<Facility>
  facilityIds?: Maybe<Array<Scalars['ID']>>
  id: Scalars['ID']
  incidentIds?: Maybe<Array<Scalars['ID']>>
  incidents?: Maybe<IncidentConnection>
  name: Scalars['String']
  owner?: Maybe<User>
  ownerId: Scalars['ID']
  personsOfInterest?: Maybe<CaseAssociatedPersonOfInterestConnection>
  personsOfInterestIds?: Maybe<Array<Scalars['ID']>>
  status: CaseStatus
  updatedAt?: Maybe<Scalars['String']>
  updatedBy?: Maybe<Scalars['ID']>
}

export type CaseActionsArgs = {
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type CaseIncidentsArgs = {
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type CasePersonsOfInterestArgs = {
  filter?: InputMaybe<PersonOfInterestFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<PersonOfInterestOrderInput>>
}

export type CaseActionConnection = {
  __typename?: 'CaseActionConnection'
  edges?: Maybe<Array<Maybe<CaseActionEdge>>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

export type CaseActionEdge = {
  __typename?: 'CaseActionEdge'
  cursor: Scalars['String']
  node?: Maybe<CaseAction>
}

export type CaseAction = {
  __typename?: 'CaseAction'
  caseId: Scalars['ID']
  comment?: Maybe<CaseComment>
  createdAt: Scalars['String']
  createdBy: Scalars['ID']
  creator?: Maybe<User>
  id: Scalars['ID']
  personOfInterestRoleUpdateDetail?: Maybe<CaseActionPersonOfInterestUpdate>
  referenceId: Array<Scalars['ID']>
  type: CaseActionType
  updatedAt: Scalars['String']
  updatedBy?: Maybe<Scalars['ID']>
}

export type CaseComment = {
  __typename?: 'CaseComment'
  caseId: Scalars['ID']
  createdAt: Scalars['String']
  createdBy: Scalars['ID']
  id: Scalars['ID']
  message: Scalars['String']
  updatedAt: Scalars['String']
  updatedBy?: Maybe<Scalars['ID']>
}

export type CaseActionPersonOfInterestUpdate = {
  __typename?: 'CaseActionPersonOfInterestUpdate'
  caseAction?: Maybe<CaseAction>
  id: Scalars['ID']
  newRole?: Maybe<CaseRole>
  newRoleCustomDescription?: Maybe<Scalars['String']>
  oldRole?: Maybe<CaseRole>
  oldRoleCustomDescription?: Maybe<Scalars['String']>
  personOfInterest?: Maybe<PersonOfInterest>
}

export enum CaseRole {
  Other = 'OTHER',
  Reporter = 'REPORTER',
  Subject = 'SUBJECT',
  Victim = 'VICTIM',
  Witness = 'WITNESS',
}

export type PersonOfInterest = {
  __typename?: 'PersonOfInterest'
  address?: Maybe<Array<Scalars['String']>>
  caseIds?: Maybe<Array<Scalars['ID']>>
  cases?: Maybe<PersonOfInterestAssociatedCaseConnection>
  companyAffiliation: CompanyAffiliation
  createdAt: Scalars['String']
  customCompanyAffiliationDescription?: Maybe<Scalars['String']>
  dateOfBirth?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  firstName: Scalars['String']
  id: Scalars['ID']
  lastName: Scalars['String']
  phoneNumber?: Maybe<Scalars['String']>
  updatedAt: Scalars['String']
}

export type PersonOfInterestAssociatedCaseConnection = {
  __typename?: 'PersonOfInterestAssociatedCaseConnection'
  edges?: Maybe<Array<PersonOfInterestAssociatedCaseEdge>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

export type PersonOfInterestAssociatedCaseEdge = {
  __typename?: 'PersonOfInterestAssociatedCaseEdge'
  cursor: Scalars['String']
  node?: Maybe<PersonOfInterestAssociatedCase>
}

export type PersonOfInterestAssociatedCase = {
  __typename?: 'PersonOfInterestAssociatedCase'
  case: Case
  caseRole: CaseRole
  customCaseRoleDescription?: Maybe<Scalars['String']>
}

export enum CompanyAffiliation {
  Contractor = 'CONTRACTOR',
  Employee = 'EMPLOYEE',
  Guest = 'GUEST',
  Other = 'OTHER',
}

export enum CaseActionType {
  AddComment = 'ADD_COMMENT',
  AddFile = 'ADD_FILE',
  AssignOwner = 'ASSIGN_OWNER',
  AssociateIncident = 'ASSOCIATE_INCIDENT',
  AssociatePerson = 'ASSOCIATE_PERSON',
  Close = 'CLOSE',
  DisassociateIncident = 'DISASSOCIATE_INCIDENT',
  DisassociatePerson = 'DISASSOCIATE_PERSON',
  Open = 'OPEN',
  UpdatePersonOfInterestRole = 'UPDATE_PERSON_OF_INTEREST_ROLE',
}

export type PersonOfInterestFilterInput = {
  AND?: InputMaybe<Array<PersonOfInterestFilterInput>>
  caseId?: InputMaybe<Scalars['ID']>
  caseIdNot?: InputMaybe<Scalars['ID']>
  emailLike?: InputMaybe<Scalars['String']>
  nameLike?: InputMaybe<Scalars['String']>
  OR?: InputMaybe<Array<PersonOfInterestFilterInput>>
}

export type PersonOfInterestOrderInput = {
  direction: OrderDirection
  field: PersonOfInterestOrderField
}

export enum PersonOfInterestOrderField {
  CreatedAt = 'CREATED_AT',
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
}

export type CaseAssociatedPersonOfInterestConnection = {
  __typename?: 'CaseAssociatedPersonOfInterestConnection'
  edges?: Maybe<Array<CaseAssociatedPersonOfInterestEdge>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

export type CaseAssociatedPersonOfInterestEdge = {
  __typename?: 'CaseAssociatedPersonOfInterestEdge'
  cursor: Scalars['String']
  node?: Maybe<CaseAssociatedPersonOfInterest>
}

export type CaseAssociatedPersonOfInterest = {
  __typename?: 'CaseAssociatedPersonOfInterest'
  caseRole: CaseRole
  customCaseRoleDescription?: Maybe<Scalars['String']>
  personOfInterest: PersonOfInterest
}

export enum CaseStatus {
  Active = 'ACTIVE',
  Closed = 'CLOSED',
}

export type CaseFilterInput = {
  AND?: InputMaybe<Array<CaseFilterInput>>
  displayId?: InputMaybe<Scalars['String']>
  displayIdLike?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  nameGt?: InputMaybe<Scalars['String']>
  nameGte?: InputMaybe<Scalars['String']>
  nameIn?: InputMaybe<Array<Scalars['String']>>
  nameLike?: InputMaybe<Scalars['String']>
  nameLt?: InputMaybe<Scalars['String']>
  nameLte?: InputMaybe<Scalars['String']>
  nameNot?: InputMaybe<Scalars['String']>
  nameNotIn?: InputMaybe<Array<Scalars['String']>>
  OR?: InputMaybe<Array<CaseFilterInput>>
  ownerId?: InputMaybe<Scalars['ID']>
  ownerIdIn?: InputMaybe<Array<Scalars['ID']>>
  ownerIdNot?: InputMaybe<Scalars['ID']>
  ownerIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  status?: InputMaybe<CaseStatus>
  statusIn?: InputMaybe<Array<CaseStatus>>
  statusNot?: InputMaybe<CaseStatus>
  statusNotIn?: InputMaybe<Array<CaseStatus>>
}

export type CaseConnection = {
  __typename?: 'CaseConnection'
  edges?: Maybe<Array<Maybe<CaseEdge>>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

export type CaseEdge = {
  __typename?: 'CaseEdge'
  cursor: Scalars['String']
  node?: Maybe<Case>
}

/** *Automatically generated.* */
export type ChargeFilterInput = {
  amount?: InputMaybe<Scalars['Int']>
  amountGt?: InputMaybe<Scalars['Int']>
  amountGte?: InputMaybe<Scalars['Int']>
  amountIn?: InputMaybe<Array<Scalars['Int']>>
  amountLike?: InputMaybe<Scalars['Int']>
  amountLt?: InputMaybe<Scalars['Int']>
  amountLte?: InputMaybe<Scalars['Int']>
  amountNot?: InputMaybe<Scalars['Int']>
  amountNotIn?: InputMaybe<Array<Scalars['Int']>>
  AND?: InputMaybe<Array<ChargeFilterInput>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  OR?: InputMaybe<Array<ChargeFilterInput>>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
}

/** *Automatically generated.* */
export type ChargeOrderInput = {
  direction: OrderDirection
  field: ChargeOrderField
}

/** *Automatically generated.* */
export enum ChargeOrderField {
  Amount = 'AMOUNT',
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT',
}

/** *Automatically generated.* */
export type ChargeConnection = {
  __typename?: 'ChargeConnection'
  edges?: Maybe<Array<Maybe<ChargeEdge>>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** *Automatically generated.* */
export type ChargeEdge = {
  __typename?: 'ChargeEdge'
  cursor: Scalars['String']
  node?: Maybe<Charge>
}

export type Charge = {
  __typename?: 'Charge'
  /** The unit is the cent. */
  amount?: Maybe<Scalars['Int']>
  /** *Automatically generated.* */
  createdAt: Scalars['String']
  id?: Maybe<Scalars['ID']>
  /** *Automatically generated.* */
  updatedAt: Scalars['String']
}

/** *Automatically generated.* */
export type EventFilterInput = {
  AND?: InputMaybe<Array<EventFilterInput>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  deviceIdsContain?: InputMaybe<Scalars['ID']>
  deviceIdsDoNotContain?: InputMaybe<Scalars['ID']>
  facilityId?: InputMaybe<Scalars['ID']>
  facilityIdIn?: InputMaybe<Array<Scalars['ID']>>
  facilityIdNot?: InputMaybe<Scalars['ID']>
  facilityIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  incidentId?: InputMaybe<Scalars['ID']>
  incidentIdIn?: InputMaybe<Array<Scalars['ID']>>
  incidentIdNot?: InputMaybe<Scalars['ID']>
  incidentIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  numTailGaters?: InputMaybe<Scalars['Int']>
  numTailGatersGt?: InputMaybe<Scalars['Int']>
  numTailGatersGte?: InputMaybe<Scalars['Int']>
  numTailGatersIn?: InputMaybe<Array<Scalars['Int']>>
  numTailGatersLike?: InputMaybe<Scalars['Int']>
  numTailGatersLt?: InputMaybe<Scalars['Int']>
  numTailGatersLte?: InputMaybe<Scalars['Int']>
  numTailGatersNot?: InputMaybe<Scalars['Int']>
  numTailGatersNotIn?: InputMaybe<Array<Scalars['Int']>>
  OR?: InputMaybe<Array<EventFilterInput>>
  type?: InputMaybe<EventType>
  typeIn?: InputMaybe<Array<EventType>>
  typeNot?: InputMaybe<EventType>
  typeNotIn?: InputMaybe<Array<EventType>>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
}

export enum EventType {
  Alarm = 'ALARM',
  DeviceHealth = 'DEVICE_HEALTH',
  DeviceOffline = 'DEVICE_OFFLINE',
  DoorHeldClose = 'DOOR_HELD_CLOSE',
  DoorHeldOpen = 'DOOR_HELD_OPEN',
  OverheadDoorOpen = 'OVERHEAD_DOOR_OPEN',
  ForcedEntry = 'FORCED_ENTRY',
  LineCrossing = 'LINE_CROSSING',
  NonTailgating = 'NON_TAILGATING',
  Tailgating = 'TAILGATING',
}

/** *Automatically generated.* */
export type EventOrderInput = {
  direction: OrderDirection
  field: EventOrderField
}

/** *Automatically generated.* */
export enum EventOrderField {
  CreatedAt = 'CREATED_AT',
  FacilityId = 'FACILITY_ID',
  Id = 'ID',
  IncidentId = 'INCIDENT_ID',
  NumTailGaters = 'NUM_TAIL_GATERS',
  Type = 'TYPE',
  UpdatedAt = 'UPDATED_AT',
}

/** *Automatically generated.* */
export type EventConnection = {
  __typename?: 'EventConnection'
  edges?: Maybe<Array<Maybe<EventEdge>>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** *Automatically generated.* */
export type EventEdge = {
  __typename?: 'EventEdge'
  cursor: Scalars['String']
  node?: Maybe<Event>
}

/**
 * An observation by a computer system.
 * Includes an observation of a fault,
 * security threat, intrusion attempt, or security breach.
 * Also includes harmless observations such as temperature readings.
 *
 * *Some HiveWatch writings give a much broader
 * definition to the word 'event'.
 * (See the
 * [definition](https://hivewatch.atlassian.net/wiki/spaces/EN/pages/166002738/Glossary?pageVersion=23#Event)
 * in the glossary.)
 * It is possible that this type will be renamed,
 * or have its meaning broadened.*
 */
export type Event = IncidentOccurrence & {
  __typename?: 'Event'
  /** *Automatically generated.* */
  createdAt: Scalars['String']
  /** A JSON object. */
  details?: Maybe<Scalars['String']>
  /**
   * There is always at least one device.
   *
   * A tailgate event is
   * an example of an event with more than one
   * device.
   */
  deviceIds: Array<Scalars['ID']>
  devices?: Maybe<Array<Maybe<Device>>>
  externalId?: Maybe<Scalars['ID']>
  facility?: Maybe<Facility>
  facilityId: Scalars['ID']
  floor?: Maybe<Floor>
  floorId?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  incident?: Maybe<Incident>
  incidentId?: Maybe<Scalars['ID']>
  /**
   * `null` unless the event is a tailgate event.
   * Holds the number of unauthorized people
   * who enter with the authorized person.
   */
  numTailGaters?: Maybe<Scalars['Int']>
  type?: Maybe<EventType>
  /** *Automatically generated.* */
  updatedAt: Scalars['String']
  video?: Maybe<StreamingUrlWrapperInterface>
}

/**
 * An observation by a computer system.
 * Includes an observation of a fault,
 * security threat, intrusion attempt, or security breach.
 * Also includes harmless observations such as temperature readings.
 *
 * *Some HiveWatch writings give a much broader
 * definition to the word 'event'.
 * (See the
 * [definition](https://hivewatch.atlassian.net/wiki/spaces/EN/pages/166002738/Glossary?pageVersion=23#Event)
 * in the glossary.)
 * It is possible that this type will be renamed,
 * or have its meaning broadened.*
 */
export type EventVideoArgs = {
  offsetAfter?: InputMaybe<Scalars['Float']>
  offsetBefore?: InputMaybe<Scalars['Float']>
}

export type Organization = {
  __typename?: 'Organization'
  aiOperatorSettings?: Maybe<AiOperatorSettings>
  /** *Automatically generated.* */
  createdAt: Scalars['String']
  id: Scalars['ID']
  name: Scalars['String']
  organizationSettings?: Maybe<OrganizationSettings>
  /** *Automatically generated.* */
  updatedAt: Scalars['String']
}

export type AiOperatorSettings = {
  __typename?: 'AiOperatorSettings'
  enabled: Scalars['Boolean']
  id: Scalars['ID']
}

export type OrganizationSettings = {
  __typename?: 'OrganizationSettings'
  cameraOfflineDurationUnit: DurationUnit
  /** *Automatically generated.* */
  createdAt: Scalars['String']
  emailReplyToAddress?: Maybe<Scalars['String']>
  emailReplyToName?: Maybe<Scalars['String']>
  emailSenderName?: Maybe<Scalars['String']>
  id: Scalars['ID']
  /** The phone number used by an organization to receive calls. */
  inboundPhoneNumber?: Maybe<Scalars['String']>
  minimumCameraOfflineDuration: Scalars['Int']
  /** *Automatically generated.* */
  updatedAt: Scalars['String']
}

export enum DurationUnit {
  Day = 'DAY',
  Hour = 'HOUR',
  Minute = 'MINUTE',
  Second = 'SECOND',
}

/** *Automatically generated.* */
export type DeviceGroupConnection = {
  __typename?: 'DeviceGroupConnection'
  edges?: Maybe<Array<Maybe<DeviceGroupEdge>>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** *Automatically generated.* */
export type DeviceGroupEdge = {
  __typename?: 'DeviceGroupEdge'
  cursor: Scalars['String']
  node?: Maybe<DeviceGroupNode>
}

/** *Automatically generated.* */
export type DeviceGroupNode = {
  __typename?: 'DeviceGroupNode'
  avg?: Maybe<DeviceGroupAvg>
  count?: Maybe<Scalars['Int']>
  dimensions?: Maybe<DeviceGroupDimension>
  sum?: Maybe<DeviceGroupSum>
  uniq?: Maybe<DeviceGroupUniq>
}

/** *Automatically generated.* */
export type DeviceGroupAvg = {
  __typename?: 'DeviceGroupAvg'
  bearing?: Maybe<Scalars['Float']>
  fieldOfView?: Maybe<Scalars['Float']>
  visibilityRange?: Maybe<Scalars['Float']>
  x?: Maybe<Scalars['Float']>
  y?: Maybe<Scalars['Float']>
}

/**
 * *Automatically generated.*
 *
 * Any fields selected on this type will be used for aggregation.
 * (This is like `GROUP BY` in SQL.)
 */
export type DeviceGroupDimension = {
  __typename?: 'DeviceGroupDimension'
  bearing?: Maybe<Scalars['Float']>
  createdAt?: Maybe<Scalars['String']>
  facilityId?: Maybe<Scalars['ID']>
  fieldOfView?: Maybe<Scalars['Float']>
  floorId?: Maybe<Scalars['ID']>
  floorPlanId?: Maybe<Scalars['ID']>
  gatewayId?: Maybe<Scalars['ID']>
  isPlaced?: Maybe<Scalars['Boolean']>
  macAddress?: Maybe<Scalars['String']>
  manufacturerName?: Maybe<Scalars['String']>
  modelName?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  status?: Maybe<DeviceStatus>
  type?: Maybe<DeviceType>
  updatedAt?: Maybe<Scalars['String']>
  visibilityRange?: Maybe<Scalars['Float']>
  x?: Maybe<Scalars['Float']>
  y?: Maybe<Scalars['Float']>
}

/** *Automatically generated.* */
export type DeviceGroupSum = {
  __typename?: 'DeviceGroupSum'
  bearing?: Maybe<Scalars['Float']>
  fieldOfView?: Maybe<Scalars['Float']>
  visibilityRange?: Maybe<Scalars['Float']>
  x?: Maybe<Scalars['Float']>
  y?: Maybe<Scalars['Float']>
}

/**
 * *Automatically generated.*
 *
 * Each field of this type is a count of the number of distinct
 * values of another field: a field of the same name on the
 * generating type.
 */
export type DeviceGroupUniq = {
  __typename?: 'DeviceGroupUniq'
  bearing?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['Int']>
  facilityId?: Maybe<Scalars['Int']>
  fieldOfView?: Maybe<Scalars['Int']>
  floorId?: Maybe<Scalars['Int']>
  floorPlanId?: Maybe<Scalars['Int']>
  gatewayId?: Maybe<Scalars['Int']>
  isPlaced?: Maybe<Scalars['Int']>
  macAddress?: Maybe<Scalars['Int']>
  manufacturerName?: Maybe<Scalars['Int']>
  modelName?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['Int']>
  status?: Maybe<Scalars['Int']>
  type?: Maybe<Scalars['Int']>
  updatedAt?: Maybe<Scalars['Int']>
  visibilityRange?: Maybe<Scalars['Int']>
  x?: Maybe<Scalars['Int']>
  y?: Maybe<Scalars['Int']>
}

export type EdgeDeviceFilterInput = {
  nameLike?: InputMaybe<Scalars['String']>
}

export type EdgeDeviceOrderInput = {
  direction: OrderDirection
  field: EdgeDeviceOrderField
}

export enum EdgeDeviceOrderField {
  Name = 'NAME',
}

export type EdgeDeviceConnection = {
  __typename?: 'EdgeDeviceConnection'
  edges?: Maybe<Array<Maybe<EdgeDeviceEdge>>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

export type EdgeDeviceEdge = {
  __typename?: 'EdgeDeviceEdge'
  cursor: Scalars['String']
  node?: Maybe<EdgeDevice>
}

/** Short-lived token used to authenticate Emergency Calling SDKs. */
export type EmergencyCallingAccessToken = {
  __typename?: 'EmergencyCallingAccessToken'
  token: Scalars['String']
}

export type ExternalAccountApiKeyConnection = {
  __typename?: 'ExternalAccountApiKeyConnection'
  edges?: Maybe<Array<Maybe<ExternalAccountApiKeyEdge>>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

export type ExternalAccountApiKeyEdge = {
  __typename?: 'ExternalAccountApiKeyEdge'
  cursor: Scalars['String']
  node?: Maybe<ExternalAccountApiKey>
}

export type ExternalAccountConnection = {
  __typename?: 'ExternalAccountConnection'
  edges?: Maybe<Array<Maybe<ExternalAccountEdge>>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

export type ExternalAccountEdge = {
  __typename?: 'ExternalAccountEdge'
  cursor: Scalars['String']
  node?: Maybe<ExternalAccount>
}

/** *Automatically generated.* */
export type FacilityFilterInput = {
  addressContain?: InputMaybe<Scalars['String']>
  addressDoNotContain?: InputMaybe<Scalars['String']>
  addressNull?: InputMaybe<Scalars['Boolean']>
  AND?: InputMaybe<Array<FacilityFilterInput>>
  archived?: InputMaybe<Scalars['Boolean']>
  box?: InputMaybe<GeoFenceBoxInput>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  includeArchived?: InputMaybe<Scalars['Boolean']>
  latitude?: InputMaybe<Scalars['Float']>
  latitudeGt?: InputMaybe<Scalars['Float']>
  latitudeGte?: InputMaybe<Scalars['Float']>
  latitudeIn?: InputMaybe<Array<Scalars['Float']>>
  latitudeLike?: InputMaybe<Scalars['Float']>
  latitudeLt?: InputMaybe<Scalars['Float']>
  latitudeLte?: InputMaybe<Scalars['Float']>
  latitudeNot?: InputMaybe<Scalars['Float']>
  latitudeNotIn?: InputMaybe<Array<Scalars['Float']>>
  longitude?: InputMaybe<Scalars['Float']>
  longitudeGt?: InputMaybe<Scalars['Float']>
  longitudeGte?: InputMaybe<Scalars['Float']>
  longitudeIn?: InputMaybe<Array<Scalars['Float']>>
  longitudeLike?: InputMaybe<Scalars['Float']>
  longitudeLt?: InputMaybe<Scalars['Float']>
  longitudeLte?: InputMaybe<Scalars['Float']>
  longitudeNot?: InputMaybe<Scalars['Float']>
  longitudeNotIn?: InputMaybe<Array<Scalars['Float']>>
  name?: InputMaybe<Scalars['String']>
  nameGt?: InputMaybe<Scalars['String']>
  nameGte?: InputMaybe<Scalars['String']>
  nameIn?: InputMaybe<Array<Scalars['String']>>
  nameLike?: InputMaybe<Scalars['String']>
  nameLt?: InputMaybe<Scalars['String']>
  nameLte?: InputMaybe<Scalars['String']>
  nameNot?: InputMaybe<Scalars['String']>
  nameNotIn?: InputMaybe<Array<Scalars['String']>>
  OR?: InputMaybe<Array<FacilityFilterInput>>
  shortName?: InputMaybe<Scalars['String']>
  shortNameGt?: InputMaybe<Scalars['String']>
  shortNameGte?: InputMaybe<Scalars['String']>
  shortNameIn?: InputMaybe<Array<Scalars['String']>>
  shortNameLike?: InputMaybe<Scalars['String']>
  shortNameLt?: InputMaybe<Scalars['String']>
  shortNameLte?: InputMaybe<Scalars['String']>
  shortNameNot?: InputMaybe<Scalars['String']>
  shortNameNotIn?: InputMaybe<Array<Scalars['String']>>
  timeZone?: InputMaybe<Scalars['String']>
  type?: InputMaybe<FacilityType>
  typeIn?: InputMaybe<Array<FacilityType>>
  typeNot?: InputMaybe<FacilityType>
  typeNotIn?: InputMaybe<Array<FacilityType>>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
}

export type GeoFenceBoxInput = {
  northEastLatitude: Scalars['Float']
  northEastLongitude: Scalars['Float']
  southWestLatitude: Scalars['Float']
  southWestLongitude: Scalars['Float']
}

/** *Automatically generated.* */
export type FacilityOrderInput = {
  direction: OrderDirection
  field: FacilityOrderField
}

/** *Automatically generated.* */
export enum FacilityOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  Latitude = 'LATITUDE',
  Longitude = 'LONGITUDE',
  Name = 'NAME',
  ShortName = 'SHORT_NAME',
  Type = 'TYPE',
  UpdatedAt = 'UPDATED_AT',
}

/** *Automatically generated.* */
export type FacilityConnection = {
  __typename?: 'FacilityConnection'
  edges?: Maybe<Array<Maybe<FacilityEdge>>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** *Automatically generated.* */
export type FacilityEdge = {
  __typename?: 'FacilityEdge'
  cursor: Scalars['String']
  node?: Maybe<Facility>
}

/** *Automatically generated.* */
export type FloorGroupConnection = {
  __typename?: 'FloorGroupConnection'
  edges?: Maybe<Array<Maybe<FloorGroupEdge>>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** *Automatically generated.* */
export type FloorGroupEdge = {
  __typename?: 'FloorGroupEdge'
  cursor: Scalars['String']
  node?: Maybe<FloorGroupNode>
}

/** *Automatically generated.* */
export type FloorGroupNode = {
  __typename?: 'FloorGroupNode'
  count?: Maybe<Scalars['Int']>
  dimensions?: Maybe<FloorGroupDimension>
  uniq?: Maybe<FloorGroupUniq>
}

/**
 * *Automatically generated.*
 *
 * Any fields selected on this type will be used for aggregation.
 * (This is like `GROUP BY` in SQL.)
 */
export type FloorGroupDimension = {
  __typename?: 'FloorGroupDimension'
  createdAt?: Maybe<Scalars['String']>
  facilityId?: Maybe<Scalars['ID']>
  floorPlanId?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
}

/**
 * *Automatically generated.*
 *
 * Each field of this type is a count of the number of distinct
 * values of another field: a field of the same name on the
 * generating type.
 */
export type FloorGroupUniq = {
  __typename?: 'FloorGroupUniq'
  createdAt?: Maybe<Scalars['Int']>
  facilityId?: Maybe<Scalars['Int']>
  floorPlanId?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['Int']>
  updatedAt?: Maybe<Scalars['Int']>
}

/** *Automatically generated.* */
export type FloorPlanFilterInput = {
  AND?: InputMaybe<Array<FloorPlanFilterInput>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  floorId?: InputMaybe<Scalars['ID']>
  floorIdIn?: InputMaybe<Array<Scalars['ID']>>
  floorIdNot?: InputMaybe<Scalars['ID']>
  floorIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  name?: InputMaybe<Scalars['String']>
  nameGt?: InputMaybe<Scalars['String']>
  nameGte?: InputMaybe<Scalars['String']>
  nameIn?: InputMaybe<Array<Scalars['String']>>
  nameLike?: InputMaybe<Scalars['String']>
  nameLt?: InputMaybe<Scalars['String']>
  nameLte?: InputMaybe<Scalars['String']>
  nameNot?: InputMaybe<Scalars['String']>
  nameNotIn?: InputMaybe<Array<Scalars['String']>>
  OR?: InputMaybe<Array<FloorPlanFilterInput>>
  s3Key?: InputMaybe<Scalars['String']>
  s3KeyGt?: InputMaybe<Scalars['String']>
  s3KeyGte?: InputMaybe<Scalars['String']>
  s3KeyIn?: InputMaybe<Array<Scalars['String']>>
  s3KeyLike?: InputMaybe<Scalars['String']>
  s3KeyLt?: InputMaybe<Scalars['String']>
  s3KeyLte?: InputMaybe<Scalars['String']>
  s3KeyNot?: InputMaybe<Scalars['String']>
  s3KeyNotIn?: InputMaybe<Array<Scalars['String']>>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
}

/** *Automatically generated.* */
export type FloorPlanOrderInput = {
  direction: OrderDirection
  field: FloorPlanOrderField
}

/** *Automatically generated.* */
export enum FloorPlanOrderField {
  CreatedAt = 'CREATED_AT',
  FloorId = 'FLOOR_ID',
  Id = 'ID',
  Name = 'NAME',
  S3Key = 'S3_KEY',
  UpdatedAt = 'UPDATED_AT',
}

/** *Automatically generated.* */
export type FloorPlanConnection = {
  __typename?: 'FloorPlanConnection'
  edges?: Maybe<Array<Maybe<FloorPlanEdge>>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** *Automatically generated.* */
export type FloorPlanEdge = {
  __typename?: 'FloorPlanEdge'
  cursor: Scalars['String']
  node?: Maybe<FloorPlan>
}

export type IncidentCountMap = {
  __typename?: 'IncidentCountMap'
  camera?: Maybe<Scalars['Int']>
  door?: Maybe<Scalars['Int']>
  health?: Maybe<Scalars['Int']>
  overall?: Maybe<Scalars['Int']>
}

/** *Automatically generated.* */
export type IncidentDeviceMediaFilterInput = {
  AND?: InputMaybe<Array<IncidentDeviceMediaFilterInput>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  deviceId?: InputMaybe<Scalars['ID']>
  deviceIdIn?: InputMaybe<Array<Scalars['ID']>>
  deviceIdNot?: InputMaybe<Scalars['ID']>
  deviceIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  incidentId?: InputMaybe<Scalars['ID']>
  incidentIdIn?: InputMaybe<Array<Scalars['ID']>>
  incidentIdNot?: InputMaybe<Scalars['ID']>
  incidentIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  OR?: InputMaybe<Array<IncidentDeviceMediaFilterInput>>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
}

/** *Automatically generated.* */
export type IncidentDeviceMediaOrderInput = {
  direction: OrderDirection
  field: IncidentDeviceMediaOrderField
}

/** *Automatically generated.* */
export enum IncidentDeviceMediaOrderField {
  CreatedAt = 'CREATED_AT',
  DeviceId = 'DEVICE_ID',
  Id = 'ID',
  IncidentId = 'INCIDENT_ID',
  UpdatedAt = 'UPDATED_AT',
}

/** *Automatically generated.* */
export type IncidentDeviceMediaConnection = {
  __typename?: 'IncidentDeviceMediaConnection'
  edges?: Maybe<Array<Maybe<IncidentDeviceMediaEdge>>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** *Automatically generated.* */
export type IncidentDeviceMediaEdge = {
  __typename?: 'IncidentDeviceMediaEdge'
  cursor: Scalars['String']
  node?: Maybe<IncidentDeviceMedia>
}

/**
 * Associates an incident with:
 * * a device (in this case a camera)
 * * a list of intervals which record when relevant
 * footage was recorded on that camera.
 */
export type IncidentDeviceMedia = {
  __typename?: 'IncidentDeviceMedia'
  /** *Automatically generated.* */
  createdAt: Scalars['String']
  device?: Maybe<Device>
  deviceId: Scalars['ID']
  id: Scalars['ID']
  incidentId: Scalars['ID']
  intervals?: Maybe<Array<Maybe<Interval>>>
  /** *Automatically generated.* */
  updatedAt: Scalars['String']
}

export type Interval = {
  __typename?: 'Interval'
  endTime?: Maybe<Scalars['String']>
  startTime?: Maybe<Scalars['String']>
}

/** *Automatically generated.* */
export type IncidentGroupConnection = {
  __typename?: 'IncidentGroupConnection'
  edges?: Maybe<Array<Maybe<IncidentGroupEdge>>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** *Automatically generated.* */
export type IncidentGroupEdge = {
  __typename?: 'IncidentGroupEdge'
  cursor: Scalars['String']
  node?: Maybe<IncidentGroupNode>
}

/** *Automatically generated.* */
export type IncidentGroupNode = {
  __typename?: 'IncidentGroupNode'
  avg?: Maybe<IncidentGroupAvg>
  count?: Maybe<Scalars['Int']>
  dimensions?: Maybe<IncidentGroupDimension>
  sum?: Maybe<IncidentGroupSum>
  uniq?: Maybe<IncidentGroupUniq>
}

/** *Automatically generated.* */
export type IncidentGroupAvg = {
  __typename?: 'IncidentGroupAvg'
  latitude?: Maybe<Scalars['Float']>
  longitude?: Maybe<Scalars['Float']>
}

/**
 * *Automatically generated.*
 *
 * Any fields selected on this type will be used for aggregation.
 * (This is like `GROUP BY` in SQL.)
 */
export type IncidentGroupDimension = {
  __typename?: 'IncidentGroupDimension'
  acknowledged?: Maybe<Scalars['Boolean']>
  actionType?: Maybe<IncidentActionType>
  actionUpdatedAt?: Maybe<Scalars['String']>
  concernStartTime?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  creatorId?: Maybe<Scalars['ID']>
  description?: Maybe<Scalars['String']>
  dispatched?: Maybe<Scalars['Boolean']>
  displayId?: Maybe<Scalars['String']>
  facilityId?: Maybe<Scalars['ID']>
  facilityName?: Maybe<Scalars['String']>
  facilityType?: Maybe<FacilityType>
  floorId?: Maybe<Scalars['ID']>
  floorName?: Maybe<Scalars['String']>
  guardId?: Maybe<Scalars['ID']>
  hasAudioMessages?: Maybe<Scalars['Boolean']>
  informationSolicited?: Maybe<Scalars['Boolean']>
  latitude?: Maybe<Scalars['Float']>
  longitude?: Maybe<Scalars['Float']>
  name?: Maybe<Scalars['String']>
  nextPossibleGuardAction?: Maybe<IncidentGuardActionType>
  offered?: Maybe<Scalars['Boolean']>
  ownerId?: Maybe<Scalars['ID']>
  priority?: Maybe<IncidentPriority>
  real?: Maybe<Scalars['Boolean']>
  sensor?: Maybe<DeviceType>
  source?: Maybe<IncidentSource>
  status?: Maybe<IncidentStatus>
  type?: Maybe<IncidentType>
  updatedAt?: Maybe<Scalars['String']>
}

/** *Automatically generated.* */
export type IncidentGroupSum = {
  __typename?: 'IncidentGroupSum'
  latitude?: Maybe<Scalars['Float']>
  longitude?: Maybe<Scalars['Float']>
}

/**
 * *Automatically generated.*
 *
 * Each field of this type is a count of the number of distinct
 * values of another field: a field of the same name on the
 * generating type.
 */
export type IncidentGroupUniq = {
  __typename?: 'IncidentGroupUniq'
  acknowledged?: Maybe<Scalars['Int']>
  actionType?: Maybe<Scalars['Int']>
  actionUpdatedAt?: Maybe<Scalars['Int']>
  concernStartTime?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['Int']>
  creatorId?: Maybe<Scalars['Int']>
  description?: Maybe<Scalars['Int']>
  dispatched?: Maybe<Scalars['Int']>
  displayId?: Maybe<Scalars['Int']>
  facilityId?: Maybe<Scalars['Int']>
  facilityName?: Maybe<Scalars['Int']>
  facilityType?: Maybe<Scalars['Int']>
  floorId?: Maybe<Scalars['Int']>
  floorName?: Maybe<Scalars['Int']>
  guardId?: Maybe<Scalars['Int']>
  hasAudioMessages?: Maybe<Scalars['Int']>
  informationSolicited?: Maybe<Scalars['Int']>
  latitude?: Maybe<Scalars['Int']>
  longitude?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['Int']>
  nextPossibleGuardAction?: Maybe<Scalars['Int']>
  offered?: Maybe<Scalars['Int']>
  ownerId?: Maybe<Scalars['Int']>
  priority?: Maybe<Scalars['Int']>
  real?: Maybe<Scalars['Int']>
  sensor?: Maybe<Scalars['Int']>
  source?: Maybe<Scalars['Int']>
  status?: Maybe<Scalars['Int']>
  type?: Maybe<Scalars['Int']>
  updatedAt?: Maybe<Scalars['Int']>
}

/**
 * A collection of statistics about incidents in a time window.
 *
 * Unless the documentation of a field states otherwise,
 * the time window is a closed interval (i.e. it contains its
 * start time and end time).
 */
export type IncidentTimePeriodStats = {
  __typename?: 'IncidentTimePeriodStats'
  /**
   * For incidents acknowledged in the time window,
   * the average time between
   * creation of the incident
   * and
   * acknowledgement of the incident.
   *
   * <i>
   *
   * In case it's not obvious:
   *
   * * Included in the count are incidents
   * created before the time window
   * and
   * acknowledged during the time window.
   * * Excluded from the count are incidents
   * created during the time window
   * and
   * acknowledged after the time window.
   *
   * </i>
   */
  avgTimeToAcknowledge?: Maybe<Scalars['Float']>
  /**
   * For incidents resolved in the time window
   * (i.e. incidents whose `status` became `RESOLVED`
   * during the time window),
   * the average time between
   * creation of the incident
   * and
   * resolution of the incident.
   *
   * <i>
   *
   * In case it's not obvious:
   * * Included in the count are incidents
   * created before the time window
   * and
   * resolved during the time window.
   * * Excluded from the count are incidents
   * created during the time window
   * and
   * resolved after the time window.
   *
   * </i>
   */
  avgTimeToResolve?: Maybe<Scalars['Float']>
  /**
   * *F*, given the following definitions.
   *
   * | Variable | Definition |
   * | --- | --- |
   * | *C_0* | The number of unresolved incidents at the start of the time range. |
   * | *C_1* | The number of incidents created during the time range. |
   * | *C_2* | The number of incidents resolved during the time range. |
   * | *F* | *C_2 / (C_0 + C_1)* |
   */
  fractionResolved?: Maybe<Scalars['Float']>
  /**
   * The total number of incidents which were unresolved
   * at some point in the time interval.
   * ('Unresolved' means: `status` was something other than `RESOLVED`.)
   *
   * *This is the sum of 2 numbers:
   * the number of incidents unresolved
   * at the start of the time window
   * and
   * the number of incidents created
   * during the time window.
   * The second number is included in the sum because
   * every incident is unresolved at the instant
   * of creation.*
   *
   * *'numUnresolved' is not a good name, because
   * it tends to suggest a count at a *point* in time.
   * Suggestions for a better name are welcome.*
   */
  numUnresolved?: Maybe<Scalars['Int']>
}

export type Kpi = {
  __typename?: 'Kpi'
  averageGuardAcknowledgmentTime?: Maybe<KpiQueryResult>
  averageGuardOnScene?: Maybe<KpiQueryResult>
  averageGuardToClear?: Maybe<KpiQueryResult>
  averageOperatorTimeToAcknowledge?: Maybe<KpiQueryResult>
  averageOperatorTimeToResolve?: Maybe<KpiQueryResult>
  averageTimeToAcknowledgeByFacility?: Maybe<
    Array<Maybe<KpiAggregateQueryResult>>
  >
  averageTimeToResolveByFacility?: Maybe<Array<Maybe<KpiAggregateQueryResult>>>
  countIncidentsByFacility?: Maybe<Array<Maybe<KpiAggregateQueryResult>>>
  countIncidentsByTypeBetweenDates?: Maybe<IncidentsByTypeBetweenDateResult>
  incidentResolutionRate?: Maybe<Scalars['Float']>
  medianOperatorTimeToAcknowledge?: Maybe<KpiQueryResult>
  medianOperatorTimeToResolve?: Maybe<KpiQueryResult>
  medianTimeToAcknowledgeByFacility?: Maybe<
    Array<Maybe<KpiAggregateQueryResult>>
  >
  medianTimeToResolveByFacility?: Maybe<Array<Maybe<KpiAggregateQueryResult>>>
  totalActiveIncidents?: Maybe<KpiQueryResult>
  totalEnrolledDevices?: Maybe<Scalars['Int']>
  totalIncidents?: Maybe<KpiQueryResult>
}

export type KpiAverageGuardAcknowledgmentTimeArgs = {
  guards?: InputMaybe<Array<Scalars['ID']>>
  hours: Scalars['Int']
}

export type KpiAverageGuardOnSceneArgs = {
  guards?: InputMaybe<Array<Scalars['ID']>>
  hours: Scalars['Int']
}

export type KpiAverageGuardToClearArgs = {
  guards?: InputMaybe<Array<Scalars['ID']>>
  hours: Scalars['Int']
}

export type KpiAverageOperatorTimeToAcknowledgeArgs = {
  hours: Scalars['Int']
  owners?: InputMaybe<Array<Scalars['ID']>>
}

export type KpiAverageOperatorTimeToResolveArgs = {
  hours: Scalars['Int']
  owners?: InputMaybe<Array<Scalars['ID']>>
}

export type KpiAverageTimeToAcknowledgeByFacilityArgs = {
  hours: Scalars['Int']
}

export type KpiAverageTimeToResolveByFacilityArgs = {
  hours: Scalars['Int']
}

export type KpiCountIncidentsByFacilityArgs = {
  hours: Scalars['Int']
}

export type KpiCountIncidentsByTypeBetweenDatesArgs = {
  endDate?: InputMaybe<Scalars['String']>
  real?: InputMaybe<Scalars['Boolean']>
  startDate?: InputMaybe<Scalars['String']>
  types?: InputMaybe<Array<InputMaybe<IncidentType>>>
}

export type KpiIncidentResolutionRateArgs = {
  hours: Scalars['Int']
}

export type KpiMedianOperatorTimeToAcknowledgeArgs = {
  hours: Scalars['Int']
  owners?: InputMaybe<Array<Scalars['ID']>>
}

export type KpiMedianOperatorTimeToResolveArgs = {
  hours: Scalars['Int']
  owners?: InputMaybe<Array<Scalars['ID']>>
}

export type KpiMedianTimeToAcknowledgeByFacilityArgs = {
  hours: Scalars['Int']
}

export type KpiMedianTimeToResolveByFacilityArgs = {
  hours: Scalars['Int']
}

export type KpiTotalActiveIncidentsArgs = {
  hours: Scalars['Int']
}

export type KpiTotalEnrolledDevicesArgs = {
  hours: Scalars['Int']
  type?: InputMaybe<DeviceType>
}

export type KpiTotalIncidentsArgs = {
  hours: Scalars['Int']
}

export type KpiQueryResult = {
  __typename?: 'KpiQueryResult'
  current?: Maybe<Scalars['Int']>
  past?: Maybe<Scalars['Int']>
}

export type KpiAggregateQueryResult = {
  __typename?: 'KpiAggregateQueryResult'
  count?: Maybe<Scalars['Int']>
  label?: Maybe<Scalars['String']>
}

export type IncidentsByTypeBetweenDateResult = {
  __typename?: 'IncidentsByTypeBetweenDateResult'
  incidentsTypeByDate?: Maybe<Array<Maybe<IncidentsTypesByDate>>>
  totalCount?: Maybe<Scalars['Int']>
}

export type IncidentsTypesByDate = {
  __typename?: 'IncidentsTypesByDate'
  date?: Maybe<Scalars['String']>
  day?: Maybe<Scalars['String']>
  incidentsTypes?: Maybe<Array<Maybe<IncidentsByTypeCount>>>
}

export type IncidentsByTypeCount = {
  __typename?: 'IncidentsByTypeCount'
  count?: Maybe<Scalars['Int']>
  type?: Maybe<Scalars['String']>
}

export type DatabaseReset = {
  __typename?: 'DatabaseReset'
  createdAt: Scalars['String']
  createdBy: Scalars['String']
}

export type ManualIncidentCategoryFilterInput = {
  AND?: InputMaybe<Array<ManualIncidentCategoryFilterInput>>
  isQuickReportCategory?: InputMaybe<Scalars['Boolean']>
  mutable?: InputMaybe<Scalars['Boolean']>
  OR?: InputMaybe<Array<ManualIncidentCategoryFilterInput>>
}

export type ManualIncidentCategoryConnection = {
  __typename?: 'ManualIncidentCategoryConnection'
  edges?: Maybe<Array<Maybe<ManualIncidentCategoryEdge>>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

export type ManualIncidentCategoryEdge = {
  __typename?: 'ManualIncidentCategoryEdge'
  cursor?: Maybe<Scalars['String']>
  node?: Maybe<ManualIncidentCategory>
}

/** *Automatically generated.* */
export type SubscriberMessageFilterInput = {
  AND?: InputMaybe<Array<SubscriberMessageFilterInput>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  OR?: InputMaybe<Array<SubscriberMessageFilterInput>>
}

/** *Automatically generated.* */
export type SubscriberMessageOrderInput = {
  direction: OrderDirection
  field: SubscriberMessageOrderField
}

/** *Automatically generated.* */
export enum SubscriberMessageOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
}

/** *Automatically generated.* */
export type SubscriberMessageConnection = {
  __typename?: 'SubscriberMessageConnection'
  edges?: Maybe<Array<Maybe<SubscriberMessageEdge>>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** *Automatically generated.* */
export type SubscriberMessageEdge = {
  __typename?: 'SubscriberMessageEdge'
  cursor: Scalars['String']
  node?: Maybe<SubscriberMessage>
}

export type PacsSourceInstance = {
  __typename?: 'PacsSourceInstance'
  createdAt: Scalars['String']
  fields?: Maybe<Array<Maybe<PacsSourceFieldInstance>>>
  id: Scalars['ID']
  name: Scalars['String']
  pacsSource?: Maybe<PacsSource>
  updatedAt: Scalars['String']
}

export type PacsSourceFieldInstance = {
  __typename?: 'PacsSourceFieldInstance'
  createdAt: Scalars['String']
  id: Scalars['ID']
  pacsSourceField?: Maybe<PacsSourceField>
  updatedAt: Scalars['String']
  value: Scalars['String']
}

export type PacsSourceField = {
  __typename?: 'PacsSourceField'
  createdAt: Scalars['String']
  id: Scalars['ID']
  name: Scalars['String']
  secure?: Maybe<Scalars['Boolean']>
  updatedAt: Scalars['String']
}

export type PacsSource = {
  __typename?: 'PacsSource'
  createdAt: Scalars['String']
  fields?: Maybe<Array<Maybe<PacsSourceField>>>
  id: Scalars['ID']
  name: Scalars['String']
  updatedAt: Scalars['String']
}

export type PacsSourceInstanceConnection = {
  __typename?: 'PacsSourceInstanceConnection'
  edges?: Maybe<Array<Maybe<PacsSourceInstanceEdge>>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

export type PacsSourceInstanceEdge = {
  __typename?: 'PacsSourceInstanceEdge'
  cursor: Scalars['String']
  node?: Maybe<PacsSourceInstance>
}

export type PacsSourceConnection = {
  __typename?: 'PacsSourceConnection'
  edges?: Maybe<Array<Maybe<PacsSourceEdge>>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

export type PacsSourceEdge = {
  __typename?: 'PacsSourceEdge'
  cursor: Scalars['String']
  node?: Maybe<PacsSource>
}

export type PartialCreditCard = {
  __typename?: 'PartialCreditCard'
  address?: Maybe<Array<Scalars['String']>>
  /** *Automatically generated.* */
  createdAt: Scalars['String']
  endOfNumber?: Maybe<Scalars['String']>
  expiryMonth?: Maybe<Scalars['Int']>
  expiryYear?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  /** *Automatically generated.* */
  updatedAt: Scalars['String']
}

export type PersonOfInterestConnection = {
  __typename?: 'PersonOfInterestConnection'
  edges?: Maybe<Array<PersonOfInterestEdge>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

export type PersonOfInterestEdge = {
  __typename?: 'PersonOfInterestEdge'
  cursor: Scalars['String']
  node?: Maybe<PersonOfInterest>
}

export type QuickReport = {
  __typename?: 'QuickReport'
  createdAt: Scalars['String']
  formDescription: Scalars['String']
  formTitle: Scalars['String']
  id: Scalars['ID']
  incidentCategories?: Maybe<ManualIncidentCategoryConnection>
  logoAssetId: Scalars['ID']
  logoUrl?: Maybe<Scalars['String']>
  qrCodeUrl?: Maybe<Scalars['String']>
  updatedAt: Scalars['String']
}

export type QuickReportCode = {
  __typename?: 'QuickReportCode'
  createdAt: Scalars['String']
  id: Scalars['ID']
  isCurrent: Scalars['Boolean']
  qrCodeUrl?: Maybe<Scalars['String']>
  updatedAt: Scalars['String']
  updatedBy: Scalars['ID']
}

export type QuickReportCodeOrderInput = {
  direction: OrderDirection
  field: QuickReportCodeOrderField
}

export enum QuickReportCodeOrderField {
  CreatedAt = 'CREATED_AT',
  UpdatedBy = 'UPDATED_BY',
}

export type QuickReportCodeConnection = {
  __typename?: 'QuickReportCodeConnection'
  edges?: Maybe<Array<Maybe<QuickReportCodeEdge>>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

export type QuickReportCodeEdge = {
  __typename?: 'QuickReportCodeEdge'
  cursor: Scalars['String']
  node?: Maybe<QuickReportCode>
}

/** *Automatically generated.* */
export type RoleFilterInput = {
  AND?: InputMaybe<Array<RoleFilterInput>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  name?: InputMaybe<Scalars['String']>
  nameGt?: InputMaybe<Scalars['String']>
  nameGte?: InputMaybe<Scalars['String']>
  nameIn?: InputMaybe<Array<Scalars['String']>>
  nameLike?: InputMaybe<Scalars['String']>
  nameLt?: InputMaybe<Scalars['String']>
  nameLte?: InputMaybe<Scalars['String']>
  nameNot?: InputMaybe<Scalars['String']>
  nameNotIn?: InputMaybe<Array<Scalars['String']>>
  OR?: InputMaybe<Array<RoleFilterInput>>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
}

/** *Automatically generated.* */
export type RoleOrderInput = {
  direction: OrderDirection
  field: RoleOrderField
}

/** *Automatically generated.* */
export enum RoleOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  Name = 'NAME',
  UpdatedAt = 'UPDATED_AT',
}

/** *Automatically generated.* */
export type RoleConnection = {
  __typename?: 'RoleConnection'
  edges?: Maybe<Array<Maybe<RoleEdge>>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** *Automatically generated.* */
export type RoleEdge = {
  __typename?: 'RoleEdge'
  cursor: Scalars['String']
  node?: Maybe<Role>
}

export type Rule = {
  __typename?: 'Rule'
  /** Action to perform. In the future, we may want a multi-selectable list. */
  action: Action
  createdAt: Scalars['String']
  /**
   * Combo of the days of the week. Su = Sunday, Mo = Monday, Tu = Tuesday,
   * We = Wednesday, Th = Thursday, Fr = Friday, Sa = Saturday. Days must be in this
   * order and should not repeat. e.g. "MoTu", "SuMoTuWeThFrSa", "TuTh", "SuSa"
   */
  day?: Maybe<Scalars['String']>
  endDate?: Maybe<Scalars['String']>
  endTimeOfDay?: Maybe<Scalars['String']>
  id: Scalars['ID']
  lastMatched?: Maybe<Scalars['String']>
  name: Scalars['String']
  repeatInterval?: Maybe<RepeatInterval>
  repeatIntervalNum?: Maybe<Scalars['Int']>
  ruleEventType: Array<RuleEventType>
  ruleLogics?: Maybe<RuleLogicConnection>
  startTime: Scalars['String']
  /** True = active, False = deactivated */
  status: Scalars['Boolean']
  updatedAt: Scalars['String']
}

export type RuleRuleLogicsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<RuleLogicFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<RuleLogicOrderInput>>>
}

export enum Action {
  EnableAiOperator = 'ENABLE_AI_OPERATOR',
  Mute = 'MUTE',
}

export enum RepeatInterval {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
}

export enum RuleEventType {
  Alarm = 'ALARM',
  /** 'ALL' means all current and future event types */
  All = 'ALL',
  DoorForcedOpen = 'DOOR_FORCED_OPEN',
  DoorHeldOpen = 'DOOR_HELD_OPEN',
  DoorOpenAlarm = 'DOOR_OPEN_ALARM',
  Tailgating = 'TAILGATING',
}

export type RuleLogicFilterInput = {
  ruleId?: InputMaybe<Scalars['ID']>
}

export type RuleLogicOrderInput = {
  direction: OrderDirection
  field: RuleLogicOrderField
}

export enum RuleLogicOrderField {
  Id = 'ID',
}

export type RuleLogicConnection = {
  __typename?: 'RuleLogicConnection'
  edges?: Maybe<Array<Maybe<RuleLogicEdge>>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

export type RuleLogicEdge = {
  __typename?: 'RuleLogicEdge'
  cursor: Scalars['String']
  node?: Maybe<RuleLogic>
}

export type RuleLogic = {
  __typename?: 'RuleLogic'
  children?: Maybe<RuleLogicConnection>
  comparator: Comparator
  /** In the future, entity may become a String type */
  entity?: Maybe<Entity>
  entityAttribute?: Maybe<Scalars['String']>
  /** This is an integer represented as a string */
  id?: Maybe<Scalars['String']>
  operator?: Maybe<Operator>
  ruleId?: Maybe<Scalars['ID']>
  value?: Maybe<Scalars['String']>
}

export enum Comparator {
  Equals = 'EQUALS',
}

export enum Entity {
  Device = 'DEVICE',
}

export enum Operator {
  Or = 'OR',
}

export type RuleFilterInput = {
  action?: InputMaybe<Action>
  actionIn?: InputMaybe<Array<Action>>
  actionNot?: InputMaybe<Action>
  actionNotIn?: InputMaybe<Array<Action>>
  AND?: InputMaybe<Array<RuleFilterInput>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  day?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  lastMatched?: InputMaybe<Scalars['String']>
  lastMatchedGt?: InputMaybe<Scalars['String']>
  lastMatchedGte?: InputMaybe<Scalars['String']>
  lastMatchedIn?: InputMaybe<Array<Scalars['String']>>
  lastMatchedLike?: InputMaybe<Scalars['String']>
  lastMatchedLt?: InputMaybe<Scalars['String']>
  lastMatchedLte?: InputMaybe<Scalars['String']>
  lastMatchedNot?: InputMaybe<Scalars['String']>
  lastMatchedNotIn?: InputMaybe<Array<Scalars['String']>>
  name?: InputMaybe<Scalars['String']>
  nameGt?: InputMaybe<Scalars['String']>
  nameGte?: InputMaybe<Scalars['String']>
  nameIn?: InputMaybe<Array<Scalars['String']>>
  nameLike?: InputMaybe<Scalars['String']>
  nameLt?: InputMaybe<Scalars['String']>
  nameLte?: InputMaybe<Scalars['String']>
  nameNot?: InputMaybe<Scalars['String']>
  nameNotIn?: InputMaybe<Array<Scalars['String']>>
  OR?: InputMaybe<Array<RuleFilterInput>>
  repeatInterval?: InputMaybe<Scalars['ID']>
  repeatIntervalIn?: InputMaybe<Array<Scalars['ID']>>
  repeatIntervalNot?: InputMaybe<Scalars['ID']>
  repeatIntervalNotIn?: InputMaybe<Array<Scalars['ID']>>
  ruleEventType?: InputMaybe<Array<InputMaybe<RuleEventType>>>
  ruleEventTypeContain?: InputMaybe<RuleEventType>
  ruleEventTypeDoNotContain?: InputMaybe<RuleEventType>
  ruleLogicIdIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  status?: InputMaybe<Scalars['Boolean']>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
}

export type RuleOrderInput = {
  direction: OrderDirection
  field: RuleOrderField
}

export enum RuleOrderField {
  Action = 'ACTION',
  CreatedAt = 'CREATED_AT',
  EndDate = 'END_DATE',
  LastMatched = 'LAST_MATCHED',
  Name = 'NAME',
  RepeatInterval = 'REPEAT_INTERVAL',
  RuleEventType = 'RULE_EVENT_TYPE',
  StartDate = 'START_DATE',
  Status = 'STATUS',
  UpdatedAt = 'UPDATED_AT',
}

export type RuleConnection = {
  __typename?: 'RuleConnection'
  edges?: Maybe<Array<Maybe<RuleEdge>>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

export type RuleEdge = {
  __typename?: 'RuleEdge'
  cursor: Scalars['String']
  node?: Maybe<Rule>
}

export type SavedFilterConnection = {
  __typename?: 'SavedFilterConnection'
  edges?: Maybe<Array<Maybe<SavedFilterEdge>>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

export type SavedFilterEdge = {
  __typename?: 'SavedFilterEdge'
  cursor: Scalars['String']
  node?: Maybe<SavedFilter>
}

export type Session = {
  __typename?: 'Session'
  user?: Maybe<User>
}

export type StandardOperatingProcedure = {
  __typename?: 'StandardOperatingProcedure'
  /** *Automatically generated.* */
  createdAt: Scalars['String']
  creator?: Maybe<User>
  creatorId?: Maybe<Scalars['ID']>
  /**
   * The client creates the StandardOperatingProcedure with some external
   * service, receiving a unique ID, then uses that ID for this
   * field.
   */
  externalId: Scalars['String']
  facilities?: Maybe<FacilityConnection>
  facilityIds?: Maybe<Array<Scalars['ID']>>
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  status?: Maybe<VirusScanStatus>
  type: SopType
  /** *Automatically generated.* */
  updatedAt: Scalars['String']
  /** A file in a format such as PDF. */
  url?: Maybe<Scalars['String']>
}

export type StandardOperatingProcedureFacilitiesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<FacilityFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<FacilityOrderInput>>>
}

export enum VirusScanStatus {
  Clean = 'CLEAN',
  Infected = 'INFECTED',
  Unscanned = 'UNSCANNED',
}

export enum SopType {
  Guard = 'GUARD',
  Operator = 'OPERATOR',
}

/** *Automatically generated.* */
export type StandardOperatingProcedureFilterInput = {
  AND?: InputMaybe<Array<StandardOperatingProcedureFilterInput>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  creatorId?: InputMaybe<Scalars['ID']>
  creatorIdIn?: InputMaybe<Array<Scalars['ID']>>
  creatorIdNot?: InputMaybe<Scalars['ID']>
  creatorIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  externalId?: InputMaybe<Scalars['String']>
  externalIdGt?: InputMaybe<Scalars['String']>
  externalIdGte?: InputMaybe<Scalars['String']>
  externalIdIn?: InputMaybe<Array<Scalars['String']>>
  externalIdLike?: InputMaybe<Scalars['String']>
  externalIdLt?: InputMaybe<Scalars['String']>
  externalIdLte?: InputMaybe<Scalars['String']>
  externalIdNot?: InputMaybe<Scalars['String']>
  externalIdNotIn?: InputMaybe<Array<Scalars['String']>>
  facilityIdsContain?: InputMaybe<Scalars['ID']>
  facilityIdsDoNotContain?: InputMaybe<Scalars['ID']>
  facilityIdsNull?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  name?: InputMaybe<Scalars['String']>
  nameGt?: InputMaybe<Scalars['String']>
  nameGte?: InputMaybe<Scalars['String']>
  nameIn?: InputMaybe<Array<Scalars['String']>>
  nameLike?: InputMaybe<Scalars['String']>
  nameLt?: InputMaybe<Scalars['String']>
  nameLte?: InputMaybe<Scalars['String']>
  nameNot?: InputMaybe<Scalars['String']>
  nameNotIn?: InputMaybe<Array<Scalars['String']>>
  OR?: InputMaybe<Array<StandardOperatingProcedureFilterInput>>
  type?: InputMaybe<SopType>
  typeIn?: InputMaybe<Array<SopType>>
  typeNot?: InputMaybe<SopType>
  typeNotIn?: InputMaybe<Array<SopType>>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
  url?: InputMaybe<Scalars['String']>
  urlGt?: InputMaybe<Scalars['String']>
  urlGte?: InputMaybe<Scalars['String']>
  urlIn?: InputMaybe<Array<Scalars['String']>>
  urlLike?: InputMaybe<Scalars['String']>
  urlLt?: InputMaybe<Scalars['String']>
  urlLte?: InputMaybe<Scalars['String']>
  urlNot?: InputMaybe<Scalars['String']>
  urlNotIn?: InputMaybe<Array<Scalars['String']>>
}

/** *Automatically generated.* */
export type StandardOperatingProcedureOrderInput = {
  direction: OrderDirection
  field: StandardOperatingProcedureOrderField
}

/** *Automatically generated.* */
export enum StandardOperatingProcedureOrderField {
  CreatedAt = 'CREATED_AT',
  CreatorId = 'CREATOR_ID',
  ExternalId = 'EXTERNAL_ID',
  Id = 'ID',
  Name = 'NAME',
  UpdatedAt = 'UPDATED_AT',
  Url = 'URL',
}

/** *Automatically generated.* */
export type StandardOperatingProcedureConnection = {
  __typename?: 'StandardOperatingProcedureConnection'
  edges?: Maybe<Array<Maybe<StandardOperatingProcedureEdge>>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** *Automatically generated.* */
export type StandardOperatingProcedureEdge = {
  __typename?: 'StandardOperatingProcedureEdge'
  cursor: Scalars['String']
  node?: Maybe<StandardOperatingProcedure>
}

export type IntervalInput = {
  endTime?: InputMaybe<Scalars['String']>
  startTime?: InputMaybe<Scalars['String']>
}

/** *Automatically generated.* */
export type TechnicianFilterInput = {
  AND?: InputMaybe<Array<TechnicianFilterInput>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  deviceTypesContain?: InputMaybe<DeviceType>
  deviceTypesDoNotContain?: InputMaybe<DeviceType>
  deviceTypesNull?: InputMaybe<Scalars['Boolean']>
  email?: InputMaybe<Scalars['String']>
  emailGt?: InputMaybe<Scalars['String']>
  emailGte?: InputMaybe<Scalars['String']>
  emailIn?: InputMaybe<Array<Scalars['String']>>
  emailLike?: InputMaybe<Scalars['String']>
  emailLt?: InputMaybe<Scalars['String']>
  emailLte?: InputMaybe<Scalars['String']>
  emailNot?: InputMaybe<Scalars['String']>
  emailNotIn?: InputMaybe<Array<Scalars['String']>>
  facilityIdsContain?: InputMaybe<Scalars['ID']>
  facilityIdsDoNotContain?: InputMaybe<Scalars['ID']>
  facilityIdsNull?: InputMaybe<Scalars['Boolean']>
  firstName?: InputMaybe<Scalars['String']>
  firstNameGt?: InputMaybe<Scalars['String']>
  firstNameGte?: InputMaybe<Scalars['String']>
  firstNameIn?: InputMaybe<Array<Scalars['String']>>
  firstNameLike?: InputMaybe<Scalars['String']>
  firstNameLt?: InputMaybe<Scalars['String']>
  firstNameLte?: InputMaybe<Scalars['String']>
  firstNameNot?: InputMaybe<Scalars['String']>
  firstNameNotIn?: InputMaybe<Array<Scalars['String']>>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  lastName?: InputMaybe<Scalars['String']>
  lastNameGt?: InputMaybe<Scalars['String']>
  lastNameGte?: InputMaybe<Scalars['String']>
  lastNameIn?: InputMaybe<Array<Scalars['String']>>
  lastNameLike?: InputMaybe<Scalars['String']>
  lastNameLt?: InputMaybe<Scalars['String']>
  lastNameLte?: InputMaybe<Scalars['String']>
  lastNameNot?: InputMaybe<Scalars['String']>
  lastNameNotIn?: InputMaybe<Array<Scalars['String']>>
  OR?: InputMaybe<Array<TechnicianFilterInput>>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
}

/** *Automatically generated.* */
export type TechnicianOrderInput = {
  direction: OrderDirection
  field: TechnicianOrderField
}

/** *Automatically generated.* */
export enum TechnicianOrderField {
  CreatedAt = 'CREATED_AT',
  Email = 'EMAIL',
  FirstName = 'FIRST_NAME',
  Id = 'ID',
  LastName = 'LAST_NAME',
  UpdatedAt = 'UPDATED_AT',
}

/** *Automatically generated.* */
export type TechnicianConnection = {
  __typename?: 'TechnicianConnection'
  edges?: Maybe<Array<Maybe<TechnicianEdge>>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** *Automatically generated.* */
export type TechnicianEdge = {
  __typename?: 'TechnicianEdge'
  cursor: Scalars['String']
  node?: Maybe<Technician>
}

export type Mutation = {
  __typename?: 'Mutation'
  /**
   * Associate a video with an event.
   * The event must already exist in the database.
   *
   * It's an error to use this mutation if a
   * different video has already been associated with the event.
   * However, it's OK to use this mutation with an event
   * and video that have already been associated with
   * each other.
   */
  appendEventVideo?: Maybe<AppendEventVideoPayload>
  appendIncidentVideo?: Maybe<AppendIncidentVideoPayload>
  associateMultipleIncidentsToSingleCase?: Maybe<UpdateCasePayload>
  associatePersonOfInterestToCase?: Maybe<UpdateCasePayload>
  associateSingleIncidentToMultipleCases?: Maybe<UpdateIncidentPayload>
  changePassword?: Maybe<ChangePasswordPayload>
  /** *Automatically generated.* */
  createAcknowledgement?: Maybe<CreateAcknowledgementPayload>
  createAlarm?: Maybe<CreateAlarmPayload>
  /** *Automatically generated.* */
  createAlert?: Maybe<CreateAlertPayload>
  /** *Automatically generated.* */
  createAudioClip?: Maybe<CreateAudioClipPayload>
  /** *Automatically generated.* */
  createAudioMessage?: Maybe<CreateAudioMessagePayload>
  createCase?: Maybe<CreateCasePayload>
  createCaseComment?: Maybe<CreateCaseCommentPayload>
  /** *Automatically generated.* */
  createCharge?: Maybe<CreateChargePayload>
  /** *Automatically generated.* */
  createCheckIn?: Maybe<CreateCheckInPayload>
  createCheckIns?: Maybe<CheckInConnection>
  /** *Automatically generated.* */
  createCheckOut?: Maybe<CreateCheckOutPayload>
  createCheckOuts?: Maybe<CheckOutConnection>
  createCheckpoint?: Maybe<CreateDevicePayload>
  /** *Automatically generated.* */
  createCreditCard?: Maybe<CreateCreditCardPayload>
  createDatabaseReset?: Maybe<CreateDatabaseResetPayload>
  /** *Automatically generated.* */
  createDevice?: Maybe<CreateDevicePayload>
  /** *Automatically generated.* */
  createDeviceRepairOrder?: Maybe<CreateDeviceRepairOrderPayload>
  createDevices?: Maybe<Array<Maybe<CreateDevicePayload>>>
  /** *Automatically generated.* */
  createDoor?: Maybe<CreateDoorPayload>
  createEdgeDevice?: Maybe<CreateEdgeDevicePayload>
  /** *Automatically generated.* */
  createEmergencyCall?: Maybe<CreateEmergencyCallPayload>
  /** *Automatically generated.* */
  createEmergencyCalling?: Maybe<CreateEmergencyCallingPayload>
  /** *Automatically generated.* */
  createEvent?: Maybe<CreateEventPayload>
  createExternalAccount?: Maybe<CreateExternalAccountPayload>
  createExternalAccountApiKey?: Maybe<CreateExternalAccountApiKeyPayload>
  createExternalIncident?: Maybe<CreateExternalIncidentPayload>
  /** *Automatically generated.* */
  createFacility?: Maybe<CreateFacilityPayload>
  /** *Automatically generated.* */
  createFloor?: Maybe<CreateFloorPayload>
  /** *Automatically generated.* */
  createFloorPlan?: Maybe<CreateFloorPlanPayload>
  /** *Automatically generated.* */
  createGateway?: Maybe<CreateGatewayPayload>
  /** *Automatically generated.* */
  createGuardTour?: Maybe<CreateGuardTourPayload>
  /** *Automatically generated.* */
  createIncident?: Maybe<CreateIncidentPayload>
  /** *Automatically generated.* */
  createIncidentAction?: Maybe<CreateIncidentActionPayload>
  /** *Automatically generated.* */
  createIncidentDeviceMedia?: Maybe<CreateIncidentDeviceMediaPayload>
  /** *Automatically generated.* */
  createIncidentInformationSolicitation?: Maybe<CreateIncidentInformationSolicitationPayload>
  /** *Automatically generated.* */
  createIncidentNote?: Maybe<CreateIncidentNotePayload>
  createIncidentNoteMedia?: Maybe<CreateIncidentNoteMediaPayload>
  /** *Automatically generated.* */
  createIncidentOccurrence?: Maybe<CreateIncidentOccurrencePayload>
  /** *Automatically generated.* */
  createIncidentOffer?: Maybe<CreateIncidentOfferPayload>
  /** *Automatically generated.* */
  createIncidentOfferAcceptance?: Maybe<CreateIncidentOfferAcceptancePayload>
  /** *Automatically generated.* */
  createIncidentOfferToGuard?: Maybe<CreateIncidentOfferToGuardPayload>
  /** *Automatically generated.* */
  createIncidentOfferToGuardAcceptance?: Maybe<CreateIncidentOfferToGuardAcceptancePayload>
  /** *Automatically generated.* */
  createIncidentShare?: Maybe<CreateIncidentSharePayload>
  /** *Automatically generated.* */
  createInvoice?: Maybe<CreateInvoicePayload>
  createLlmIncidents?: Maybe<CreateLlmIncidentsPayload>
  createManualIncidentCategory?: Maybe<CreateManualIncidentCategoryPayload>
  /** *Automatically generated.* */
  createOrganization?: Maybe<CreateOrganizationPayload>
  /** *Automatically generated.* */
  createOrganizationSettings?: Maybe<CreateOrganizationSettingsPayload>
  createPacsSourceInstance?: Maybe<CreatePacsSourceInstancePayload>
  /** *Automatically generated.* */
  createPartialCreditCard?: Maybe<CreatePartialCreditCardPayload>
  /** *Automatically generated.* */
  createPermission?: Maybe<CreatePermissionPayload>
  /**
   * Creating a person of interest occurs in the context of a case.
   * Creation associates the person with the case.
   * Behind the scenes, this involves 2 operations:
   * 1. PersonOfInterest creation.
   * 2. Association of the newly created PersonOfInterest to the case.
   * Creation is a partially auditable operation, i.e: operation 2 will be audited.
   */
  createPersonOfInterest?: Maybe<CreatePersonOfInterestPayload>
  createQuickReport?: Maybe<CreateQuickReportPayload>
  /** *Automatically generated.* */
  createRole?: Maybe<CreateRolePayload>
  /** *Automatically generated.* */
  createRule?: Maybe<CreateRulePayload>
  createSavedFilter?: Maybe<CreateSavedFilterPayload>
  createStandardOperatingProcedure?: Maybe<CreateStandardOperatingProcedurePayload>
  /** *Automatically generated.* */
  createSubscriberMessage?: Maybe<CreateSubscriberMessagePayload>
  /** *Automatically generated.* */
  createTailgateConn?: Maybe<CreateTailgateConnPayload>
  /** *Automatically generated.* */
  createTechnician?: Maybe<CreateTechnicianPayload>
  /** *Automatically generated.* */
  createTourPlan?: Maybe<CreateTourPlanPayload>
  /** *Automatically generated.* */
  createUser?: Maybe<CreateUserPayload>
  /** *Automatically generated.* */
  deleteAcknowledgement?: Maybe<DeleteAcknowledgementPayload>
  deleteAlarm?: Maybe<DeleteAlarmPayload>
  /** *Automatically generated.* */
  deleteAlert?: Maybe<DeleteAlertPayload>
  /** *Automatically generated.* */
  deleteAudioClip?: Maybe<DeleteAudioClipPayload>
  /** *Automatically generated.* */
  deleteAudioMessage?: Maybe<DeleteAudioMessagePayload>
  /** *Automatically generated.* */
  deleteCamera?: Maybe<DeleteCameraPayload>
  /** *Automatically generated.* */
  deleteCameraLiveFeedDetail?: Maybe<DeleteCameraLiveFeedDetailPayload>
  /** *Automatically generated.* */
  deleteCharge?: Maybe<DeleteChargePayload>
  /** *Automatically generated.* */
  deleteCheckIn?: Maybe<DeleteCheckInPayload>
  /** *Automatically generated.* */
  deleteCheckOut?: Maybe<DeleteCheckOutPayload>
  /** *Automatically generated.* */
  deleteCreditCard?: Maybe<DeleteCreditCardPayload>
  /** *Automatically generated.* */
  deleteDevice?: Maybe<DeleteDevicePayload>
  /** *Automatically generated.* */
  deleteDeviceRepairOrder?: Maybe<DeleteDeviceRepairOrderPayload>
  /** *Automatically generated.* */
  deleteDoor?: Maybe<DeleteDoorPayload>
  deleteEdgeDevice?: Maybe<DeleteEdgeDevicePayload>
  /** *Automatically generated.* */
  deleteEmergencyCall?: Maybe<DeleteEmergencyCallPayload>
  /** *Automatically generated.* */
  deleteEmergencyCalling?: Maybe<DeleteEmergencyCallingPayload>
  /** *Automatically generated.* */
  deleteEvent?: Maybe<DeleteEventPayload>
  deleteExternalAccount?: Maybe<DeleteExternalAccountPayload>
  deleteExternalAccountApiKey?: Maybe<DeleteExternalAccountApiKeyPayload>
  /** *Automatically generated.* */
  deleteFacility?: Maybe<DeleteFacilityPayload>
  /** *Automatically generated.* */
  deleteFloor?: Maybe<DeleteFloorPayload>
  /** *Automatically generated.* */
  deleteFloorPlan?: Maybe<DeleteFloorPlanPayload>
  /** *Automatically generated.* */
  deleteGateway?: Maybe<DeleteGatewayPayload>
  /** *Automatically generated.* */
  deleteGuardTour?: Maybe<DeleteGuardTourPayload>
  /** *Automatically generated.* */
  deleteIncident?: Maybe<DeleteIncidentPayload>
  /** *Automatically generated.* */
  deleteIncidentAction?: Maybe<DeleteIncidentActionPayload>
  /** *Automatically generated.* */
  deleteIncidentDeviceMedia?: Maybe<DeleteIncidentDeviceMediaPayload>
  /** *Automatically generated.* */
  deleteIncidentInformationSolicitation?: Maybe<DeleteIncidentInformationSolicitationPayload>
  /** *Automatically generated.* */
  deleteIncidentNote?: Maybe<DeleteIncidentNotePayload>
  /** *Automatically generated.* */
  deleteIncidentOccurrence?: Maybe<DeleteIncidentOccurrencePayload>
  /** *Automatically generated.* */
  deleteIncidentOffer?: Maybe<DeleteIncidentOfferPayload>
  /** *Automatically generated.* */
  deleteIncidentOfferAcceptance?: Maybe<DeleteIncidentOfferAcceptancePayload>
  /** *Automatically generated.* */
  deleteIncidentOfferToGuard?: Maybe<DeleteIncidentOfferToGuardPayload>
  /** *Automatically generated.* */
  deleteIncidentOfferToGuardAcceptance?: Maybe<DeleteIncidentOfferToGuardAcceptancePayload>
  /** *Automatically generated.* */
  deleteIncidentShare?: Maybe<DeleteIncidentSharePayload>
  /** *Automatically generated.* */
  deleteInvoice?: Maybe<DeleteInvoicePayload>
  deleteManualIncidentCategory?: Maybe<DeleteManualIncidentCategoryPayload>
  /** *Automatically generated.* */
  deleteOrganization?: Maybe<DeleteOrganizationPayload>
  /** *Automatically generated.* */
  deleteOrganizationSettings?: Maybe<DeleteOrganizationSettingsPayload>
  /** *Automatically generated.* */
  deletePartialCreditCard?: Maybe<DeletePartialCreditCardPayload>
  /** *Automatically generated.* */
  deletePermission?: Maybe<DeletePermissionPayload>
  /** *Automatically generated.* */
  deleteRole?: Maybe<DeleteRolePayload>
  deleteSavedFilter?: Maybe<DeleteSavedFilterPayload>
  /** *Automatically generated.* */
  deleteStandardOperatingProcedure?: Maybe<DeleteStandardOperatingProcedurePayload>
  /** *Automatically generated.* */
  deleteSubscriberMessage?: Maybe<DeleteSubscriberMessagePayload>
  /** *Automatically generated.* */
  deleteTailgateConn?: Maybe<DeleteTailgateConnPayload>
  deleteTailgateGrid?: Maybe<DeleteTailgateGridPayload>
  /** *Automatically generated.* */
  deleteTechnician?: Maybe<DeleteTechnicianPayload>
  /** *Automatically generated.* */
  deleteTourPlan?: Maybe<DeleteTourPlanPayload>
  /** *Automatically generated.* */
  deleteUser?: Maybe<DeleteUserPayload>
  disassociateMultipleIncidentsFromSingleCase?: Maybe<UpdateCasePayload>
  disassociatePersonOfInterestFromCase?: Maybe<UpdateCasePayload>
  /**
   * Return information for embedding QuickSight content
   * (a dashboard)
   * in a web page.
   */
  embedQuickSightContent?: Maybe<EmbedQuickSightContentPayload>
  enableManualIncidentCategoriesForQuickReport?: Maybe<ManualIncidentCategoryConnection>
  generateFileUploadUrl?: Maybe<GenerateFileUploadUrlPayload>
  logGuardTourCheckpoint?: Maybe<UpdateGuardTourPayload>
  modifyTailgateConn?: Maybe<ModifyTailgateConnPayload>
  refreshQuickReportCode?: Maybe<RefreshQuickReportCodePayload>
  registerPushNotificationQuery?: Maybe<RegisterPushNotificationQueryPayload>
  restartEdgeDevice?: Maybe<RestartEdgeDevicePayload>
  sendIncidentToOntic?: Maybe<UpdateIncidentPayload>
  setUserPhoto?: Maybe<SetUserPhotoPayload>
  submitGuardTour?: Maybe<UpdateGuardTourPayload>
  /** *Automatically generated.* */
  updateAcknowledgement?: Maybe<UpdateAcknowledgementPayload>
  updateAiOperatorSettings?: Maybe<UpdateAiOperatorSettingsPayload>
  updateAlarm?: Maybe<UpdateAlarmPayload>
  /** *Automatically generated.* */
  updateAlert?: Maybe<UpdateAlertPayload>
  /** *Automatically generated.* */
  updateAudioClip?: Maybe<UpdateAudioClipPayload>
  /** *Automatically generated.* */
  updateAudioMessage?: Maybe<UpdateAudioMessagePayload>
  /** *Automatically generated.* */
  updateCamera?: Maybe<UpdateCameraPayload>
  updateCase?: Maybe<UpdateCasePayload>
  updateCaseOwner?: Maybe<UpdateCasePayload>
  updateCaseStatus?: Maybe<UpdateCasePayload>
  /** *Automatically generated.* */
  updateCharge?: Maybe<UpdateChargePayload>
  /** *Automatically generated.* */
  updateCheckIn?: Maybe<UpdateCheckInPayload>
  /** *Automatically generated.* */
  updateCreditCard?: Maybe<UpdateCreditCardPayload>
  /** *Automatically generated.* */
  updateDevice?: Maybe<UpdateDevicePayload>
  /** *Automatically generated.* */
  updateDeviceRepairOrder?: Maybe<UpdateDeviceRepairOrderPayload>
  /** *Automatically generated.* */
  updateDoor?: Maybe<UpdateDoorPayload>
  updateEdgeDevice?: Maybe<UpdateEdgeDevicePayload>
  /** *Automatically generated.* */
  updateEmergencyCall?: Maybe<UpdateEmergencyCallPayload>
  /** *Automatically generated.* */
  updateEmergencyCalling?: Maybe<UpdateEmergencyCallingPayload>
  /** *Automatically generated.* */
  updateEvent?: Maybe<UpdateEventPayload>
  updateExternalAccount?: Maybe<UpdateExternalAccountPayload>
  updateExternalAccountApiKey?: Maybe<UpdateExternalAccountApiKeyPayload>
  /** *Automatically generated.* */
  updateFacility?: Maybe<UpdateFacilityPayload>
  /** *Automatically generated.* */
  updateFloor?: Maybe<UpdateFloorPayload>
  /** *Automatically generated.* */
  updateFloorPlan?: Maybe<UpdateFloorPlanPayload>
  /** *Automatically generated.* */
  updateGateway?: Maybe<UpdateGatewayPayload>
  /** *Automatically generated.* */
  updateGuardTour?: Maybe<UpdateGuardTourPayload>
  /** *Automatically generated.* */
  updateIncident?: Maybe<UpdateIncidentPayload>
  /** *Automatically generated.* */
  updateIncidentAction?: Maybe<UpdateIncidentActionPayload>
  /** *Automatically generated.* */
  updateIncidentDeviceMedia?: Maybe<UpdateIncidentDeviceMediaPayload>
  /** *Automatically generated.* */
  updateIncidentInformationSolicitation?: Maybe<UpdateIncidentInformationSolicitationPayload>
  /** *Automatically generated.* */
  updateIncidentNote?: Maybe<UpdateIncidentNotePayload>
  /** *Automatically generated.* */
  updateIncidentOccurrence?: Maybe<UpdateIncidentOccurrencePayload>
  /** *Automatically generated.* */
  updateIncidentOffer?: Maybe<UpdateIncidentOfferPayload>
  /** *Automatically generated.* */
  updateIncidentOfferToGuard?: Maybe<UpdateIncidentOfferToGuardPayload>
  /** *Automatically generated.* */
  updateIncidentShare?: Maybe<UpdateIncidentSharePayload>
  updateManualIncidentCategory?: Maybe<UpdateManualIncidentCategoryPayload>
  /** *Automatically generated.* */
  updateOrganization?: Maybe<UpdateOrganizationPayload>
  /** *Automatically generated.* */
  updateOrganizationSettings?: Maybe<UpdateOrganizationSettingsPayload>
  updatePacsSourceInstance?: Maybe<UpdatePacsSourceInstancePayload>
  /** *Automatically generated.* */
  updatePartialCreditCard?: Maybe<UpdatePartialCreditCardPayload>
  /** *Automatically generated.* */
  updatePermission?: Maybe<UpdatePermissionPayload>
  updatePersonOfInterest?: Maybe<UpdatePersonOfInterestPayload>
  updatePersonOfInterestRole?: Maybe<UpdateCasePayload>
  updateQuickReport?: Maybe<UpdateQuickReportPayload>
  /** *Automatically generated.* */
  updateRole?: Maybe<UpdateRolePayload>
  updateRule?: Maybe<UpdateRulePayload>
  updateSavedFilter?: Maybe<UpdateSavedFilterPayload>
  /** *Automatically generated.* */
  updateStandardOperatingProcedure?: Maybe<UpdateStandardOperatingProcedurePayload>
  /** *Automatically generated.* */
  updateSubscriberMessage?: Maybe<UpdateSubscriberMessagePayload>
  updateTailgateGrid?: Maybe<UpdateTailgateGridPayload>
  /** *Automatically generated.* */
  updateTechnician?: Maybe<UpdateTechnicianPayload>
  /** *Automatically generated.* */
  updateTourPlan?: Maybe<UpdateTourPlanPayload>
  /** *Automatically generated.* */
  updateUser?: Maybe<UpdateUserPayload>
}

export type MutationAppendEventVideoArgs = {
  input?: InputMaybe<AppendEventVideoInput>
}

export type MutationAppendIncidentVideoArgs = {
  input?: InputMaybe<AppendIncidentVideoInput>
}

export type MutationAssociateMultipleIncidentsToSingleCaseArgs = {
  input: AssociateIncidentsToCaseInput
}

export type MutationAssociatePersonOfInterestToCaseArgs = {
  input: AssociatePersonOfInterestInput
}

export type MutationAssociateSingleIncidentToMultipleCasesArgs = {
  input: AssociateIncidentToCasesInput
}

export type MutationChangePasswordArgs = {
  input?: InputMaybe<ChangePasswordInput>
}

export type MutationCreateAcknowledgementArgs = {
  input: CreateAcknowledgementInput
}

export type MutationCreateAlarmArgs = {
  input: CreateAlarmInput
}

export type MutationCreateAlertArgs = {
  input: CreateAlertInput
}

export type MutationCreateAudioClipArgs = {
  input: CreateAudioClipInput
}

export type MutationCreateAudioMessageArgs = {
  input: CreateAudioMessageInput
}

export type MutationCreateCaseArgs = {
  input: CreateCaseInput
}

export type MutationCreateCaseCommentArgs = {
  input?: InputMaybe<CreateCaseCommentInput>
}

export type MutationCreateChargeArgs = {
  input: CreateChargeInput
}

export type MutationCreateCheckInArgs = {
  input: CreateCheckInInput
}

export type MutationCreateCheckInsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<CheckInFilterInput>
  first?: InputMaybe<Scalars['Int']>
  input: CreateCheckInsInput
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<CheckInOrderInput>>>
}

export type MutationCreateCheckOutsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<CheckOutFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<InputMaybe<CheckOutOrderInput>>>
}

export type MutationCreateCheckpointArgs = {
  input: CreateDeviceInput
}

export type MutationCreateCreditCardArgs = {
  input: CreateCreditCardInput
}

export type MutationCreateDeviceArgs = {
  input: CreateDeviceInput
}

export type MutationCreateDeviceRepairOrderArgs = {
  input: CreateDeviceRepairOrderInput
}

export type MutationCreateDevicesArgs = {
  input: CreateDevicesInput
}

export type MutationCreateDoorArgs = {
  input: CreateDoorInput
}

export type MutationCreateEdgeDeviceArgs = {
  input: CreateEdgeDeviceInput
}

export type MutationCreateEmergencyCallArgs = {
  input: CreateEmergencyCallInput
}

export type MutationCreateEmergencyCallingArgs = {
  input: CreateEmergencyCallingInput
}

export type MutationCreateEventArgs = {
  input: CreateEventInput
}

export type MutationCreateExternalAccountArgs = {
  input?: InputMaybe<CreateExternalAccountInput>
}

export type MutationCreateExternalAccountApiKeyArgs = {
  input: CreateExternalAccountApiKeyInput
}

export type MutationCreateExternalIncidentArgs = {
  input?: InputMaybe<CreateExternalIncidentInput>
}

export type MutationCreateFacilityArgs = {
  input: CreateFacilityInput
}

export type MutationCreateFloorArgs = {
  input: CreateFloorInput
}

export type MutationCreateFloorPlanArgs = {
  input: CreateFloorPlanInput
}

export type MutationCreateGatewayArgs = {
  input: CreateGatewayInput
}

export type MutationCreateGuardTourArgs = {
  input: CreateGuardTourInput
}

export type MutationCreateIncidentArgs = {
  input: CreateIncidentInput
}

export type MutationCreateIncidentActionArgs = {
  input: CreateIncidentActionInput
}

export type MutationCreateIncidentDeviceMediaArgs = {
  input: CreateIncidentDeviceMediaInput
}

export type MutationCreateIncidentInformationSolicitationArgs = {
  input: CreateIncidentInformationSolicitationInput
}

export type MutationCreateIncidentNoteArgs = {
  input: CreateIncidentNoteInput
}

export type MutationCreateIncidentNoteMediaArgs = {
  input: CreateIncidentNoteMediaInput
}

export type MutationCreateIncidentOccurrenceArgs = {
  input: CreateIncidentOccurrenceInput
}

export type MutationCreateIncidentOfferArgs = {
  input: CreateIncidentOfferInput
}

export type MutationCreateIncidentOfferToGuardArgs = {
  input: CreateIncidentOfferToGuardInput
}

export type MutationCreateIncidentShareArgs = {
  input: CreateIncidentShareInput
}

export type MutationCreateLlmIncidentsArgs = {
  input: CreateLlmIncidentsInput
}

export type MutationCreateManualIncidentCategoryArgs = {
  input: CreateManualIncidentCategoryInput
}

export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput
}

export type MutationCreateOrganizationSettingsArgs = {
  input: CreateOrganizationSettingsInput
}

export type MutationCreatePacsSourceInstanceArgs = {
  input?: InputMaybe<CreatePacsSourceInstanceInput>
}

export type MutationCreatePartialCreditCardArgs = {
  input: CreatePartialCreditCardInput
}

export type MutationCreatePermissionArgs = {
  input: CreatePermissionInput
}

export type MutationCreatePersonOfInterestArgs = {
  input?: InputMaybe<CreatePersonOfInterestInput>
}

export type MutationCreateQuickReportArgs = {
  input: CreateQuickReportInput
}

export type MutationCreateRoleArgs = {
  input: CreateRoleInput
}

export type MutationCreateRuleArgs = {
  input: CreateRuleInput
}

export type MutationCreateSavedFilterArgs = {
  input: CreateSavedFilterInput
}

export type MutationCreateStandardOperatingProcedureArgs = {
  input: CreateStandardOperatingProcedureInput
}

export type MutationCreateSubscriberMessageArgs = {
  input: CreateSubscriberMessageInput
}

export type MutationCreateTailgateConnArgs = {
  input: CreateTailgateConnInput
}

export type MutationCreateTechnicianArgs = {
  input: CreateTechnicianInput
}

export type MutationCreateTourPlanArgs = {
  input: CreateTourPlanInput
}

export type MutationCreateUserArgs = {
  input: CreateUserInput
}

export type MutationDeleteAcknowledgementArgs = {
  input: DeleteAcknowledgementInput
}

export type MutationDeleteAlarmArgs = {
  input: DeleteAlarmInput
}

export type MutationDeleteAlertArgs = {
  input: DeleteAlertInput
}

export type MutationDeleteAudioClipArgs = {
  input: DeleteAudioClipInput
}

export type MutationDeleteAudioMessageArgs = {
  input: DeleteAudioMessageInput
}

export type MutationDeleteCameraArgs = {
  input: DeleteCameraInput
}

export type MutationDeleteCameraLiveFeedDetailArgs = {
  input: DeleteCameraLiveFeedDetailInput
}

export type MutationDeleteChargeArgs = {
  input: DeleteChargeInput
}

export type MutationDeleteCheckInArgs = {
  input: DeleteCheckInInput
}

export type MutationDeleteCheckOutArgs = {
  input: DeleteCheckOutInput
}

export type MutationDeleteCreditCardArgs = {
  input: DeleteCreditCardInput
}

export type MutationDeleteDeviceArgs = {
  input: DeleteDeviceInput
}

export type MutationDeleteDeviceRepairOrderArgs = {
  input: DeleteDeviceRepairOrderInput
}

export type MutationDeleteDoorArgs = {
  input: DeleteDoorInput
}

export type MutationDeleteEdgeDeviceArgs = {
  input: DeleteEdgeDeviceInput
}

export type MutationDeleteEmergencyCallArgs = {
  input: DeleteEmergencyCallInput
}

export type MutationDeleteEmergencyCallingArgs = {
  input: DeleteEmergencyCallingInput
}

export type MutationDeleteEventArgs = {
  input: DeleteEventInput
}

export type MutationDeleteExternalAccountArgs = {
  input: DeleteExternalAccountInput
}

export type MutationDeleteExternalAccountApiKeyArgs = {
  input: DeleteExternalAccountApiKeyInput
}

export type MutationDeleteFacilityArgs = {
  input: DeleteFacilityInput
}

export type MutationDeleteFloorArgs = {
  input: DeleteFloorInput
}

export type MutationDeleteFloorPlanArgs = {
  input: DeleteFloorPlanInput
}

export type MutationDeleteGatewayArgs = {
  input: DeleteGatewayInput
}

export type MutationDeleteGuardTourArgs = {
  input: DeleteGuardTourInput
}

export type MutationDeleteIncidentArgs = {
  input: DeleteIncidentInput
}

export type MutationDeleteIncidentActionArgs = {
  input: DeleteIncidentActionInput
}

export type MutationDeleteIncidentDeviceMediaArgs = {
  input: DeleteIncidentDeviceMediaInput
}

export type MutationDeleteIncidentInformationSolicitationArgs = {
  input: DeleteIncidentInformationSolicitationInput
}

export type MutationDeleteIncidentNoteArgs = {
  input: DeleteIncidentNoteInput
}

export type MutationDeleteIncidentOccurrenceArgs = {
  input: DeleteIncidentOccurrenceInput
}

export type MutationDeleteIncidentOfferArgs = {
  input: DeleteIncidentOfferInput
}

export type MutationDeleteIncidentOfferAcceptanceArgs = {
  input: DeleteIncidentOfferAcceptanceInput
}

export type MutationDeleteIncidentOfferToGuardArgs = {
  input: DeleteIncidentOfferToGuardInput
}

export type MutationDeleteIncidentOfferToGuardAcceptanceArgs = {
  input: DeleteIncidentOfferToGuardAcceptanceInput
}

export type MutationDeleteIncidentShareArgs = {
  input: DeleteIncidentShareInput
}

export type MutationDeleteInvoiceArgs = {
  input: DeleteInvoiceInput
}

export type MutationDeleteManualIncidentCategoryArgs = {
  input: DeleteManualIncidentCategoryInput
}

export type MutationDeleteOrganizationArgs = {
  input: DeleteOrganizationInput
}

export type MutationDeleteOrganizationSettingsArgs = {
  input: DeleteOrganizationSettingsInput
}

export type MutationDeletePartialCreditCardArgs = {
  input: DeletePartialCreditCardInput
}

export type MutationDeletePermissionArgs = {
  input: DeletePermissionInput
}

export type MutationDeleteRoleArgs = {
  input: DeleteRoleInput
}

export type MutationDeleteSavedFilterArgs = {
  input: DeleteSavedFilterInput
}

export type MutationDeleteStandardOperatingProcedureArgs = {
  input: DeleteStandardOperatingProcedureInput
}

export type MutationDeleteSubscriberMessageArgs = {
  input: DeleteSubscriberMessageInput
}

export type MutationDeleteTailgateConnArgs = {
  input: DeleteTailgateConnInput
}

export type MutationDeleteTailgateGridArgs = {
  input: DeleteTailgateGridInput
}

export type MutationDeleteTechnicianArgs = {
  input: DeleteTechnicianInput
}

export type MutationDeleteTourPlanArgs = {
  input: DeleteTourPlanInput
}

export type MutationDeleteUserArgs = {
  input: DeleteUserInput
}

export type MutationDisassociateMultipleIncidentsFromSingleCaseArgs = {
  input: DisassociateIncidentsFromCaseInput
}

export type MutationDisassociatePersonOfInterestFromCaseArgs = {
  input: DisassociatePersonOfInterestInput
}

export type MutationEmbedQuickSightContentArgs = {
  input?: InputMaybe<EmbedQuickSightContentInput>
}

export type MutationEnableManualIncidentCategoriesForQuickReportArgs = {
  input: EnableManualIncidentCategoriesForQuickReportInput
}

export type MutationGenerateFileUploadUrlArgs = {
  input?: InputMaybe<GenerateFileUploadUrlInput>
}

export type MutationLogGuardTourCheckpointArgs = {
  input: LogGuardTourCheckpointInput
}

export type MutationModifyTailgateConnArgs = {
  input: ModifyTailgateConnInput
}

export type MutationRegisterPushNotificationQueryArgs = {
  input: RegisterPushNotificationQueryInput
}

export type MutationRestartEdgeDeviceArgs = {
  input: RestartEdgeDeviceInput
}

export type MutationSendIncidentToOnticArgs = {
  id: Scalars['ID']
}

export type MutationSetUserPhotoArgs = {
  input?: InputMaybe<SetUserPhotoInput>
}

export type MutationSubmitGuardTourArgs = {
  id: Scalars['ID']
}

export type MutationUpdateAcknowledgementArgs = {
  input: UpdateAcknowledgementInput
}

export type MutationUpdateAiOperatorSettingsArgs = {
  input: UpdateAiOperatorSettingsInput
}

export type MutationUpdateAlarmArgs = {
  input: UpdateAlarmInput
}

export type MutationUpdateAlertArgs = {
  input: UpdateAlertInput
}

export type MutationUpdateAudioClipArgs = {
  input: UpdateAudioClipInput
}

export type MutationUpdateAudioMessageArgs = {
  input: UpdateAudioMessageInput
}

export type MutationUpdateCameraArgs = {
  input: UpdateCameraInput
}

export type MutationUpdateCaseArgs = {
  input: UpdateCaseInput
}

export type MutationUpdateCaseOwnerArgs = {
  input?: InputMaybe<UpdateCaseOwnerInput>
}

export type MutationUpdateCaseStatusArgs = {
  input: UpdateCaseStatusInput
}

export type MutationUpdateChargeArgs = {
  input: UpdateChargeInput
}

export type MutationUpdateCheckInArgs = {
  input: UpdateCheckInInput
}

export type MutationUpdateCreditCardArgs = {
  input: UpdateCreditCardInput
}

export type MutationUpdateDeviceArgs = {
  input: UpdateDeviceInput
}

export type MutationUpdateDeviceRepairOrderArgs = {
  input: UpdateDeviceRepairOrderInput
}

export type MutationUpdateDoorArgs = {
  input: UpdateDoorInput
}

export type MutationUpdateEdgeDeviceArgs = {
  input: UpdateEdgeDeviceInput
}

export type MutationUpdateEmergencyCallArgs = {
  input: UpdateEmergencyCallInput
}

export type MutationUpdateEmergencyCallingArgs = {
  input: UpdateEmergencyCallingInput
}

export type MutationUpdateEventArgs = {
  input: UpdateEventInput
}

export type MutationUpdateExternalAccountArgs = {
  input: UpdateExternalAccountInput
}

export type MutationUpdateExternalAccountApiKeyArgs = {
  input: UpdateExternalAccountApiKeyInput
}

export type MutationUpdateFacilityArgs = {
  input: UpdateFacilityInput
}

export type MutationUpdateFloorArgs = {
  input: UpdateFloorInput
}

export type MutationUpdateFloorPlanArgs = {
  input: UpdateFloorPlanInput
}

export type MutationUpdateGatewayArgs = {
  input: UpdateGatewayInput
}

export type MutationUpdateGuardTourArgs = {
  input: UpdateGuardTourInput
}

export type MutationUpdateIncidentArgs = {
  input: UpdateIncidentInput
}

export type MutationUpdateIncidentActionArgs = {
  input: UpdateIncidentActionInput
}

export type MutationUpdateIncidentDeviceMediaArgs = {
  input: UpdateIncidentDeviceMediaInput
}

export type MutationUpdateIncidentInformationSolicitationArgs = {
  input: UpdateIncidentInformationSolicitationInput
}

export type MutationUpdateIncidentNoteArgs = {
  input: UpdateIncidentNoteInput
}

export type MutationUpdateIncidentOccurrenceArgs = {
  input: UpdateIncidentOccurrenceInput
}

export type MutationUpdateIncidentOfferArgs = {
  input: UpdateIncidentOfferInput
}

export type MutationUpdateIncidentOfferToGuardArgs = {
  input: UpdateIncidentOfferToGuardInput
}

export type MutationUpdateIncidentShareArgs = {
  input: UpdateIncidentShareInput
}

export type MutationUpdateManualIncidentCategoryArgs = {
  input: UpdateManualIncidentCategoryInput
}

export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput
}

export type MutationUpdateOrganizationSettingsArgs = {
  input: UpdateOrganizationSettingsInput
}

export type MutationUpdatePacsSourceInstanceArgs = {
  input?: InputMaybe<UpdatePacsSourceInstanceInput>
}

export type MutationUpdatePartialCreditCardArgs = {
  input: UpdatePartialCreditCardInput
}

export type MutationUpdatePermissionArgs = {
  input: UpdatePermissionInput
}

export type MutationUpdatePersonOfInterestArgs = {
  input?: InputMaybe<UpdatePersonOfInterestInput>
}

export type MutationUpdatePersonOfInterestRoleArgs = {
  input?: InputMaybe<UpdatePersonOfInterestRoleInput>
}

export type MutationUpdateQuickReportArgs = {
  input: UpdateQuickReportInput
}

export type MutationUpdateRoleArgs = {
  input: UpdateRoleInput
}

export type MutationUpdateRuleArgs = {
  input: UpdateRuleInput
}

export type MutationUpdateSavedFilterArgs = {
  input: UpdateSavedFilterInput
}

export type MutationUpdateStandardOperatingProcedureArgs = {
  input: UpdateStandardOperatingProcedureInput
}

export type MutationUpdateSubscriberMessageArgs = {
  input: UpdateSubscriberMessageInput
}

export type MutationUpdateTailgateGridArgs = {
  input: UpdateTailgateGridInput
}

export type MutationUpdateTechnicianArgs = {
  input: UpdateTechnicianInput
}

export type MutationUpdateTourPlanArgs = {
  input: UpdateTourPlanInput
}

export type MutationUpdateUserArgs = {
  input: UpdateUserInput
}

export type AppendEventVideoInput = {
  eventExternalId: Scalars['ID']
  /**
   * The stored asset
   * must have been created as a `VIDEO_CLIP`
   * (see the `UploadedFileType` enum).
   */
  storedAssetId: Scalars['ID']
}

export type AppendEventVideoPayload = {
  __typename?: 'AppendEventVideoPayload'
  eventExternalId: Scalars['ID']
}

export type AppendIncidentVideoInput = {
  cameraExternalId?: InputMaybe<Scalars['String']>
  doorExternalId?: InputMaybe<Scalars['String']>
  eventExternalId: Scalars['ID']
  storedAssetId: Scalars['ID']
}

export type AppendIncidentVideoPayload = {
  __typename?: 'AppendIncidentVideoPayload'
  incidentExisted?: Maybe<Scalars['Boolean']>
}

export type AssociateIncidentsToCaseInput = {
  caseId: Scalars['ID']
  incidentIds: Array<Scalars['ID']>
}

export type UpdateCasePayload = {
  __typename?: 'UpdateCasePayload'
  case?: Maybe<Case>
}

export type AssociatePersonOfInterestInput = {
  caseId: Scalars['ID']
  caseRole: CaseRole
  customCaseRoleDescription?: InputMaybe<Scalars['String']>
  personOfInterestId: Scalars['ID']
}

export type AssociateIncidentToCasesInput = {
  caseIds: Array<Scalars['ID']>
  incidentId: Scalars['ID']
}

/** *Automatically generated.* */
export type UpdateIncidentPayload = {
  __typename?: 'UpdateIncidentPayload'
  incident?: Maybe<Incident>
}

export type ChangePasswordInput = {
  newPassword: Scalars['String']
  oldPassword: Scalars['String']
  /** Defaults to ID of authenticated user. */
  userId?: InputMaybe<Scalars['ID']>
}

export type ChangePasswordPayload = {
  __typename?: 'ChangePasswordPayload'
  /** The user whose password was changed. */
  user: User
}

/** *Automatically generated.* */
export type CreateAcknowledgementInput = {
  audioClipId?: InputMaybe<Scalars['ID']>
}

/** *Automatically generated.* */
export type CreateAcknowledgementPayload = {
  __typename?: 'CreateAcknowledgementPayload'
  acknowledgement?: Maybe<Acknowledgement>
}

export type CreateAlarmInput = {
  externalId?: InputMaybe<Scalars['String']>
}

export type CreateAlarmPayload = {
  __typename?: 'CreateAlarmPayload'
  alarm?: Maybe<Alarm>
}

/** *Automatically generated.* */
export type CreateAlertInput = {
  incidentId: Scalars['ID']
  priority?: InputMaybe<AlertPriority>
}

/** *Automatically generated.* */
export type CreateAlertPayload = {
  __typename?: 'CreateAlertPayload'
  alert?: Maybe<Alert>
}

/** *Automatically generated.* */
export type CreateAudioClipInput = {
  endTime: Scalars['String']
  externalId: Scalars['String']
  facilityId?: InputMaybe<Scalars['ID']>
  incidentId?: InputMaybe<Scalars['ID']>
  startTime: Scalars['String']
}

/** *Automatically generated.* */
export type CreateAudioClipPayload = {
  __typename?: 'CreateAudioClipPayload'
  audioClip?: Maybe<AudioClip>
}

/** *Automatically generated.* */
export type CreateAudioMessageInput = {
  audioClipId: Scalars['ID']
  facilityId?: InputMaybe<Scalars['ID']>
  incidentId?: InputMaybe<Scalars['ID']>
  incidentOwnerId?: InputMaybe<Scalars['ID']>
  target?: InputMaybe<MessageTargetInput>
}

/** *Automatically generated.* */
export type MessageTargetInput = {
  AND?: InputMaybe<Array<MessageTargetInput>>
  facilityId?: InputMaybe<Scalars['ID']>
  incidentId?: InputMaybe<Scalars['ID']>
  onShift?: InputMaybe<Scalars['Boolean']>
  OR?: InputMaybe<Array<MessageTargetInput>>
  recipientIds?: InputMaybe<Array<Scalars['ID']>>
}

/** *Automatically generated.* */
export type CreateAudioMessagePayload = {
  __typename?: 'CreateAudioMessagePayload'
  audioMessage?: Maybe<AudioMessage>
}

export type CreateCaseInput = {
  description: Scalars['String']
  facilityIds: Array<Scalars['ID']>
  name: Scalars['String']
}

export type CreateCasePayload = {
  __typename?: 'CreateCasePayload'
  case: Case
}

export type CreateCaseCommentInput = {
  caseId: Scalars['ID']
  message: Scalars['String']
}

export type CreateCaseCommentPayload = {
  __typename?: 'CreateCaseCommentPayload'
  caseComment: CaseComment
}

/** *Automatically generated.* */
export type CreateChargeInput = {
  amount?: InputMaybe<Scalars['Int']>
}

/** *Automatically generated.* */
export type CreateChargePayload = {
  __typename?: 'CreateChargePayload'
  charge?: Maybe<Charge>
}

/** *Automatically generated.* */
export type CreateCheckInInput = {
  deviceToken?: InputMaybe<Scalars['String']>
  facilityId: Scalars['ID']
  platform?: InputMaybe<Platform>
}

export enum Platform {
  Android = 'ANDROID',
  Ios = 'IOS',
}

/** *Automatically generated.* */
export type CreateCheckInPayload = {
  __typename?: 'CreateCheckInPayload'
  checkIn?: Maybe<CheckIn>
}

/**
 * Create an object of this type
 * when the user wants to check in to a facility.
 * (Note: you can only check in the authenticated
 * user, not any arbitrary user.)
 *
 * If you create one object of this type,
 * then another,
 * without any intervening creation of
 * a `CheckOut` object, then the second
 * check-in replaces the first.
 */
export type CheckIn = {
  __typename?: 'CheckIn'
  /** *Automatically generated.* */
  createdAt: Scalars['String']
  deviceToken?: Maybe<Scalars['String']>
  facility?: Maybe<Facility>
  facilityId: Scalars['ID']
  id: Scalars['ID']
  platform?: Maybe<Platform>
  /** *Automatically generated.* */
  updatedAt: Scalars['String']
  user?: Maybe<User>
  userId: Scalars['ID']
}

/** *Automatically generated.* */
export type CheckInFilterInput = {
  AND?: InputMaybe<Array<CheckInFilterInput>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  deviceToken?: InputMaybe<Scalars['String']>
  deviceTokenGt?: InputMaybe<Scalars['String']>
  deviceTokenGte?: InputMaybe<Scalars['String']>
  deviceTokenIn?: InputMaybe<Array<Scalars['String']>>
  deviceTokenLike?: InputMaybe<Scalars['String']>
  deviceTokenLt?: InputMaybe<Scalars['String']>
  deviceTokenLte?: InputMaybe<Scalars['String']>
  deviceTokenNot?: InputMaybe<Scalars['String']>
  deviceTokenNotIn?: InputMaybe<Array<Scalars['String']>>
  facilityId?: InputMaybe<Scalars['ID']>
  facilityIdIn?: InputMaybe<Array<Scalars['ID']>>
  facilityIdNot?: InputMaybe<Scalars['ID']>
  facilityIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  OR?: InputMaybe<Array<CheckInFilterInput>>
  platform?: InputMaybe<Platform>
  platformIn?: InputMaybe<Array<Platform>>
  platformNot?: InputMaybe<Platform>
  platformNotIn?: InputMaybe<Array<Platform>>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
  userId?: InputMaybe<Scalars['ID']>
  userIdIn?: InputMaybe<Array<Scalars['ID']>>
  userIdNot?: InputMaybe<Scalars['ID']>
  userIdNotIn?: InputMaybe<Array<Scalars['ID']>>
}

export type CreateCheckInsInput = {
  deviceToken?: InputMaybe<Scalars['String']>
  facilityIds: Array<Scalars['ID']>
  platform?: InputMaybe<Platform>
}

/** *Automatically generated.* */
export type CheckInOrderInput = {
  direction: OrderDirection
  field: CheckInOrderField
}

/** *Automatically generated.* */
export enum CheckInOrderField {
  CreatedAt = 'CREATED_AT',
  DeviceToken = 'DEVICE_TOKEN',
  FacilityId = 'FACILITY_ID',
  Id = 'ID',
  Platform = 'PLATFORM',
  UpdatedAt = 'UPDATED_AT',
  UserId = 'USER_ID',
}

/** *Automatically generated.* */
export type CheckInConnection = {
  __typename?: 'CheckInConnection'
  edges?: Maybe<Array<Maybe<CheckInEdge>>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** *Automatically generated.* */
export type CheckInEdge = {
  __typename?: 'CheckInEdge'
  cursor: Scalars['String']
  node?: Maybe<CheckIn>
}

/** *Automatically generated.* */
export type CreateCheckOutPayload = {
  __typename?: 'CreateCheckOutPayload'
  checkOut?: Maybe<CheckOut>
}

/**
 * Create an object of this type
 * when the user wants to check out of a facility.
 * (Note: a GraphQL client can check out only the authenticated
 * user, not any arbitrary user.)
 */
export type CheckOut = {
  __typename?: 'CheckOut'
  /** *Automatically generated.* */
  createdAt: Scalars['String']
  facility?: Maybe<Facility>
  facilityId: Scalars['ID']
  id: Scalars['ID']
  /** *Automatically generated.* */
  updatedAt: Scalars['String']
  user?: Maybe<User>
  userId: Scalars['ID']
}

/** *Automatically generated.* */
export type CheckOutFilterInput = {
  AND?: InputMaybe<Array<CheckOutFilterInput>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  facilityId?: InputMaybe<Scalars['ID']>
  facilityIdIn?: InputMaybe<Array<Scalars['ID']>>
  facilityIdNot?: InputMaybe<Scalars['ID']>
  facilityIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  OR?: InputMaybe<Array<CheckOutFilterInput>>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
  userId?: InputMaybe<Scalars['ID']>
  userIdIn?: InputMaybe<Array<Scalars['ID']>>
  userIdNot?: InputMaybe<Scalars['ID']>
  userIdNotIn?: InputMaybe<Array<Scalars['ID']>>
}

/** *Automatically generated.* */
export type CheckOutOrderInput = {
  direction: OrderDirection
  field: CheckOutOrderField
}

/** *Automatically generated.* */
export enum CheckOutOrderField {
  CreatedAt = 'CREATED_AT',
  FacilityId = 'FACILITY_ID',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT',
  UserId = 'USER_ID',
}

/** *Automatically generated.* */
export type CheckOutConnection = {
  __typename?: 'CheckOutConnection'
  edges?: Maybe<Array<Maybe<CheckOutEdge>>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** *Automatically generated.* */
export type CheckOutEdge = {
  __typename?: 'CheckOutEdge'
  cursor: Scalars['String']
  node?: Maybe<CheckOut>
}

/** *Automatically generated.* */
export type CreateDeviceInput = {
  bearing?: InputMaybe<Scalars['Float']>
  facilityId?: InputMaybe<Scalars['ID']>
  fieldOfView?: InputMaybe<Scalars['Float']>
  floorId?: InputMaybe<Scalars['ID']>
  floorPlanId?: InputMaybe<Scalars['ID']>
  gatewayId?: InputMaybe<Scalars['ID']>
  macAddress?: InputMaybe<Scalars['String']>
  manufacturerName?: InputMaybe<Scalars['String']>
  modelName?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  status?: InputMaybe<DeviceStatus>
  type?: InputMaybe<DeviceType>
  visibilityRange?: InputMaybe<Scalars['Float']>
  x?: InputMaybe<Scalars['Float']>
  y?: InputMaybe<Scalars['Float']>
}

/** *Automatically generated.* */
export type CreateDevicePayload = {
  __typename?: 'CreateDevicePayload'
  device?: Maybe<Device>
}

/** *Automatically generated.* */
export type CreateCreditCardInput = {
  address?: InputMaybe<Array<Scalars['String']>>
  expiryMonth?: InputMaybe<Scalars['Int']>
  expiryYear?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  number?: InputMaybe<Scalars['String']>
}

/** *Automatically generated.* */
export type CreateCreditCardPayload = {
  __typename?: 'CreateCreditCardPayload'
  creditCard?: Maybe<CreditCard>
}

export type CreditCard = {
  __typename?: 'CreditCard'
  address?: Maybe<Array<Scalars['String']>>
  /** *Automatically generated.* */
  createdAt: Scalars['String']
  expiryMonth?: Maybe<Scalars['Int']>
  expiryYear?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  number?: Maybe<Scalars['String']>
  /** *Automatically generated.* */
  updatedAt: Scalars['String']
}

export type CreateDatabaseResetPayload = {
  __typename?: 'CreateDatabaseResetPayload'
  createdAt?: Maybe<Scalars['String']>
  createdBy?: Maybe<Scalars['String']>
}

/** *Automatically generated.* */
export type CreateDeviceRepairOrderInput = {
  message: Scalars['String']
}

/** *Automatically generated.* */
export type CreateDeviceRepairOrderPayload = {
  __typename?: 'CreateDeviceRepairOrderPayload'
  deviceRepairOrder?: Maybe<DeviceRepairOrder>
}

export type CreateDevicesInput = {
  deviceType: DeviceType
  sheetExternalId: Scalars['String']
}

/** *Automatically generated.* */
export type CreateDoorInput = {
  externalId?: InputMaybe<Scalars['String']>
}

/** *Automatically generated.* */
export type CreateDoorPayload = {
  __typename?: 'CreateDoorPayload'
  door?: Maybe<Door>
}

export type CreateEdgeDeviceInput = {
  deviceIds?: InputMaybe<Array<Scalars['ID']>>
  name: Scalars['String']
}

export type CreateEdgeDevicePayload = {
  __typename?: 'CreateEdgeDevicePayload'
  edgeDevice?: Maybe<EdgeDevice>
}

/** *Automatically generated.* */
export type CreateEmergencyCallInput = {
  callEndTime?: InputMaybe<Scalars['String']>
  callStartTime: Scalars['String']
  externalCallId?: InputMaybe<Scalars['String']>
  incidentId: Scalars['ID']
}

/** *Automatically generated.* */
export type CreateEmergencyCallPayload = {
  __typename?: 'CreateEmergencyCallPayload'
  emergencyCall?: Maybe<EmergencyCall>
}

/** *Automatically generated.* */
export type CreateEmergencyCallingInput = {
  facilityId: Scalars['ID']
  outboundPhoneNumber: Scalars['String']
  outboundPhoneNumberSid: Scalars['String']
}

/** *Automatically generated.* */
export type CreateEmergencyCallingPayload = {
  __typename?: 'CreateEmergencyCallingPayload'
  emergencyCalling?: Maybe<EmergencyCalling>
}

/** *Automatically generated.* */
export type CreateEventInput = {
  details?: InputMaybe<Scalars['String']>
  deviceIds: Array<Scalars['ID']>
  eventExternalId: Scalars['ID']
  facilityId: Scalars['ID']
  floorId?: InputMaybe<Scalars['ID']>
  incidentId?: InputMaybe<Scalars['ID']>
  numTailGaters?: InputMaybe<Scalars['Int']>
  type: EventType
}

/** *Automatically generated.* */
export type CreateEventPayload = {
  __typename?: 'CreateEventPayload'
  event?: Maybe<Event>
}

export type CreateExternalAccountInput = {
  accountId: Scalars['String']
  accountName: Scalars['String']
  defaultAccount?: Scalars['Boolean']
  externalSystemType?: ExternalSystemType
}

export type CreateExternalAccountPayload = {
  __typename?: 'CreateExternalAccountPayload'
  externalAccount?: Maybe<ExternalAccount>
}

export type CreateExternalAccountApiKeyInput = {
  externalAccountId: Scalars['ID']
  permissionName: ApiKeyPermission
  secretName: Scalars['String']
}

export type CreateExternalAccountApiKeyPayload = {
  __typename?: 'CreateExternalAccountApiKeyPayload'
  externalAccountApiKey?: Maybe<ExternalAccountApiKey>
}

export type CreateExternalIncidentInput = {
  createExternalIncidentAdditionalDetailInput: CreateExternalIncidentAdditionalDetailInput
  createIncidentInput: CreateIncidentInput
  generateFileUploadUrlInputs: Array<GenerateFileUploadUrlInput>
}

export type CreateExternalIncidentAdditionalDetailInput = {
  additionalContactInformation?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  locationOfConcern?: InputMaybe<Scalars['String']>
  partiesInvolved?: InputMaybe<Scalars['String']>
  phoneNumber?: InputMaybe<Scalars['String']>
  timeReported: Scalars['String']
}

export type CreateIncidentInput = {
  address?: InputMaybe<Array<Scalars['String']>>
  concernStartTime?: InputMaybe<Scalars['String']>
  creatorId?: InputMaybe<Scalars['ID']>
  description?: InputMaybe<Scalars['String']>
  detectedAt?: InputMaybe<Scalars['String']>
  deviceIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  deviceTypes?: InputMaybe<Array<InputMaybe<DeviceType>>>
  eventExternalId?: InputMaybe<Scalars['String']>
  facilityId: Scalars['ID']
  facilityName?: InputMaybe<Scalars['String']>
  facilityType?: InputMaybe<FacilityType>
  floorId?: InputMaybe<Scalars['ID']>
  floorName?: InputMaybe<Scalars['String']>
  guardId?: InputMaybe<Scalars['ID']>
  hasAudioMessages?: InputMaybe<Scalars['Boolean']>
  imageIds?: InputMaybe<Array<Scalars['ID']>>
  informationSolicited?: InputMaybe<Scalars['Boolean']>
  latitude?: InputMaybe<Scalars['Float']>
  longitude?: InputMaybe<Scalars['Float']>
  manualIncidentCategoryId?: InputMaybe<Scalars['ID']>
  /** Matches a Rule.id */
  matchedRule?: InputMaybe<Scalars['ID']>
  /** Defaults to false */
  muted?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  offered?: InputMaybe<Scalars['Boolean']>
  ownerId?: InputMaybe<Scalars['ID']>
  priority?: InputMaybe<IncidentPriority>
  real?: InputMaybe<Scalars['Boolean']>
  sensor?: InputMaybe<DeviceType>
  source?: InputMaybe<IncidentSource>
  status?: InputMaybe<IncidentStatus>
  type?: InputMaybe<IncidentType>
}

export type GenerateFileUploadUrlInput = {
  fileType: UploadedFileType
  /**
   * [Mime type](https://en.wikipedia.org/wiki/Media_type).
   *
   * Example: `image/jpeg`.
   */
  mimeType: Scalars['String']
  size: Scalars['Int']
}

/**
 * This type exists to allow the server to impose
 * different file-size limits and rate limits, depending on
 * the type of file being uploaded.
 * As an example of rate limits:
 * the server could set a maximum rate of 2 `VOICE_CLIP`
 * uploads per user per second.
 */
export enum UploadedFileType {
  /** The file is used for bulk create of different entities based on provided data. */
  CsvFile = 'CSV_FILE',
  /**
   * The file is intended for the
   * `s3Key` field of the `FloorPlan` type.
   */
  FloorPlan = 'FLOOR_PLAN',
  /** Photo attached to a guard-generated incident. */
  IncidentImage = 'INCIDENT_IMAGE',
  /** Supporting media attached to an incident note */
  IncidentNoteMedia = 'INCIDENT_NOTE_MEDIA',
  /**
   * The file is intended for
   * `logo` of a quick report
   */
  QuickReportImage = 'QUICK_REPORT_IMAGE',
  /**
   * The file is intended for the
   * `url` field of the `StandardOperatingProcedure` type.
   */
  StandardOperatingProcedure = 'STANDARD_OPERATING_PROCEDURE',
  /**
   * The file is intended for the
   * `avatar` field of the `User` type.
   */
  UserAvatar = 'USER_AVATAR',
  /**
   * The file is intended for the
   * `photo` field of the `User` type.
   */
  UserPhoto = 'USER_PHOTO',
  /**
   * The file contains video of an incident, or of a non-tailgating event,
   * or of some other event that has associated video.
   */
  VideoClip = 'VIDEO_CLIP',
  /**
   * The file is intended for the
   * `url` field of the `AudioClip` type.
   */
  VoiceClip = 'VOICE_CLIP',
}

export type CreateExternalIncidentPayload = {
  __typename?: 'CreateExternalIncidentPayload'
  fileUploadUrls: Array<GenerateFileUploadUrlPayload>
  incidentId: Scalars['ID']
}

export type GenerateFileUploadUrlPayload = {
  __typename?: 'GenerateFileUploadUrlPayload'
  /** Maximum length: 80 characters. */
  fileId?: Maybe<Scalars['ID']>
  url?: Maybe<Scalars['String']>
}

/** *Automatically generated.* */
export type CreateFacilityInput = {
  address?: InputMaybe<Array<Scalars['String']>>
  defaultFloorId?: InputMaybe<Scalars['ID']>
  emergencyNumber?: InputMaybe<Scalars['String']>
  latitude?: InputMaybe<Scalars['Float']>
  longitude?: InputMaybe<Scalars['Float']>
  name: Scalars['String']
  shortName?: InputMaybe<Scalars['String']>
  timeZone?: InputMaybe<Scalars['String']>
  type?: InputMaybe<FacilityType>
}

/** *Automatically generated.* */
export type CreateFacilityPayload = {
  __typename?: 'CreateFacilityPayload'
  facility?: Maybe<Facility>
}

/** *Automatically generated.* */
export type CreateFloorInput = {
  facilityId: Scalars['ID']
  floorPlanId?: InputMaybe<Scalars['ID']>
  name: Scalars['String']
}

/** *Automatically generated.* */
export type CreateFloorPayload = {
  __typename?: 'CreateFloorPayload'
  floor?: Maybe<Floor>
}

/** *Automatically generated.* */
export type CreateFloorPlanInput = {
  floorId: Scalars['ID']
  name: Scalars['String']
  s3Key?: InputMaybe<Scalars['String']>
}

/** *Automatically generated.* */
export type CreateFloorPlanPayload = {
  __typename?: 'CreateFloorPlanPayload'
  floorPlan?: Maybe<FloorPlan>
}

/** *Automatically generated.* */
export type CreateGatewayInput = {
  facilityIds?: InputMaybe<Array<Scalars['ID']>>
  floorIds?: InputMaybe<Array<Scalars['ID']>>
  physical: Scalars['Boolean']
}

/** *Automatically generated.* */
export type CreateGatewayPayload = {
  __typename?: 'CreateGatewayPayload'
  gateway?: Maybe<Gateway>
}

/** *Automatically generated.* */
export type CreateGuardTourInput = {
  tourPlanId: Scalars['ID']
}

/** *Automatically generated.* */
export type CreateGuardTourPayload = {
  __typename?: 'CreateGuardTourPayload'
  guardTour?: Maybe<GuardTour>
}

/** *Automatically generated.* */
export type CreateIncidentPayload = {
  __typename?: 'CreateIncidentPayload'
  incident?: Maybe<Incident>
}

/** *Automatically generated.* */
export type CreateIncidentActionInput = {
  audioClipId?: InputMaybe<Scalars['ID']>
  dispatchedGuardIds?: Array<Scalars['ID']>
  incidentId: Scalars['ID']
  message?: InputMaybe<Scalars['String']>
  technicianId?: InputMaybe<Scalars['ID']>
  type: IncidentActionType
}

/** *Automatically generated.* */
export type CreateIncidentActionPayload = {
  __typename?: 'CreateIncidentActionPayload'
  incidentAction?: Maybe<IncidentAction>
}

/** *Automatically generated.* */
export type CreateIncidentDeviceMediaInput = {
  deviceId: Scalars['ID']
  incidentId: Scalars['ID']
}

/** *Automatically generated.* */
export type CreateIncidentDeviceMediaPayload = {
  __typename?: 'CreateIncidentDeviceMediaPayload'
  incidentDeviceMedia?: Maybe<IncidentDeviceMedia>
}

/** *Automatically generated.* */
export type CreateIncidentInformationSolicitationInput = {
  incidentId: Scalars['ID']
  message?: InputMaybe<Scalars['String']>
  recipientIds: Array<Scalars['ID']>
}

/** *Automatically generated.* */
export type CreateIncidentInformationSolicitationPayload = {
  __typename?: 'CreateIncidentInformationSolicitationPayload'
  incidentInformationSolicitation?: Maybe<IncidentInformationSolicitation>
}

export type IncidentInformationSolicitation = {
  __typename?: 'IncidentInformationSolicitation'
  /** *Automatically generated.* */
  createdAt: Scalars['String']
  creator?: Maybe<User>
  creatorId: Scalars['ID']
  id: Scalars['ID']
  incidentId: Scalars['ID']
  message?: Maybe<Scalars['String']>
  recipientIds: Array<Scalars['ID']>
  /** *Automatically generated.* */
  updatedAt: Scalars['String']
}

/** *Automatically generated.* */
export type CreateIncidentNoteInput = {
  incidentId: Scalars['ID']
  message: Scalars['String']
}

/** *Automatically generated.* */
export type CreateIncidentNotePayload = {
  __typename?: 'CreateIncidentNotePayload'
  incidentNote?: Maybe<IncidentNote>
}

export type CreateIncidentNoteMediaInput = {
  incidentNoteId: Scalars['ID']
  media: Array<IncidentNoteMediaInput>
}

export type IncidentNoteMediaInput = {
  name: Scalars['String']
  storedAssetId: Scalars['ID']
}

export type CreateIncidentNoteMediaPayload = {
  __typename?: 'CreateIncidentNoteMediaPayload'
  incidentNoteId: Scalars['ID']
  media?: Maybe<Array<Maybe<IncidentNoteMedia>>>
}

/** *Automatically generated.* */
export type CreateIncidentOccurrenceInput = {
  createdAt: Scalars['String']
}

/** *Automatically generated.* */
export type CreateIncidentOccurrencePayload = {
  __typename?: 'CreateIncidentOccurrencePayload'
  incidentOccurrence?: Maybe<IncidentOccurrence>
}

/** *Automatically generated.* */
export type CreateIncidentOfferInput = {
  incidentId: Scalars['ID']
  message?: InputMaybe<Scalars['String']>
  recipientIds: Array<Scalars['ID']>
}

/** *Automatically generated.* */
export type CreateIncidentOfferPayload = {
  __typename?: 'CreateIncidentOfferPayload'
  incidentOffer?: Maybe<IncidentOffer>
}

/**
 * ["Sharing"](https://hivewatch.atlassian.net/wiki/spaces/EN/pages/166002738/Glossary#Share-an-Incident-with-an-Operator)
 * of an incident by one Operator with another.
 */
export type IncidentOffer = IncidentOccurrence &
  SubscriberMessage & {
    __typename?: 'IncidentOffer'
    /** *Automatically generated.* */
    createdAt: Scalars['String']
    creator?: Maybe<User>
    creatorId: Scalars['ID']
    id: Scalars['ID']
    incident?: Maybe<Incident>
    incidentId: Scalars['ID']
    message?: Maybe<Scalars['String']>
    recipientIds: Array<Scalars['ID']>
    /**
     * There is
     * [exactly 1 recipient](https://hivewatch.atlassian.net/wiki/spaces/EN/pages/718241793/Fragments+of+specification#%E2%80%9CSharing%E2%80%9D-has-1-recipient).
     *
     * *Despite that fact, we use a list type,
     * in order to future-proof the API.*
     */
    recipients?: Maybe<Array<Maybe<User>>>
    /** *Automatically generated.* */
    updatedAt: Scalars['String']
  }

/** *Automatically generated.* */
export type CreateIncidentOfferAcceptancePayload = {
  __typename?: 'CreateIncidentOfferAcceptancePayload'
  incidentOfferAcceptance?: Maybe<IncidentOfferAcceptance>
}

/**
 * Acceptance of an offer that was
 * made with the `IncidentOffer` type.
 */
export type IncidentOfferAcceptance = SubscriberMessage & {
  __typename?: 'IncidentOfferAcceptance'
  /** *Automatically generated.* */
  createdAt: Scalars['String']
  id: Scalars['ID']
  incidentOffer?: Maybe<IncidentOffer>
  /** *Automatically generated.* */
  updatedAt: Scalars['String']
}

/** *Automatically generated.* */
export type CreateIncidentOfferToGuardInput = {
  audioMessageId?: InputMaybe<Scalars['ID']>
  incidentId: Scalars['ID']
}

/** *Automatically generated.* */
export type CreateIncidentOfferToGuardPayload = {
  __typename?: 'CreateIncidentOfferToGuardPayload'
  incidentOfferToGuard?: Maybe<IncidentOfferToGuard>
}

/**
 * An offer of an incident to guards by an Operator.
 *
 * **Design note**
 *
 * There is no need to have a field for recipients, because the
 * recipients are determined largely by the facility.  To quote
 * ["Fragments of specification"](https://hivewatch.atlassian.net/wiki/spaces/EN/pages/718241793/Fragments+of+specification?pageVersion=18#Incidents%3A-notes-on-assigning-to-guards%2C-etc.):
 *
 * > When an incident happens, an Operator offers it to every guard
 * > at the facility, optionally including a voice message with the
 * > offer. (A guard should probably be left out of this if he has
 * > already been assigned to an incident.)
 */
export type IncidentOfferToGuard = IncidentOccurrence &
  SubscriberMessage & {
    __typename?: 'IncidentOfferToGuard'
    audioMessage?: Maybe<AudioMessage>
    audioMessageId?: Maybe<Scalars['ID']>
    /** *Automatically generated.* */
    createdAt: Scalars['String']
    creator?: Maybe<User>
    creatorId: Scalars['ID']
    id: Scalars['ID']
    incident?: Maybe<Incident>
    incidentId: Scalars['ID']
    /** *Automatically generated.* */
    updatedAt: Scalars['String']
  }

/** *Automatically generated.* */
export type CreateIncidentOfferToGuardAcceptancePayload = {
  __typename?: 'CreateIncidentOfferToGuardAcceptancePayload'
  incidentOfferToGuardAcceptance?: Maybe<IncidentOfferToGuardAcceptance>
}

/**
 * Acceptance of an offer that was
 * made with the `IncidentOfferToGuard` type.
 */
export type IncidentOfferToGuardAcceptance = SubscriberMessage & {
  __typename?: 'IncidentOfferToGuardAcceptance'
  /** *Automatically generated.* */
  createdAt: Scalars['String']
  id: Scalars['ID']
  incidentOfferToGuard?: Maybe<IncidentOfferToGuard>
  /** *Automatically generated.* */
  updatedAt: Scalars['String']
}

/** *Automatically generated.* */
export type CreateIncidentShareInput = {
  incidentId: Scalars['ID']
  target: MessageTargetInput
}

/** *Automatically generated.* */
export type CreateIncidentSharePayload = {
  __typename?: 'CreateIncidentSharePayload'
  incidentShare?: Maybe<IncidentShare>
}

/**
 * Create an object of this type to
 * ["Share an Incident with an Employee"](https://hivewatch.atlassian.net/wiki/spaces/EN/pages/166002738/Glossary#Share-an-Incident-with-an-Employee).
 */
export type IncidentShare = IncidentOccurrence &
  SubscriberMessage & {
    __typename?: 'IncidentShare'
    /** *Automatically generated.* */
    createdAt: Scalars['String']
    id: Scalars['ID']
    incidentId: Scalars['ID']
    target: MessageTarget
    /** *Automatically generated.* */
    updatedAt: Scalars['String']
  }

/** *Automatically generated.* */
export type CreateInvoicePayload = {
  __typename?: 'CreateInvoicePayload'
  invoice?: Maybe<Invoice>
}

export type Invoice = {
  __typename?: 'Invoice'
  /** *Automatically generated.* */
  createdAt: Scalars['String']
  id?: Maybe<Scalars['ID']>
  /** *Automatically generated.* */
  updatedAt: Scalars['String']
}

export type CreateLlmIncidentsInput = {
  delayToProcess?: Scalars['Int']
}

export type CreateLlmIncidentsPayload = {
  __typename?: 'CreateLlmIncidentsPayload'
  incidents?: Maybe<Array<Incident>>
}

export type CreateManualIncidentCategoryInput = {
  isQuickReportCategory?: Scalars['Boolean']
  name: Scalars['String']
}

export type CreateManualIncidentCategoryPayload = {
  __typename?: 'CreateManualIncidentCategoryPayload'
  manualIncidentCategory: ManualIncidentCategory
}

/** *Automatically generated.* */
export type CreateOrganizationInput = {
  name: Scalars['String']
}

/** *Automatically generated.* */
export type CreateOrganizationPayload = {
  __typename?: 'CreateOrganizationPayload'
  organization?: Maybe<Organization>
}

/** *Automatically generated.* */
export type CreateOrganizationSettingsInput = {
  cameraOfflineDurationUnit: DurationUnit
  emailReplyToAddress?: InputMaybe<Scalars['String']>
  emailReplyToName?: InputMaybe<Scalars['String']>
  emailSenderName?: InputMaybe<Scalars['String']>
  minimumCameraOfflineDuration: Scalars['Int']
}

/** *Automatically generated.* */
export type CreateOrganizationSettingsPayload = {
  __typename?: 'CreateOrganizationSettingsPayload'
  organizationSettings?: Maybe<OrganizationSettings>
}

export type CreatePacsSourceInstanceInput = {
  fields: Array<PacsSourceFieldInput>
  name: Scalars['String']
  pacsSourceId: Scalars['ID']
}

export type PacsSourceFieldInput = {
  pacsSourceFieldId: Scalars['ID']
  value: Scalars['String']
}

export type CreatePacsSourceInstancePayload = {
  __typename?: 'CreatePacsSourceInstancePayload'
  pacsSourceInstance?: Maybe<PacsSourceInstance>
}

/** *Automatically generated.* */
export type CreatePartialCreditCardInput = {
  address?: InputMaybe<Array<Scalars['String']>>
  endOfNumber?: InputMaybe<Scalars['String']>
  expiryMonth?: InputMaybe<Scalars['Int']>
  expiryYear?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
}

/** *Automatically generated.* */
export type CreatePartialCreditCardPayload = {
  __typename?: 'CreatePartialCreditCardPayload'
  partialCreditCard?: Maybe<PartialCreditCard>
}

/** *Automatically generated.* */
export type CreatePermissionInput = {
  name?: InputMaybe<Scalars['String']>
}

/** *Automatically generated.* */
export type CreatePermissionPayload = {
  __typename?: 'CreatePermissionPayload'
  permission?: Maybe<Permission>
}

export type CreatePersonOfInterestInput = {
  address?: InputMaybe<Array<Scalars['String']>>
  caseId: Scalars['ID']
  caseRole: CaseRole
  companyAffiliation: CompanyAffiliation
  customCaseRoleDescription?: InputMaybe<Scalars['String']>
  customCompanyAffiliationDescription?: InputMaybe<Scalars['String']>
  dateOfBirth?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  firstName: Scalars['String']
  lastName: Scalars['String']
  phoneNumber?: InputMaybe<Scalars['String']>
}

export type CreatePersonOfInterestPayload = {
  __typename?: 'CreatePersonOfInterestPayload'
  personOfInterest?: Maybe<PersonOfInterest>
}

export type CreateQuickReportInput = {
  formDescription: Scalars['String']
  formTitle: Scalars['String']
  logoAssetId: Scalars['ID']
}

export type CreateQuickReportPayload = {
  __typename?: 'CreateQuickReportPayload'
  quickReport?: Maybe<QuickReport>
}

/** *Automatically generated.* */
export type CreateRoleInput = {
  name?: InputMaybe<Scalars['String']>
}

/** *Automatically generated.* */
export type CreateRolePayload = {
  __typename?: 'CreateRolePayload'
  role?: Maybe<Role>
}

export type CreateRuleInput = {
  action: Action
  day?: InputMaybe<Scalars['String']>
  endDate?: InputMaybe<Scalars['String']>
  endTimeOfDay?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  repeatInterval?: InputMaybe<RepeatInterval>
  repeatIntervalNum?: InputMaybe<Scalars['Int']>
  ruleEventType: Array<RuleEventType>
  ruleLogics: CreateRuleLogicInput
  startTime: Scalars['String']
  /** Default == active */
  status?: InputMaybe<Scalars['Boolean']>
}

export type CreateRuleLogicInput = {
  children?: InputMaybe<Array<InputMaybe<CreateRuleLogicInput>>>
  value?: InputMaybe<Scalars['String']>
}

export type CreateRulePayload = {
  __typename?: 'CreateRulePayload'
  rule?: Maybe<Rule>
}

export type CreateSavedFilterInput = {
  context: FilterContext
  filterCriteria: FilterCriteriaInput
  isActive?: InputMaybe<Scalars['Boolean']>
  name: Scalars['String']
}

export type FilterCriteriaInput = {
  incident?: InputMaybe<IncidentFilterInput>
}

export type CreateSavedFilterPayload = {
  __typename?: 'CreateSavedFilterPayload'
  savedFilter?: Maybe<SavedFilter>
}

/** *Automatically generated.* */
export type CreateStandardOperatingProcedureInput = {
  externalId: Scalars['String']
  facilityIds?: InputMaybe<Array<Scalars['ID']>>
  name?: InputMaybe<Scalars['String']>
  type: SopType
}

/** *Automatically generated.* */
export type CreateStandardOperatingProcedurePayload = {
  __typename?: 'CreateStandardOperatingProcedurePayload'
  standardOperatingProcedure?: Maybe<StandardOperatingProcedure>
}

/** *Automatically generated.* */
export type CreateSubscriberMessageInput = {
  createdAt: Scalars['String']
}

/** *Automatically generated.* */
export type CreateSubscriberMessagePayload = {
  __typename?: 'CreateSubscriberMessagePayload'
  subscriberMessage?: Maybe<SubscriberMessage>
}

export type CreateTailgateConnInput = {
  cameraIds: Array<Scalars['ID']>
  /** The id of a device that can be connected to cameras, not necessarily a door. */
  doorId: Scalars['ID']
}

/** *Automatically generated.* */
export type CreateTailgateConnPayload = {
  __typename?: 'CreateTailgateConnPayload'
  tailgateConn?: Maybe<TailgateConn>
}

/** *Automatically generated.* */
export type CreateTechnicianInput = {
  deviceTypes?: InputMaybe<Array<InputMaybe<DeviceType>>>
  email?: InputMaybe<Scalars['String']>
  facilityIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  firstName?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
}

/** *Automatically generated.* */
export type CreateTechnicianPayload = {
  __typename?: 'CreateTechnicianPayload'
  technician?: Maybe<Technician>
}

/** *Automatically generated.* */
export type CreateTourPlanInput = {
  checkpointIds: Array<Scalars['ID']>
  facilityId: Scalars['ID']
  name: Scalars['String']
}

/** *Automatically generated.* */
export type CreateTourPlanPayload = {
  __typename?: 'CreateTourPlanPayload'
  tourPlan?: Maybe<TourPlan>
}

/** *Automatically generated.* */
export type CreateUserInput = {
  designatedFacilityId: Scalars['ID']
  email?: InputMaybe<Scalars['String']>
  facilityId?: InputMaybe<Scalars['ID']>
  firstName?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  onDuty?: InputMaybe<Scalars['Boolean']>
  roleName?: InputMaybe<Scalars['String']>
}

/** *Automatically generated.* */
export type CreateUserPayload = {
  __typename?: 'CreateUserPayload'
  user?: Maybe<User>
}

/** *Automatically generated.* */
export type DeleteAcknowledgementInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteAcknowledgementPayload = {
  __typename?: 'DeleteAcknowledgementPayload'
  id: Scalars['ID']
}

export type DeleteAlarmInput = {
  id: Scalars['ID']
}

export type DeleteAlarmPayload = {
  __typename?: 'DeleteAlarmPayload'
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteAlertInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteAlertPayload = {
  __typename?: 'DeleteAlertPayload'
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteAudioClipInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteAudioClipPayload = {
  __typename?: 'DeleteAudioClipPayload'
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteAudioMessageInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteAudioMessagePayload = {
  __typename?: 'DeleteAudioMessagePayload'
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteCameraInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteCameraPayload = {
  __typename?: 'DeleteCameraPayload'
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteCameraLiveFeedDetailInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteCameraLiveFeedDetailPayload = {
  __typename?: 'DeleteCameraLiveFeedDetailPayload'
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteChargeInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteChargePayload = {
  __typename?: 'DeleteChargePayload'
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteCheckInInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteCheckInPayload = {
  __typename?: 'DeleteCheckInPayload'
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteCheckOutInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteCheckOutPayload = {
  __typename?: 'DeleteCheckOutPayload'
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteCreditCardInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteCreditCardPayload = {
  __typename?: 'DeleteCreditCardPayload'
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteDeviceInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteDevicePayload = {
  __typename?: 'DeleteDevicePayload'
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteDeviceRepairOrderInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteDeviceRepairOrderPayload = {
  __typename?: 'DeleteDeviceRepairOrderPayload'
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteDoorInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteDoorPayload = {
  __typename?: 'DeleteDoorPayload'
  id: Scalars['ID']
}

export type DeleteEdgeDeviceInput = {
  id: Scalars['ID']
}

export type DeleteEdgeDevicePayload = {
  __typename?: 'DeleteEdgeDevicePayload'
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteEmergencyCallInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteEmergencyCallPayload = {
  __typename?: 'DeleteEmergencyCallPayload'
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteEmergencyCallingInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteEmergencyCallingPayload = {
  __typename?: 'DeleteEmergencyCallingPayload'
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteEventInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteEventPayload = {
  __typename?: 'DeleteEventPayload'
  id: Scalars['ID']
}

export type DeleteExternalAccountInput = {
  id: Scalars['ID']
}

export type DeleteExternalAccountPayload = {
  __typename?: 'DeleteExternalAccountPayload'
  id: Scalars['ID']
}

export type DeleteExternalAccountApiKeyInput = {
  id: Scalars['ID']
}

export type DeleteExternalAccountApiKeyPayload = {
  __typename?: 'DeleteExternalAccountApiKeyPayload'
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteFacilityInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteFacilityPayload = {
  __typename?: 'DeleteFacilityPayload'
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteFloorInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteFloorPayload = {
  __typename?: 'DeleteFloorPayload'
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteFloorPlanInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteFloorPlanPayload = {
  __typename?: 'DeleteFloorPlanPayload'
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteGatewayInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteGatewayPayload = {
  __typename?: 'DeleteGatewayPayload'
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteGuardTourInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteGuardTourPayload = {
  __typename?: 'DeleteGuardTourPayload'
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteIncidentInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteIncidentPayload = {
  __typename?: 'DeleteIncidentPayload'
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteIncidentActionInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteIncidentActionPayload = {
  __typename?: 'DeleteIncidentActionPayload'
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteIncidentDeviceMediaInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteIncidentDeviceMediaPayload = {
  __typename?: 'DeleteIncidentDeviceMediaPayload'
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteIncidentInformationSolicitationInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteIncidentInformationSolicitationPayload = {
  __typename?: 'DeleteIncidentInformationSolicitationPayload'
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteIncidentNoteInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteIncidentNotePayload = {
  __typename?: 'DeleteIncidentNotePayload'
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteIncidentOccurrenceInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteIncidentOccurrencePayload = {
  __typename?: 'DeleteIncidentOccurrencePayload'
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteIncidentOfferInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteIncidentOfferPayload = {
  __typename?: 'DeleteIncidentOfferPayload'
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteIncidentOfferAcceptanceInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteIncidentOfferAcceptancePayload = {
  __typename?: 'DeleteIncidentOfferAcceptancePayload'
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteIncidentOfferToGuardInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteIncidentOfferToGuardPayload = {
  __typename?: 'DeleteIncidentOfferToGuardPayload'
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteIncidentOfferToGuardAcceptanceInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteIncidentOfferToGuardAcceptancePayload = {
  __typename?: 'DeleteIncidentOfferToGuardAcceptancePayload'
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteIncidentShareInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteIncidentSharePayload = {
  __typename?: 'DeleteIncidentSharePayload'
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteInvoiceInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteInvoicePayload = {
  __typename?: 'DeleteInvoicePayload'
  id: Scalars['ID']
}

export type DeleteManualIncidentCategoryInput = {
  id: Scalars['ID']
}

export type DeleteManualIncidentCategoryPayload = {
  __typename?: 'DeleteManualIncidentCategoryPayload'
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteOrganizationInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteOrganizationPayload = {
  __typename?: 'DeleteOrganizationPayload'
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteOrganizationSettingsInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteOrganizationSettingsPayload = {
  __typename?: 'DeleteOrganizationSettingsPayload'
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeletePartialCreditCardInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeletePartialCreditCardPayload = {
  __typename?: 'DeletePartialCreditCardPayload'
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeletePermissionInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeletePermissionPayload = {
  __typename?: 'DeletePermissionPayload'
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteRoleInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteRolePayload = {
  __typename?: 'DeleteRolePayload'
  id: Scalars['ID']
}

export type DeleteSavedFilterInput = {
  id: Scalars['ID']
}

export type DeleteSavedFilterPayload = {
  __typename?: 'DeleteSavedFilterPayload'
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteStandardOperatingProcedureInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteStandardOperatingProcedurePayload = {
  __typename?: 'DeleteStandardOperatingProcedurePayload'
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteSubscriberMessageInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteSubscriberMessagePayload = {
  __typename?: 'DeleteSubscriberMessagePayload'
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteTailgateConnInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteTailgateConnPayload = {
  __typename?: 'DeleteTailgateConnPayload'
  id: Scalars['ID']
}

export type DeleteTailgateGridInput = {
  cameraId: Scalars['ID']
  doorId: Scalars['ID']
}

export type DeleteTailgateGridPayload = {
  __typename?: 'DeleteTailgateGridPayload'
  camera?: Maybe<Device>
  door?: Maybe<Device>
  inBox?: Maybe<Box>
  outBox?: Maybe<Box>
}

/** *Automatically generated.* */
export type DeleteTechnicianInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteTechnicianPayload = {
  __typename?: 'DeleteTechnicianPayload'
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteTourPlanInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteTourPlanPayload = {
  __typename?: 'DeleteTourPlanPayload'
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteUserInput = {
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type DeleteUserPayload = {
  __typename?: 'DeleteUserPayload'
  id: Scalars['ID']
}

export type DisassociateIncidentsFromCaseInput = {
  caseId: Scalars['ID']
  incidentIds: Array<Scalars['ID']>
}

export type DisassociatePersonOfInterestInput = {
  caseId: Scalars['ID']
  personOfInterestId: Scalars['ID']
}

export type EmbedQuickSightContentInput = {
  /**
   * Corresponds to `SessionLifetimeInMinutes`
   * as briefly described in the QuickSight [documentation].
   *
   * The server adjusts the client's value
   * so that it falls within a minimum and maximum,
   * which are configured on the server.
   * They are both 15,
   * but this might change in future.
   *
   * [documentation]: https://docs.aws.amazon.com/quicksight/latest/APIReference/API_GenerateEmbedUrlForAnonymousUser.html#API_GenerateEmbedUrlForAnonymousUser_RequestBody
   */
  sessionLifetimeInMinutes?: Scalars['Int']
}

export type EmbedQuickSightContentPayload = {
  __typename?: 'EmbedQuickSightContentPayload'
  embeddedQuickSightContent?: Maybe<EmbeddedQuickSightContent>
}

export type EmbeddedQuickSightContent = {
  __typename?: 'EmbeddedQuickSightContent'
  /**
   * A time in ISO8601 format.
   * The time is `sessionLifetimeInMinutes` minutes
   * after the time of generation of the URL.
   *
   * ## Examples
   *
   * * 2025-01-07T13:25:54.432Z
   */
  expiryTime: Scalars['String']
  /**
   * Guaranteed to have the same value as
   * `EmbedQuickSightContentInput.sessionLifetimeInMinutes`,
   * after its adjustment by the server.
   */
  sessionLifetimeInMinutes: Scalars['Int']
  /**
   * A URL suitable for embedding
   * in an iframe in a web page.
   * See the [AWS documentation]
   * for information on how to embed the URL.
   *
   * [AWS documentation]: https://docs.aws.amazon.com/quicksight/latest/user/embedded-analytics-dashboards-for-everyone.html#embedded-analytics-dashboards-with-anonymous-users-step-3
   *
   * The URL must be requested
   * within 5 minutes of its generation
   * or it won't work.
   * It can only be requested once.
   *
   * The web page containing the embedded QuickSight content
   * must have the same domain as the HiveWatch web app.
   */
  url: Scalars['String']
}

export type EnableManualIncidentCategoriesForQuickReportInput = {
  ids: Array<Scalars['ID']>
}

export type LogGuardTourCheckpointInput = {
  checkpointId: Scalars['ID']
  guardTourId: Scalars['ID']
}

/** *Automatically generated.* */
export type UpdateGuardTourPayload = {
  __typename?: 'UpdateGuardTourPayload'
  guardTour?: Maybe<GuardTour>
}

export type ModifyTailgateConnInput = {
  cameraIdsToAdd: Array<Scalars['ID']>
  cameraIdsToRemove: Array<Scalars['ID']>
  id: Scalars['ID']
}

export type ModifyTailgateConnPayload = {
  __typename?: 'ModifyTailgateConnPayload'
  tailgateConn?: Maybe<TailgateConn>
}

export type RefreshQuickReportCodePayload = {
  __typename?: 'RefreshQuickReportCodePayload'
  quickReportCode?: Maybe<QuickReportCode>
}

export type RegisterPushNotificationQueryInput = {
  query: Scalars['String']
}

export type RegisterPushNotificationQueryPayload = {
  __typename?: 'RegisterPushNotificationQueryPayload'
  success: Scalars['Boolean']
}

export type RestartEdgeDeviceInput = {
  componentName: Scalars['String']
  edgeDeviceName: Scalars['String']
}

export type RestartEdgeDevicePayload = {
  __typename?: 'RestartEdgeDevicePayload'
  isRestartRequested: Scalars['Boolean']
}

export type SetUserPhotoInput = {
  /**
   * Must refer to an image having certain dimensions.
   * The permitted dimensions depend on the value of
   * `type`, as follows.
   *
   * | `type` | Width | Height |
   * | --- | --- | --- |
   * | `AVATAR` | 48 | 48 |
   * | `PHOTO` | 300-500 | `[width, 1.75 * width]` |
   *
   * *In a web browser, it appears that an image's dimensions
   * can be obtained using
   * [FileReader](https://developer.mozilla.org/en-US/docs/Web/API/FileReader).
   * See [this answer](https://stackoverflow.com/a/47786555) on Stack Overflow.*
   */
  fileId: Scalars['ID']
  type: UserPhotoType
}

export enum UserPhotoType {
  Avatar = 'AVATAR',
  Photo = 'PHOTO',
}

export type SetUserPhotoPayload = {
  __typename?: 'SetUserPhotoPayload'
  success?: Maybe<Scalars['Boolean']>
}

/** *Automatically generated.* */
export type UpdateAcknowledgementInput = {
  audioClipId?: InputMaybe<Scalars['ID']>
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type UpdateAcknowledgementPayload = {
  __typename?: 'UpdateAcknowledgementPayload'
  acknowledgement?: Maybe<Acknowledgement>
}

export type UpdateAiOperatorSettingsInput = {
  enabled: Scalars['Boolean']
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type UpdateAiOperatorSettingsPayload = {
  __typename?: 'UpdateAiOperatorSettingsPayload'
  aiOperatorSettings?: Maybe<AiOperatorSettings>
}

export type UpdateAlarmInput = {
  externalId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type UpdateAlarmPayload = {
  __typename?: 'UpdateAlarmPayload'
  alarm?: Maybe<Alarm>
}

/** *Automatically generated.* */
export type UpdateAlertInput = {
  id: Scalars['ID']
  incidentId?: InputMaybe<Scalars['ID']>
  priority?: InputMaybe<AlertPriority>
}

/** *Automatically generated.* */
export type UpdateAlertPayload = {
  __typename?: 'UpdateAlertPayload'
  alert?: Maybe<Alert>
}

/** *Automatically generated.* */
export type UpdateAudioClipInput = {
  endTime?: InputMaybe<Scalars['String']>
  externalId?: InputMaybe<Scalars['String']>
  facilityId?: InputMaybe<Scalars['ID']>
  id: Scalars['ID']
  incidentId?: InputMaybe<Scalars['ID']>
  startTime?: InputMaybe<Scalars['String']>
}

/** *Automatically generated.* */
export type UpdateAudioClipPayload = {
  __typename?: 'UpdateAudioClipPayload'
  audioClip?: Maybe<AudioClip>
}

/** *Automatically generated.* */
export type UpdateAudioMessageInput = {
  audioClipId?: InputMaybe<Scalars['ID']>
  facilityId?: InputMaybe<Scalars['ID']>
  id: Scalars['ID']
  incidentId?: InputMaybe<Scalars['ID']>
  incidentOwnerId?: InputMaybe<Scalars['ID']>
  target?: InputMaybe<MessageTargetInput>
}

/** *Automatically generated.* */
export type UpdateAudioMessagePayload = {
  __typename?: 'UpdateAudioMessagePayload'
  audioMessage?: Maybe<AudioMessage>
}

/** *Automatically generated.* */
export type UpdateCameraInput = {
  externalAccountId?: InputMaybe<Scalars['ID']>
  externalId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  rtspIp?: InputMaybe<Scalars['String']>
  rtspPassword?: InputMaybe<Scalars['String']>
  rtspPath?: InputMaybe<Scalars['String']>
  rtspPort?: InputMaybe<Scalars['Int']>
  rtspUsername?: InputMaybe<Scalars['String']>
  streamingProtocol?: InputMaybe<StreamingProtocol>
  streamingResolutionHeight?: InputMaybe<Scalars['Int']>
  streamingResolutionWidth?: InputMaybe<Scalars['Int']>
  streamingType?: InputMaybe<StreamingType>
}

/** *Automatically generated.* */
export type UpdateCameraPayload = {
  __typename?: 'UpdateCameraPayload'
  camera?: Maybe<Camera>
}

export type UpdateCaseInput = {
  archived?: InputMaybe<Scalars['Boolean']>
  description?: InputMaybe<Scalars['String']>
  detailedReport?: InputMaybe<Scalars['String']>
  facilityIds?: InputMaybe<Array<Scalars['ID']>>
  id: Scalars['ID']
  name?: InputMaybe<Scalars['String']>
  status?: InputMaybe<CaseStatus>
}

export type UpdateCaseOwnerInput = {
  caseId: Scalars['ID']
  ownerId: Scalars['ID']
}

export type UpdateCaseStatusInput = {
  caseId: Scalars['ID']
  newStatus: CaseStatus
}

/** *Automatically generated.* */
export type UpdateChargeInput = {
  amount?: InputMaybe<Scalars['Int']>
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type UpdateChargePayload = {
  __typename?: 'UpdateChargePayload'
  charge?: Maybe<Charge>
}

/** *Automatically generated.* */
export type UpdateCheckInInput = {
  deviceToken?: InputMaybe<Scalars['String']>
  facilityId?: InputMaybe<Scalars['ID']>
  id: Scalars['ID']
  platform?: InputMaybe<Platform>
}

/** *Automatically generated.* */
export type UpdateCheckInPayload = {
  __typename?: 'UpdateCheckInPayload'
  checkIn?: Maybe<CheckIn>
}

/** *Automatically generated.* */
export type UpdateCreditCardInput = {
  address?: InputMaybe<Array<Scalars['String']>>
  expiryMonth?: InputMaybe<Scalars['Int']>
  expiryYear?: InputMaybe<Scalars['Int']>
  id: Scalars['ID']
  name?: InputMaybe<Scalars['String']>
  number?: InputMaybe<Scalars['String']>
}

/** *Automatically generated.* */
export type UpdateCreditCardPayload = {
  __typename?: 'UpdateCreditCardPayload'
  creditCard?: Maybe<CreditCard>
}

/** *Automatically generated.* */
export type UpdateDeviceInput = {
  bearing?: InputMaybe<Scalars['Float']>
  facilityId?: InputMaybe<Scalars['ID']>
  fieldOfView?: InputMaybe<Scalars['Float']>
  floorId?: InputMaybe<Scalars['ID']>
  floorPlanId?: InputMaybe<Scalars['ID']>
  gatewayId?: InputMaybe<Scalars['ID']>
  id: Scalars['ID']
  macAddress?: InputMaybe<Scalars['String']>
  manufacturerName?: InputMaybe<Scalars['String']>
  modelName?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  status?: InputMaybe<DeviceStatus>
  type?: InputMaybe<DeviceType>
  visibilityRange?: InputMaybe<Scalars['Float']>
  x?: InputMaybe<Scalars['Float']>
  y?: InputMaybe<Scalars['Float']>
}

/** *Automatically generated.* */
export type UpdateDevicePayload = {
  __typename?: 'UpdateDevicePayload'
  device?: Maybe<Device>
}

/** *Automatically generated.* */
export type UpdateDeviceRepairOrderInput = {
  id: Scalars['ID']
  message?: InputMaybe<Scalars['String']>
}

/** *Automatically generated.* */
export type UpdateDeviceRepairOrderPayload = {
  __typename?: 'UpdateDeviceRepairOrderPayload'
  deviceRepairOrder?: Maybe<DeviceRepairOrder>
}

/** *Automatically generated.* */
export type UpdateDoorInput = {
  externalId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type UpdateDoorPayload = {
  __typename?: 'UpdateDoorPayload'
  door?: Maybe<Door>
}

export type UpdateEdgeDeviceInput = {
  deviceIds?: InputMaybe<Array<Scalars['ID']>>
  id: Scalars['ID']
  name?: InputMaybe<Scalars['String']>
}

export type UpdateEdgeDevicePayload = {
  __typename?: 'UpdateEdgeDevicePayload'
  edgeDevice?: Maybe<EdgeDevice>
}

/** *Automatically generated.* */
export type UpdateEmergencyCallInput = {
  callEndTime?: InputMaybe<Scalars['String']>
  callStartTime?: InputMaybe<Scalars['String']>
  externalCallId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  incidentId?: InputMaybe<Scalars['ID']>
}

/** *Automatically generated.* */
export type UpdateEmergencyCallPayload = {
  __typename?: 'UpdateEmergencyCallPayload'
  emergencyCall?: Maybe<EmergencyCall>
}

/** *Automatically generated.* */
export type UpdateEmergencyCallingInput = {
  facilityId?: InputMaybe<Scalars['ID']>
  id: Scalars['ID']
  outboundPhoneNumber?: InputMaybe<Scalars['String']>
  outboundPhoneNumberSid?: InputMaybe<Scalars['String']>
}

/** *Automatically generated.* */
export type UpdateEmergencyCallingPayload = {
  __typename?: 'UpdateEmergencyCallingPayload'
  emergencyCalling?: Maybe<EmergencyCalling>
}

/** *Automatically generated.* */
export type UpdateEventInput = {
  deviceIds?: InputMaybe<Array<Scalars['ID']>>
  facilityId?: InputMaybe<Scalars['ID']>
  id: Scalars['ID']
  incidentId?: InputMaybe<Scalars['ID']>
  numTailGaters?: InputMaybe<Scalars['Int']>
  type?: InputMaybe<EventType>
}

/** *Automatically generated.* */
export type UpdateEventPayload = {
  __typename?: 'UpdateEventPayload'
  event?: Maybe<Event>
}

export type UpdateExternalAccountInput = {
  accountId?: InputMaybe<Scalars['String']>
  accountName?: InputMaybe<Scalars['String']>
  defaultAccount?: InputMaybe<Scalars['Boolean']>
  externalSystemType?: InputMaybe<ExternalSystemType>
  id: Scalars['ID']
}

export type UpdateExternalAccountPayload = {
  __typename?: 'UpdateExternalAccountPayload'
  externalAccount?: Maybe<ExternalAccount>
}

export type UpdateExternalAccountApiKeyInput = {
  externalAccountId?: InputMaybe<Scalars['ID']>
  id: Scalars['ID']
  permissionName?: InputMaybe<ApiKeyPermission>
  secretName?: InputMaybe<Scalars['String']>
}

export type UpdateExternalAccountApiKeyPayload = {
  __typename?: 'UpdateExternalAccountApiKeyPayload'
  externalAccountApiKey?: Maybe<ExternalAccountApiKey>
}

/** *Automatically generated.* */
export type UpdateFacilityInput = {
  address?: InputMaybe<Array<Scalars['String']>>
  defaultFloorId?: InputMaybe<Scalars['ID']>
  emergencyNumber?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  latitude?: InputMaybe<Scalars['Float']>
  longitude?: InputMaybe<Scalars['Float']>
  name?: InputMaybe<Scalars['String']>
  shortName?: InputMaybe<Scalars['String']>
  timeZone?: InputMaybe<Scalars['String']>
  type?: InputMaybe<FacilityType>
}

/** *Automatically generated.* */
export type UpdateFacilityPayload = {
  __typename?: 'UpdateFacilityPayload'
  facility?: Maybe<Facility>
}

export type UpdateFloorInput = {
  facilityId?: InputMaybe<Scalars['ID']>
  id: Scalars['ID']
  name?: InputMaybe<Scalars['String']>
}

/** *Automatically generated.* */
export type UpdateFloorPayload = {
  __typename?: 'UpdateFloorPayload'
  floor?: Maybe<Floor>
}

/** *Automatically generated.* */
export type UpdateFloorPlanInput = {
  floorId?: InputMaybe<Scalars['ID']>
  id: Scalars['ID']
  name?: InputMaybe<Scalars['String']>
  s3Key?: InputMaybe<Scalars['String']>
}

/** *Automatically generated.* */
export type UpdateFloorPlanPayload = {
  __typename?: 'UpdateFloorPlanPayload'
  floorPlan?: Maybe<FloorPlan>
}

/** *Automatically generated.* */
export type UpdateGatewayInput = {
  facilityIds?: InputMaybe<Array<Scalars['ID']>>
  floorIds?: InputMaybe<Array<Scalars['ID']>>
  id: Scalars['ID']
  physical?: InputMaybe<Scalars['Boolean']>
}

/** *Automatically generated.* */
export type UpdateGatewayPayload = {
  __typename?: 'UpdateGatewayPayload'
  gateway?: Maybe<Gateway>
}

/** *Automatically generated.* */
export type UpdateGuardTourInput = {
  id: Scalars['ID']
  tourPlanId?: InputMaybe<Scalars['ID']>
}

export type UpdateIncidentInput = {
  address?: InputMaybe<Array<Scalars['String']>>
  concernStartTime?: InputMaybe<Scalars['String']>
  creatorId?: InputMaybe<Scalars['ID']>
  description?: InputMaybe<Scalars['String']>
  deviceIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  deviceTypes?: InputMaybe<Array<InputMaybe<DeviceType>>>
  facilityId?: InputMaybe<Scalars['ID']>
  facilityName?: InputMaybe<Scalars['String']>
  facilityType?: InputMaybe<FacilityType>
  floorId?: InputMaybe<Scalars['ID']>
  floorName?: InputMaybe<Scalars['String']>
  guardId?: InputMaybe<Scalars['ID']>
  hasAudioMessages?: InputMaybe<Scalars['Boolean']>
  id: Scalars['ID']
  informationSolicited?: InputMaybe<Scalars['Boolean']>
  latitude?: InputMaybe<Scalars['Float']>
  longitude?: InputMaybe<Scalars['Float']>
  manualIncidentCategoryId?: InputMaybe<Scalars['ID']>
  matchedRule?: InputMaybe<Scalars['ID']>
  muted?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  offered?: InputMaybe<Scalars['Boolean']>
  ownerId?: InputMaybe<Scalars['ID']>
  priority?: InputMaybe<IncidentPriority>
  real?: InputMaybe<Scalars['Boolean']>
  sensor?: InputMaybe<DeviceType>
  source?: InputMaybe<IncidentSource>
  status?: InputMaybe<IncidentStatus>
  type?: InputMaybe<IncidentType>
}

/** *Automatically generated.* */
export type UpdateIncidentActionInput = {
  audioClipId?: InputMaybe<Scalars['ID']>
  id: Scalars['ID']
  incidentId?: InputMaybe<Scalars['ID']>
  message?: InputMaybe<Scalars['String']>
  technicianId?: InputMaybe<Scalars['ID']>
  type?: InputMaybe<IncidentActionType>
}

/** *Automatically generated.* */
export type UpdateIncidentActionPayload = {
  __typename?: 'UpdateIncidentActionPayload'
  incidentAction?: Maybe<IncidentAction>
}

/** *Automatically generated.* */
export type UpdateIncidentDeviceMediaInput = {
  deviceId?: InputMaybe<Scalars['ID']>
  id: Scalars['ID']
  incidentId?: InputMaybe<Scalars['ID']>
}

/** *Automatically generated.* */
export type UpdateIncidentDeviceMediaPayload = {
  __typename?: 'UpdateIncidentDeviceMediaPayload'
  incidentDeviceMedia?: Maybe<IncidentDeviceMedia>
}

/** *Automatically generated.* */
export type UpdateIncidentInformationSolicitationInput = {
  id: Scalars['ID']
  incidentId?: InputMaybe<Scalars['ID']>
  message?: InputMaybe<Scalars['String']>
  recipientIds?: InputMaybe<Array<Scalars['ID']>>
}

/** *Automatically generated.* */
export type UpdateIncidentInformationSolicitationPayload = {
  __typename?: 'UpdateIncidentInformationSolicitationPayload'
  incidentInformationSolicitation?: Maybe<IncidentInformationSolicitation>
}

/** *Automatically generated.* */
export type UpdateIncidentNoteInput = {
  id: Scalars['ID']
  incidentId?: InputMaybe<Scalars['ID']>
  message?: InputMaybe<Scalars['String']>
}

/** *Automatically generated.* */
export type UpdateIncidentNotePayload = {
  __typename?: 'UpdateIncidentNotePayload'
  incidentNote?: Maybe<IncidentNote>
}

/** *Automatically generated.* */
export type UpdateIncidentOccurrenceInput = {
  createdAt?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type UpdateIncidentOccurrencePayload = {
  __typename?: 'UpdateIncidentOccurrencePayload'
  incidentOccurrence?: Maybe<IncidentOccurrence>
}

/** *Automatically generated.* */
export type UpdateIncidentOfferInput = {
  id: Scalars['ID']
  incidentId?: InputMaybe<Scalars['ID']>
  message?: InputMaybe<Scalars['String']>
  recipientIds?: InputMaybe<Array<Scalars['ID']>>
}

/** *Automatically generated.* */
export type UpdateIncidentOfferPayload = {
  __typename?: 'UpdateIncidentOfferPayload'
  incidentOffer?: Maybe<IncidentOffer>
}

/** *Automatically generated.* */
export type UpdateIncidentOfferToGuardInput = {
  audioMessageId?: InputMaybe<Scalars['ID']>
  id: Scalars['ID']
  incidentId?: InputMaybe<Scalars['ID']>
}

/** *Automatically generated.* */
export type UpdateIncidentOfferToGuardPayload = {
  __typename?: 'UpdateIncidentOfferToGuardPayload'
  incidentOfferToGuard?: Maybe<IncidentOfferToGuard>
}

/** *Automatically generated.* */
export type UpdateIncidentShareInput = {
  incidentId?: InputMaybe<Scalars['ID']>
  target?: InputMaybe<MessageTargetInput>
}

/** *Automatically generated.* */
export type UpdateIncidentSharePayload = {
  __typename?: 'UpdateIncidentSharePayload'
  incidentShare?: Maybe<IncidentShare>
}

export type UpdateManualIncidentCategoryInput = {
  id: Scalars['ID']
  isQuickReportCategory?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
}

export type UpdateManualIncidentCategoryPayload = {
  __typename?: 'UpdateManualIncidentCategoryPayload'
  manualIncidentCategory: ManualIncidentCategory
}

/** *Automatically generated.* */
export type UpdateOrganizationInput = {
  id: Scalars['ID']
  name?: InputMaybe<Scalars['String']>
}

/** *Automatically generated.* */
export type UpdateOrganizationPayload = {
  __typename?: 'UpdateOrganizationPayload'
  organization?: Maybe<Organization>
}

/** *Automatically generated.* */
export type UpdateOrganizationSettingsInput = {
  cameraOfflineDurationUnit?: InputMaybe<DurationUnit>
  emailReplyToAddress?: InputMaybe<Scalars['String']>
  emailReplyToName?: InputMaybe<Scalars['String']>
  emailSenderName?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  minimumCameraOfflineDuration?: InputMaybe<Scalars['Int']>
}

/** *Automatically generated.* */
export type UpdateOrganizationSettingsPayload = {
  __typename?: 'UpdateOrganizationSettingsPayload'
  organizationSettings?: Maybe<OrganizationSettings>
}

export type UpdatePacsSourceInstanceInput = {
  fields?: InputMaybe<Array<PacsSourceFieldInstanceInput>>
  id: Scalars['ID']
  name?: InputMaybe<Scalars['String']>
}

export type PacsSourceFieldInstanceInput = {
  pacsSourceFieldInstanceId: Scalars['ID']
  value: Scalars['String']
}

export type UpdatePacsSourceInstancePayload = {
  __typename?: 'UpdatePacsSourceInstancePayload'
  pacsSourceInstance?: Maybe<PacsSourceInstance>
}

/** *Automatically generated.* */
export type UpdatePartialCreditCardInput = {
  address?: InputMaybe<Array<Scalars['String']>>
  endOfNumber?: InputMaybe<Scalars['String']>
  expiryMonth?: InputMaybe<Scalars['Int']>
  expiryYear?: InputMaybe<Scalars['Int']>
  id: Scalars['ID']
  name?: InputMaybe<Scalars['String']>
}

/** *Automatically generated.* */
export type UpdatePartialCreditCardPayload = {
  __typename?: 'UpdatePartialCreditCardPayload'
  partialCreditCard?: Maybe<PartialCreditCard>
}

/** *Automatically generated.* */
export type UpdatePermissionInput = {
  id: Scalars['ID']
  name?: InputMaybe<Scalars['String']>
}

/** *Automatically generated.* */
export type UpdatePermissionPayload = {
  __typename?: 'UpdatePermissionPayload'
  permission?: Maybe<Permission>
}

export type UpdatePersonOfInterestInput = {
  address?: InputMaybe<Array<Scalars['String']>>
  companyAffiliation?: InputMaybe<CompanyAffiliation>
  customCompanyAffiliationDescription?: InputMaybe<Scalars['String']>
  dateOfBirth?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  lastName?: InputMaybe<Scalars['String']>
  phoneNumber?: InputMaybe<Scalars['String']>
}

export type UpdatePersonOfInterestPayload = {
  __typename?: 'UpdatePersonOfInterestPayload'
  personOfInterest?: Maybe<PersonOfInterest>
}

export type UpdatePersonOfInterestRoleInput = {
  caseId: Scalars['ID']
  caseRole: CaseRole
  customCaseRoleDescription?: InputMaybe<Scalars['String']>
  personOfInterestId: Scalars['ID']
}

export type UpdateQuickReportInput = {
  formDescription?: InputMaybe<Scalars['String']>
  formTitle?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  logoAssetId?: InputMaybe<Scalars['ID']>
}

export type UpdateQuickReportPayload = {
  __typename?: 'UpdateQuickReportPayload'
  quickReport?: Maybe<QuickReport>
}

/** *Automatically generated.* */
export type UpdateRoleInput = {
  id: Scalars['ID']
  name?: InputMaybe<Scalars['String']>
}

/** *Automatically generated.* */
export type UpdateRolePayload = {
  __typename?: 'UpdateRolePayload'
  role?: Maybe<Role>
}

export type UpdateRuleInput = {
  day?: InputMaybe<Scalars['String']>
  endDate?: InputMaybe<Scalars['String']>
  endTimeOfDay?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  name?: InputMaybe<Scalars['String']>
  repeatInterval?: InputMaybe<RepeatInterval>
  repeatIntervalNum?: InputMaybe<Scalars['Int']>
  ruleLogics?: InputMaybe<CreateRuleLogicInput>
  startTime?: InputMaybe<Scalars['String']>
  /** True = active, False = deactivated */
  status?: InputMaybe<Scalars['Boolean']>
}

export type UpdateRulePayload = {
  __typename?: 'UpdateRulePayload'
  rule?: Maybe<Rule>
}

export type UpdateSavedFilterInput = {
  context?: FilterContext
  filterCriteria?: InputMaybe<FilterCriteriaInput>
  id: Scalars['ID']
  isActive?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
}

export type UpdateSavedFilterPayload = {
  __typename?: 'UpdateSavedFilterPayload'
  savedFilter?: Maybe<SavedFilter>
}

/** *Automatically generated.* */
export type UpdateStandardOperatingProcedureInput = {
  externalId?: InputMaybe<Scalars['String']>
  facilityIds?: InputMaybe<Array<Scalars['ID']>>
  id: Scalars['ID']
  name?: InputMaybe<Scalars['String']>
  type?: InputMaybe<SopType>
}

/** *Automatically generated.* */
export type UpdateStandardOperatingProcedurePayload = {
  __typename?: 'UpdateStandardOperatingProcedurePayload'
  standardOperatingProcedure?: Maybe<StandardOperatingProcedure>
}

/** *Automatically generated.* */
export type UpdateSubscriberMessageInput = {
  createdAt?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

/** *Automatically generated.* */
export type UpdateSubscriberMessagePayload = {
  __typename?: 'UpdateSubscriberMessagePayload'
  subscriberMessage?: Maybe<SubscriberMessage>
}

export type UpdateTailgateGridInput = {
  cameraId: Scalars['ID']
  doorId: Scalars['ID']
  inBox: BoxInput
  outBox: BoxInput
}

export type BoxInput = {
  bottomLeft: PointInput
  bottomRight: PointInput
  topLeft: PointInput
  topRight: PointInput
}

export type PointInput = {
  x: Scalars['Float']
  y: Scalars['Float']
}

export type UpdateTailgateGridPayload = {
  __typename?: 'UpdateTailgateGridPayload'
  camera?: Maybe<Device>
  door?: Maybe<Device>
  inBox?: Maybe<Box>
  outBox?: Maybe<Box>
}

/** *Automatically generated.* */
export type UpdateTechnicianInput = {
  deviceTypes?: InputMaybe<Array<InputMaybe<DeviceType>>>
  email?: InputMaybe<Scalars['String']>
  facilityIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  firstName?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  lastName?: InputMaybe<Scalars['String']>
}

/** *Automatically generated.* */
export type UpdateTechnicianPayload = {
  __typename?: 'UpdateTechnicianPayload'
  technician?: Maybe<Technician>
}

/** *Automatically generated.* */
export type UpdateTourPlanInput = {
  checkpointIds?: InputMaybe<Array<Scalars['ID']>>
  id: Scalars['ID']
  name?: InputMaybe<Scalars['String']>
}

/** *Automatically generated.* */
export type UpdateTourPlanPayload = {
  __typename?: 'UpdateTourPlanPayload'
  tourPlan?: Maybe<TourPlan>
}

/** *Automatically generated.* */
export type UpdateUserInput = {
  designatedFacilityId?: InputMaybe<Scalars['ID']>
  email?: InputMaybe<Scalars['String']>
  facilityId?: InputMaybe<Scalars['ID']>
  firstName?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  lastName?: InputMaybe<Scalars['String']>
  onDuty?: InputMaybe<Scalars['Boolean']>
  roleName?: InputMaybe<Scalars['String']>
}

/** *Automatically generated.* */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload'
  user?: Maybe<User>
}

export type Subscription = {
  __typename?: 'Subscription'
  /**
   * *The current idea is that
   * different users see different message streams.
   * However, that might have to be changed,
   * because the web service might have
   * insufficient information to know which messages
   * are suitable for which user.  This means that the GraphQL schema
   * may have to be changed so that the client uses
   * a filter to specify what messages it wants to get.*
   */
  message?: Maybe<SubscriberMessage>
  /**
   * A substitute for the `message` field.
   *
   * *This is a temporary hack.
   * It was
   * added because of a failure to get
   * our web service to correctly set `__typename`
   * for `message`.*
   */
  messageIncident?: Maybe<Incident>
  onStreamValidationError?: Maybe<Scalars['String']>
}

export type SubscriptionOnStreamValidationErrorArgs = {
  cameraId: Scalars['String']
}

/** *Automatically generated.* */
export type AcknowledgementFilterInput = {
  AND?: InputMaybe<Array<AcknowledgementFilterInput>>
  audioClipId?: InputMaybe<Scalars['ID']>
  audioClipIdIn?: InputMaybe<Array<Scalars['ID']>>
  audioClipIdNot?: InputMaybe<Scalars['ID']>
  audioClipIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  creatorId?: InputMaybe<Scalars['ID']>
  creatorIdIn?: InputMaybe<Array<Scalars['ID']>>
  creatorIdNot?: InputMaybe<Scalars['ID']>
  creatorIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  OR?: InputMaybe<Array<AcknowledgementFilterInput>>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
}

/** *Automatically generated.* */
export type CreditCardFilterInput = {
  addressContain?: InputMaybe<Scalars['String']>
  addressDoNotContain?: InputMaybe<Scalars['String']>
  addressNull?: InputMaybe<Scalars['Boolean']>
  AND?: InputMaybe<Array<CreditCardFilterInput>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  expiryMonth?: InputMaybe<Scalars['Int']>
  expiryMonthGt?: InputMaybe<Scalars['Int']>
  expiryMonthGte?: InputMaybe<Scalars['Int']>
  expiryMonthIn?: InputMaybe<Array<Scalars['Int']>>
  expiryMonthLike?: InputMaybe<Scalars['Int']>
  expiryMonthLt?: InputMaybe<Scalars['Int']>
  expiryMonthLte?: InputMaybe<Scalars['Int']>
  expiryMonthNot?: InputMaybe<Scalars['Int']>
  expiryMonthNotIn?: InputMaybe<Array<Scalars['Int']>>
  expiryYear?: InputMaybe<Scalars['Int']>
  expiryYearGt?: InputMaybe<Scalars['Int']>
  expiryYearGte?: InputMaybe<Scalars['Int']>
  expiryYearIn?: InputMaybe<Array<Scalars['Int']>>
  expiryYearLike?: InputMaybe<Scalars['Int']>
  expiryYearLt?: InputMaybe<Scalars['Int']>
  expiryYearLte?: InputMaybe<Scalars['Int']>
  expiryYearNot?: InputMaybe<Scalars['Int']>
  expiryYearNotIn?: InputMaybe<Array<Scalars['Int']>>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  name?: InputMaybe<Scalars['String']>
  nameGt?: InputMaybe<Scalars['String']>
  nameGte?: InputMaybe<Scalars['String']>
  nameIn?: InputMaybe<Array<Scalars['String']>>
  nameLike?: InputMaybe<Scalars['String']>
  nameLt?: InputMaybe<Scalars['String']>
  nameLte?: InputMaybe<Scalars['String']>
  nameNot?: InputMaybe<Scalars['String']>
  nameNotIn?: InputMaybe<Array<Scalars['String']>>
  number?: InputMaybe<Scalars['String']>
  numberGt?: InputMaybe<Scalars['String']>
  numberGte?: InputMaybe<Scalars['String']>
  numberIn?: InputMaybe<Array<Scalars['String']>>
  numberLike?: InputMaybe<Scalars['String']>
  numberLt?: InputMaybe<Scalars['String']>
  numberLte?: InputMaybe<Scalars['String']>
  numberNot?: InputMaybe<Scalars['String']>
  numberNotIn?: InputMaybe<Array<Scalars['String']>>
  OR?: InputMaybe<Array<CreditCardFilterInput>>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
}

/** *Automatically generated.* */
export type DatabaseResetFilterInput = {
  AND?: InputMaybe<Array<DatabaseResetFilterInput>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  createdBy?: InputMaybe<Scalars['String']>
  createdByGt?: InputMaybe<Scalars['String']>
  createdByGte?: InputMaybe<Scalars['String']>
  createdByIn?: InputMaybe<Array<Scalars['String']>>
  createdByLike?: InputMaybe<Scalars['String']>
  createdByLt?: InputMaybe<Scalars['String']>
  createdByLte?: InputMaybe<Scalars['String']>
  createdByNot?: InputMaybe<Scalars['String']>
  createdByNotIn?: InputMaybe<Array<Scalars['String']>>
  OR?: InputMaybe<Array<DatabaseResetFilterInput>>
}

/** *Automatically generated.* */
export type DeviceRepairOrderFilterInput = {
  AND?: InputMaybe<Array<DeviceRepairOrderFilterInput>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  message?: InputMaybe<Scalars['String']>
  messageGt?: InputMaybe<Scalars['String']>
  messageGte?: InputMaybe<Scalars['String']>
  messageIn?: InputMaybe<Array<Scalars['String']>>
  messageLike?: InputMaybe<Scalars['String']>
  messageLt?: InputMaybe<Scalars['String']>
  messageLte?: InputMaybe<Scalars['String']>
  messageNot?: InputMaybe<Scalars['String']>
  messageNotIn?: InputMaybe<Array<Scalars['String']>>
  OR?: InputMaybe<Array<DeviceRepairOrderFilterInput>>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
}

/** *Automatically generated.* */
export type DoorFilterInput = {
  AND?: InputMaybe<Array<DoorFilterInput>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  externalId?: InputMaybe<Scalars['String']>
  externalIdGt?: InputMaybe<Scalars['String']>
  externalIdGte?: InputMaybe<Scalars['String']>
  externalIdIn?: InputMaybe<Array<Scalars['String']>>
  externalIdLike?: InputMaybe<Scalars['String']>
  externalIdLt?: InputMaybe<Scalars['String']>
  externalIdLte?: InputMaybe<Scalars['String']>
  externalIdNot?: InputMaybe<Scalars['String']>
  externalIdNotIn?: InputMaybe<Array<Scalars['String']>>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  OR?: InputMaybe<Array<DoorFilterInput>>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
}

/** *Automatically generated.* */
export type EmergencyCallingAccessTokenFilterInput = {
  AND?: InputMaybe<Array<EmergencyCallingAccessTokenFilterInput>>
  OR?: InputMaybe<Array<EmergencyCallingAccessTokenFilterInput>>
  token?: InputMaybe<Scalars['String']>
  tokenGt?: InputMaybe<Scalars['String']>
  tokenGte?: InputMaybe<Scalars['String']>
  tokenIn?: InputMaybe<Array<Scalars['String']>>
  tokenLike?: InputMaybe<Scalars['String']>
  tokenLt?: InputMaybe<Scalars['String']>
  tokenLte?: InputMaybe<Scalars['String']>
  tokenNot?: InputMaybe<Scalars['String']>
  tokenNotIn?: InputMaybe<Array<Scalars['String']>>
}

/** *Automatically generated.* */
export type EmergencyCallingFilterInput = {
  AND?: InputMaybe<Array<EmergencyCallingFilterInput>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  facilityId?: InputMaybe<Scalars['ID']>
  facilityIdIn?: InputMaybe<Array<Scalars['ID']>>
  facilityIdNot?: InputMaybe<Scalars['ID']>
  facilityIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  OR?: InputMaybe<Array<EmergencyCallingFilterInput>>
  outboundPhoneNumber?: InputMaybe<Scalars['String']>
  outboundPhoneNumberGt?: InputMaybe<Scalars['String']>
  outboundPhoneNumberGte?: InputMaybe<Scalars['String']>
  outboundPhoneNumberIn?: InputMaybe<Array<Scalars['String']>>
  outboundPhoneNumberLike?: InputMaybe<Scalars['String']>
  outboundPhoneNumberLt?: InputMaybe<Scalars['String']>
  outboundPhoneNumberLte?: InputMaybe<Scalars['String']>
  outboundPhoneNumberNot?: InputMaybe<Scalars['String']>
  outboundPhoneNumberNotIn?: InputMaybe<Array<Scalars['String']>>
  outboundPhoneNumberSid?: InputMaybe<Scalars['String']>
  outboundPhoneNumberSidGt?: InputMaybe<Scalars['String']>
  outboundPhoneNumberSidGte?: InputMaybe<Scalars['String']>
  outboundPhoneNumberSidIn?: InputMaybe<Array<Scalars['String']>>
  outboundPhoneNumberSidLike?: InputMaybe<Scalars['String']>
  outboundPhoneNumberSidLt?: InputMaybe<Scalars['String']>
  outboundPhoneNumberSidLte?: InputMaybe<Scalars['String']>
  outboundPhoneNumberSidNot?: InputMaybe<Scalars['String']>
  outboundPhoneNumberSidNotIn?: InputMaybe<Array<Scalars['String']>>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
}

/** *Automatically generated.* */
export type GatewayFilterInput = {
  AND?: InputMaybe<Array<GatewayFilterInput>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  facilityIdsContain?: InputMaybe<Scalars['ID']>
  facilityIdsDoNotContain?: InputMaybe<Scalars['ID']>
  facilityIdsNull?: InputMaybe<Scalars['Boolean']>
  floorIdsContain?: InputMaybe<Scalars['ID']>
  floorIdsDoNotContain?: InputMaybe<Scalars['ID']>
  floorIdsNull?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  OR?: InputMaybe<Array<GatewayFilterInput>>
  physical?: InputMaybe<Scalars['Boolean']>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
}

/** *Automatically generated.* */
export type IncidentActionFilterInput = {
  AND?: InputMaybe<Array<IncidentActionFilterInput>>
  audioClipId?: InputMaybe<Scalars['ID']>
  audioClipIdIn?: InputMaybe<Array<Scalars['ID']>>
  audioClipIdNot?: InputMaybe<Scalars['ID']>
  audioClipIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  audioMessageId?: InputMaybe<Scalars['ID']>
  audioMessageIdIn?: InputMaybe<Array<Scalars['ID']>>
  audioMessageIdNot?: InputMaybe<Scalars['ID']>
  audioMessageIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  creatorId?: InputMaybe<Scalars['ID']>
  creatorIdIn?: InputMaybe<Array<Scalars['ID']>>
  creatorIdNot?: InputMaybe<Scalars['ID']>
  creatorIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  incidentId?: InputMaybe<Scalars['ID']>
  incidentIdIn?: InputMaybe<Array<Scalars['ID']>>
  incidentIdNot?: InputMaybe<Scalars['ID']>
  incidentIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  message?: InputMaybe<Scalars['String']>
  messageGt?: InputMaybe<Scalars['String']>
  messageGte?: InputMaybe<Scalars['String']>
  messageIn?: InputMaybe<Array<Scalars['String']>>
  messageLike?: InputMaybe<Scalars['String']>
  messageLt?: InputMaybe<Scalars['String']>
  messageLte?: InputMaybe<Scalars['String']>
  messageNot?: InputMaybe<Scalars['String']>
  messageNotIn?: InputMaybe<Array<Scalars['String']>>
  OR?: InputMaybe<Array<IncidentActionFilterInput>>
  technicianId?: InputMaybe<Scalars['ID']>
  technicianIdIn?: InputMaybe<Array<Scalars['ID']>>
  technicianIdNot?: InputMaybe<Scalars['ID']>
  technicianIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  type?: InputMaybe<IncidentActionType>
  typeIn?: InputMaybe<Array<IncidentActionType>>
  typeNot?: InputMaybe<IncidentActionType>
  typeNotIn?: InputMaybe<Array<IncidentActionType>>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
}

/** *Automatically generated.* */
export type IncidentCountMapFilterInput = {
  AND?: InputMaybe<Array<IncidentCountMapFilterInput>>
  camera?: InputMaybe<Scalars['Int']>
  cameraGt?: InputMaybe<Scalars['Int']>
  cameraGte?: InputMaybe<Scalars['Int']>
  cameraIn?: InputMaybe<Array<Scalars['Int']>>
  cameraLike?: InputMaybe<Scalars['Int']>
  cameraLt?: InputMaybe<Scalars['Int']>
  cameraLte?: InputMaybe<Scalars['Int']>
  cameraNot?: InputMaybe<Scalars['Int']>
  cameraNotIn?: InputMaybe<Array<Scalars['Int']>>
  door?: InputMaybe<Scalars['Int']>
  doorGt?: InputMaybe<Scalars['Int']>
  doorGte?: InputMaybe<Scalars['Int']>
  doorIn?: InputMaybe<Array<Scalars['Int']>>
  doorLike?: InputMaybe<Scalars['Int']>
  doorLt?: InputMaybe<Scalars['Int']>
  doorLte?: InputMaybe<Scalars['Int']>
  doorNot?: InputMaybe<Scalars['Int']>
  doorNotIn?: InputMaybe<Array<Scalars['Int']>>
  health?: InputMaybe<Scalars['Int']>
  healthGt?: InputMaybe<Scalars['Int']>
  healthGte?: InputMaybe<Scalars['Int']>
  healthIn?: InputMaybe<Array<Scalars['Int']>>
  healthLike?: InputMaybe<Scalars['Int']>
  healthLt?: InputMaybe<Scalars['Int']>
  healthLte?: InputMaybe<Scalars['Int']>
  healthNot?: InputMaybe<Scalars['Int']>
  healthNotIn?: InputMaybe<Array<Scalars['Int']>>
  OR?: InputMaybe<Array<IncidentCountMapFilterInput>>
  overall?: InputMaybe<Scalars['Int']>
  overallGt?: InputMaybe<Scalars['Int']>
  overallGte?: InputMaybe<Scalars['Int']>
  overallIn?: InputMaybe<Array<Scalars['Int']>>
  overallLike?: InputMaybe<Scalars['Int']>
  overallLt?: InputMaybe<Scalars['Int']>
  overallLte?: InputMaybe<Scalars['Int']>
  overallNot?: InputMaybe<Scalars['Int']>
  overallNotIn?: InputMaybe<Array<Scalars['Int']>>
}

/** *Automatically generated.* */
export type IncidentEventFilterInput = {
  AND?: InputMaybe<Array<IncidentEventFilterInput>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  OR?: InputMaybe<Array<IncidentEventFilterInput>>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
}

/** *Automatically generated.* */
export type IncidentInformationSolicitationFilterInput = {
  AND?: InputMaybe<Array<IncidentInformationSolicitationFilterInput>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  creatorId?: InputMaybe<Scalars['ID']>
  creatorIdIn?: InputMaybe<Array<Scalars['ID']>>
  creatorIdNot?: InputMaybe<Scalars['ID']>
  creatorIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  incidentId?: InputMaybe<Scalars['ID']>
  incidentIdIn?: InputMaybe<Array<Scalars['ID']>>
  incidentIdNot?: InputMaybe<Scalars['ID']>
  incidentIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  message?: InputMaybe<Scalars['String']>
  messageGt?: InputMaybe<Scalars['String']>
  messageGte?: InputMaybe<Scalars['String']>
  messageIn?: InputMaybe<Array<Scalars['String']>>
  messageLike?: InputMaybe<Scalars['String']>
  messageLt?: InputMaybe<Scalars['String']>
  messageLte?: InputMaybe<Scalars['String']>
  messageNot?: InputMaybe<Scalars['String']>
  messageNotIn?: InputMaybe<Array<Scalars['String']>>
  OR?: InputMaybe<Array<IncidentInformationSolicitationFilterInput>>
  recipientIdsContain?: InputMaybe<Scalars['ID']>
  recipientIdsDoNotContain?: InputMaybe<Scalars['ID']>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
}

/** *Automatically generated.* */
export type IncidentOccurrenceFilterInput = {
  AND?: InputMaybe<Array<IncidentOccurrenceFilterInput>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  OR?: InputMaybe<Array<IncidentOccurrenceFilterInput>>
}

/** *Automatically generated.* */
export type IncidentOfferAcceptanceFilterInput = {
  AND?: InputMaybe<Array<IncidentOfferAcceptanceFilterInput>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  OR?: InputMaybe<Array<IncidentOfferAcceptanceFilterInput>>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
}

/** *Automatically generated.* */
export type IncidentOfferFilterInput = {
  AND?: InputMaybe<Array<IncidentOfferFilterInput>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  creatorId?: InputMaybe<Scalars['ID']>
  creatorIdIn?: InputMaybe<Array<Scalars['ID']>>
  creatorIdNot?: InputMaybe<Scalars['ID']>
  creatorIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  incidentId?: InputMaybe<Scalars['ID']>
  incidentIdIn?: InputMaybe<Array<Scalars['ID']>>
  incidentIdNot?: InputMaybe<Scalars['ID']>
  incidentIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  message?: InputMaybe<Scalars['String']>
  messageGt?: InputMaybe<Scalars['String']>
  messageGte?: InputMaybe<Scalars['String']>
  messageIn?: InputMaybe<Array<Scalars['String']>>
  messageLike?: InputMaybe<Scalars['String']>
  messageLt?: InputMaybe<Scalars['String']>
  messageLte?: InputMaybe<Scalars['String']>
  messageNot?: InputMaybe<Scalars['String']>
  messageNotIn?: InputMaybe<Array<Scalars['String']>>
  OR?: InputMaybe<Array<IncidentOfferFilterInput>>
  recipientIdsContain?: InputMaybe<Scalars['ID']>
  recipientIdsDoNotContain?: InputMaybe<Scalars['ID']>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
}

/** *Automatically generated.* */
export type IncidentOfferToGuardAcceptanceFilterInput = {
  AND?: InputMaybe<Array<IncidentOfferToGuardAcceptanceFilterInput>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  OR?: InputMaybe<Array<IncidentOfferToGuardAcceptanceFilterInput>>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
}

/** *Automatically generated.* */
export type IncidentOfferToGuardFilterInput = {
  AND?: InputMaybe<Array<IncidentOfferToGuardFilterInput>>
  audioMessageId?: InputMaybe<Scalars['ID']>
  audioMessageIdIn?: InputMaybe<Array<Scalars['ID']>>
  audioMessageIdNot?: InputMaybe<Scalars['ID']>
  audioMessageIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  creatorId?: InputMaybe<Scalars['ID']>
  creatorIdIn?: InputMaybe<Array<Scalars['ID']>>
  creatorIdNot?: InputMaybe<Scalars['ID']>
  creatorIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  incidentId?: InputMaybe<Scalars['ID']>
  incidentIdIn?: InputMaybe<Array<Scalars['ID']>>
  incidentIdNot?: InputMaybe<Scalars['ID']>
  incidentIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  OR?: InputMaybe<Array<IncidentOfferToGuardFilterInput>>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
}

/** *Automatically generated.* */
export type IncidentsByFacilityResultFilterInput = {
  AND?: InputMaybe<Array<IncidentsByFacilityResultFilterInput>>
  count?: InputMaybe<Scalars['Int']>
  countGt?: InputMaybe<Scalars['Int']>
  countGte?: InputMaybe<Scalars['Int']>
  countIn?: InputMaybe<Array<Scalars['Int']>>
  countLike?: InputMaybe<Scalars['Int']>
  countLt?: InputMaybe<Scalars['Int']>
  countLte?: InputMaybe<Scalars['Int']>
  countNot?: InputMaybe<Scalars['Int']>
  countNotIn?: InputMaybe<Array<Scalars['Int']>>
  facilityName?: InputMaybe<Scalars['String']>
  facilityNameGt?: InputMaybe<Scalars['String']>
  facilityNameGte?: InputMaybe<Scalars['String']>
  facilityNameIn?: InputMaybe<Array<Scalars['String']>>
  facilityNameLike?: InputMaybe<Scalars['String']>
  facilityNameLt?: InputMaybe<Scalars['String']>
  facilityNameLte?: InputMaybe<Scalars['String']>
  facilityNameNot?: InputMaybe<Scalars['String']>
  facilityNameNotIn?: InputMaybe<Array<Scalars['String']>>
  OR?: InputMaybe<Array<IncidentsByFacilityResultFilterInput>>
}

/** *Automatically generated.* */
export type IncidentsByTypeBetweenDateResultFilterInput = {
  AND?: InputMaybe<Array<IncidentsByTypeBetweenDateResultFilterInput>>
  OR?: InputMaybe<Array<IncidentsByTypeBetweenDateResultFilterInput>>
  totalCount?: InputMaybe<Scalars['Int']>
  totalCountGt?: InputMaybe<Scalars['Int']>
  totalCountGte?: InputMaybe<Scalars['Int']>
  totalCountIn?: InputMaybe<Array<Scalars['Int']>>
  totalCountLike?: InputMaybe<Scalars['Int']>
  totalCountLt?: InputMaybe<Scalars['Int']>
  totalCountLte?: InputMaybe<Scalars['Int']>
  totalCountNot?: InputMaybe<Scalars['Int']>
  totalCountNotIn?: InputMaybe<Array<Scalars['Int']>>
}

/** *Automatically generated.* */
export type IncidentsByTypeCountFilterInput = {
  AND?: InputMaybe<Array<IncidentsByTypeCountFilterInput>>
  count?: InputMaybe<Scalars['Int']>
  countGt?: InputMaybe<Scalars['Int']>
  countGte?: InputMaybe<Scalars['Int']>
  countIn?: InputMaybe<Array<Scalars['Int']>>
  countLike?: InputMaybe<Scalars['Int']>
  countLt?: InputMaybe<Scalars['Int']>
  countLte?: InputMaybe<Scalars['Int']>
  countNot?: InputMaybe<Scalars['Int']>
  countNotIn?: InputMaybe<Array<Scalars['Int']>>
  OR?: InputMaybe<Array<IncidentsByTypeCountFilterInput>>
  type?: InputMaybe<Scalars['String']>
  typeGt?: InputMaybe<Scalars['String']>
  typeGte?: InputMaybe<Scalars['String']>
  typeIn?: InputMaybe<Array<Scalars['String']>>
  typeLike?: InputMaybe<Scalars['String']>
  typeLt?: InputMaybe<Scalars['String']>
  typeLte?: InputMaybe<Scalars['String']>
  typeNot?: InputMaybe<Scalars['String']>
  typeNotIn?: InputMaybe<Array<Scalars['String']>>
}

/** *Automatically generated.* */
export type IncidentShareFilterInput = {
  AND?: InputMaybe<Array<IncidentShareFilterInput>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  incidentId?: InputMaybe<Scalars['ID']>
  incidentIdIn?: InputMaybe<Array<Scalars['ID']>>
  incidentIdNot?: InputMaybe<Scalars['ID']>
  incidentIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  OR?: InputMaybe<Array<IncidentShareFilterInput>>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
}

/** *Automatically generated.* */
export type IncidentsTypesByDateFilterInput = {
  AND?: InputMaybe<Array<IncidentsTypesByDateFilterInput>>
  date?: InputMaybe<Scalars['String']>
  dateGt?: InputMaybe<Scalars['String']>
  dateGte?: InputMaybe<Scalars['String']>
  dateIn?: InputMaybe<Array<Scalars['String']>>
  dateLike?: InputMaybe<Scalars['String']>
  dateLt?: InputMaybe<Scalars['String']>
  dateLte?: InputMaybe<Scalars['String']>
  dateNot?: InputMaybe<Scalars['String']>
  dateNotIn?: InputMaybe<Array<Scalars['String']>>
  day?: InputMaybe<Scalars['String']>
  dayGt?: InputMaybe<Scalars['String']>
  dayGte?: InputMaybe<Scalars['String']>
  dayIn?: InputMaybe<Array<Scalars['String']>>
  dayLike?: InputMaybe<Scalars['String']>
  dayLt?: InputMaybe<Scalars['String']>
  dayLte?: InputMaybe<Scalars['String']>
  dayNot?: InputMaybe<Scalars['String']>
  dayNotIn?: InputMaybe<Array<Scalars['String']>>
  OR?: InputMaybe<Array<IncidentsTypesByDateFilterInput>>
}

/** *Automatically generated.* */
export type IncidentTimePeriodStatsFilterInput = {
  AND?: InputMaybe<Array<IncidentTimePeriodStatsFilterInput>>
  avgTimeToAcknowledge?: InputMaybe<Scalars['Float']>
  avgTimeToAcknowledgeGt?: InputMaybe<Scalars['Float']>
  avgTimeToAcknowledgeGte?: InputMaybe<Scalars['Float']>
  avgTimeToAcknowledgeIn?: InputMaybe<Array<Scalars['Float']>>
  avgTimeToAcknowledgeLike?: InputMaybe<Scalars['Float']>
  avgTimeToAcknowledgeLt?: InputMaybe<Scalars['Float']>
  avgTimeToAcknowledgeLte?: InputMaybe<Scalars['Float']>
  avgTimeToAcknowledgeNot?: InputMaybe<Scalars['Float']>
  avgTimeToAcknowledgeNotIn?: InputMaybe<Array<Scalars['Float']>>
  avgTimeToResolve?: InputMaybe<Scalars['Float']>
  avgTimeToResolveGt?: InputMaybe<Scalars['Float']>
  avgTimeToResolveGte?: InputMaybe<Scalars['Float']>
  avgTimeToResolveIn?: InputMaybe<Array<Scalars['Float']>>
  avgTimeToResolveLike?: InputMaybe<Scalars['Float']>
  avgTimeToResolveLt?: InputMaybe<Scalars['Float']>
  avgTimeToResolveLte?: InputMaybe<Scalars['Float']>
  avgTimeToResolveNot?: InputMaybe<Scalars['Float']>
  avgTimeToResolveNotIn?: InputMaybe<Array<Scalars['Float']>>
  fractionResolved?: InputMaybe<Scalars['Float']>
  fractionResolvedGt?: InputMaybe<Scalars['Float']>
  fractionResolvedGte?: InputMaybe<Scalars['Float']>
  fractionResolvedIn?: InputMaybe<Array<Scalars['Float']>>
  fractionResolvedLike?: InputMaybe<Scalars['Float']>
  fractionResolvedLt?: InputMaybe<Scalars['Float']>
  fractionResolvedLte?: InputMaybe<Scalars['Float']>
  fractionResolvedNot?: InputMaybe<Scalars['Float']>
  fractionResolvedNotIn?: InputMaybe<Array<Scalars['Float']>>
  numUnresolved?: InputMaybe<Scalars['Int']>
  numUnresolvedGt?: InputMaybe<Scalars['Int']>
  numUnresolvedGte?: InputMaybe<Scalars['Int']>
  numUnresolvedIn?: InputMaybe<Array<Scalars['Int']>>
  numUnresolvedLike?: InputMaybe<Scalars['Int']>
  numUnresolvedLt?: InputMaybe<Scalars['Int']>
  numUnresolvedLte?: InputMaybe<Scalars['Int']>
  numUnresolvedNot?: InputMaybe<Scalars['Int']>
  numUnresolvedNotIn?: InputMaybe<Array<Scalars['Int']>>
  OR?: InputMaybe<Array<IncidentTimePeriodStatsFilterInput>>
}

/** *Automatically generated.* */
export type IntervalFilterInput = {
  AND?: InputMaybe<Array<IntervalFilterInput>>
  endTime?: InputMaybe<Scalars['String']>
  endTimeGt?: InputMaybe<Scalars['String']>
  endTimeGte?: InputMaybe<Scalars['String']>
  endTimeIn?: InputMaybe<Array<Scalars['String']>>
  endTimeLike?: InputMaybe<Scalars['String']>
  endTimeLt?: InputMaybe<Scalars['String']>
  endTimeLte?: InputMaybe<Scalars['String']>
  endTimeNot?: InputMaybe<Scalars['String']>
  endTimeNotIn?: InputMaybe<Array<Scalars['String']>>
  OR?: InputMaybe<Array<IntervalFilterInput>>
  startTime?: InputMaybe<Scalars['String']>
  startTimeGt?: InputMaybe<Scalars['String']>
  startTimeGte?: InputMaybe<Scalars['String']>
  startTimeIn?: InputMaybe<Array<Scalars['String']>>
  startTimeLike?: InputMaybe<Scalars['String']>
  startTimeLt?: InputMaybe<Scalars['String']>
  startTimeLte?: InputMaybe<Scalars['String']>
  startTimeNot?: InputMaybe<Scalars['String']>
  startTimeNotIn?: InputMaybe<Array<Scalars['String']>>
}

/** *Automatically generated.* */
export type InvoiceFilterInput = {
  AND?: InputMaybe<Array<InvoiceFilterInput>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  OR?: InputMaybe<Array<InvoiceFilterInput>>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
}

/** *Automatically generated.* */
export type KpiAggregateQueryResultFilterInput = {
  AND?: InputMaybe<Array<KpiAggregateQueryResultFilterInput>>
  count?: InputMaybe<Scalars['Int']>
  countGt?: InputMaybe<Scalars['Int']>
  countGte?: InputMaybe<Scalars['Int']>
  countIn?: InputMaybe<Array<Scalars['Int']>>
  countLike?: InputMaybe<Scalars['Int']>
  countLt?: InputMaybe<Scalars['Int']>
  countLte?: InputMaybe<Scalars['Int']>
  countNot?: InputMaybe<Scalars['Int']>
  countNotIn?: InputMaybe<Array<Scalars['Int']>>
  label?: InputMaybe<Scalars['String']>
  labelGt?: InputMaybe<Scalars['String']>
  labelGte?: InputMaybe<Scalars['String']>
  labelIn?: InputMaybe<Array<Scalars['String']>>
  labelLike?: InputMaybe<Scalars['String']>
  labelLt?: InputMaybe<Scalars['String']>
  labelLte?: InputMaybe<Scalars['String']>
  labelNot?: InputMaybe<Scalars['String']>
  labelNotIn?: InputMaybe<Array<Scalars['String']>>
  OR?: InputMaybe<Array<KpiAggregateQueryResultFilterInput>>
}

/** *Automatically generated.* */
export type KpiQueryResultFilterInput = {
  AND?: InputMaybe<Array<KpiQueryResultFilterInput>>
  current?: InputMaybe<Scalars['Int']>
  currentGt?: InputMaybe<Scalars['Int']>
  currentGte?: InputMaybe<Scalars['Int']>
  currentIn?: InputMaybe<Array<Scalars['Int']>>
  currentLike?: InputMaybe<Scalars['Int']>
  currentLt?: InputMaybe<Scalars['Int']>
  currentLte?: InputMaybe<Scalars['Int']>
  currentNot?: InputMaybe<Scalars['Int']>
  currentNotIn?: InputMaybe<Array<Scalars['Int']>>
  OR?: InputMaybe<Array<KpiQueryResultFilterInput>>
  past?: InputMaybe<Scalars['Int']>
  pastGt?: InputMaybe<Scalars['Int']>
  pastGte?: InputMaybe<Scalars['Int']>
  pastIn?: InputMaybe<Array<Scalars['Int']>>
  pastLike?: InputMaybe<Scalars['Int']>
  pastLt?: InputMaybe<Scalars['Int']>
  pastLte?: InputMaybe<Scalars['Int']>
  pastNot?: InputMaybe<Scalars['Int']>
  pastNotIn?: InputMaybe<Array<Scalars['Int']>>
}

/** *Automatically generated.* */
export type MessageTargetFilterInput = {
  AND?: InputMaybe<Array<MessageTargetFilterInput>>
  facilityId?: InputMaybe<Scalars['ID']>
  facilityIdIn?: InputMaybe<Array<Scalars['ID']>>
  facilityIdNot?: InputMaybe<Scalars['ID']>
  facilityIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  incidentId?: InputMaybe<Scalars['ID']>
  incidentIdIn?: InputMaybe<Array<Scalars['ID']>>
  incidentIdNot?: InputMaybe<Scalars['ID']>
  incidentIdNotIn?: InputMaybe<Array<Scalars['ID']>>
  onShift?: InputMaybe<Scalars['Boolean']>
  OR?: InputMaybe<Array<MessageTargetFilterInput>>
  recipientIdsContain?: InputMaybe<Scalars['ID']>
  recipientIdsDoNotContain?: InputMaybe<Scalars['ID']>
  recipientIdsNull?: InputMaybe<Scalars['Boolean']>
}

/** *Automatically generated.* */
export type MimeTypedObjectFilterInput = {
  AND?: InputMaybe<Array<MimeTypedObjectFilterInput>>
  base64Content?: InputMaybe<Scalars['String']>
  base64ContentGt?: InputMaybe<Scalars['String']>
  base64ContentGte?: InputMaybe<Scalars['String']>
  base64ContentIn?: InputMaybe<Array<Scalars['String']>>
  base64ContentLike?: InputMaybe<Scalars['String']>
  base64ContentLt?: InputMaybe<Scalars['String']>
  base64ContentLte?: InputMaybe<Scalars['String']>
  base64ContentNot?: InputMaybe<Scalars['String']>
  base64ContentNotIn?: InputMaybe<Array<Scalars['String']>>
  mimeType?: InputMaybe<Scalars['String']>
  mimeTypeGt?: InputMaybe<Scalars['String']>
  mimeTypeGte?: InputMaybe<Scalars['String']>
  mimeTypeIn?: InputMaybe<Array<Scalars['String']>>
  mimeTypeLike?: InputMaybe<Scalars['String']>
  mimeTypeLt?: InputMaybe<Scalars['String']>
  mimeTypeLte?: InputMaybe<Scalars['String']>
  mimeTypeNot?: InputMaybe<Scalars['String']>
  mimeTypeNotIn?: InputMaybe<Array<Scalars['String']>>
  OR?: InputMaybe<Array<MimeTypedObjectFilterInput>>
}

/** *Automatically generated.* */
export type OrganizationFilterInput = {
  AND?: InputMaybe<Array<OrganizationFilterInput>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  name?: InputMaybe<Scalars['String']>
  nameGt?: InputMaybe<Scalars['String']>
  nameGte?: InputMaybe<Scalars['String']>
  nameIn?: InputMaybe<Array<Scalars['String']>>
  nameLike?: InputMaybe<Scalars['String']>
  nameLt?: InputMaybe<Scalars['String']>
  nameLte?: InputMaybe<Scalars['String']>
  nameNot?: InputMaybe<Scalars['String']>
  nameNotIn?: InputMaybe<Array<Scalars['String']>>
  OR?: InputMaybe<Array<OrganizationFilterInput>>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
}

/** *Automatically generated.* */
export type OrganizationSettingsFilterInput = {
  AND?: InputMaybe<Array<OrganizationSettingsFilterInput>>
  cameraOfflineDurationUnit?: InputMaybe<DurationUnit>
  cameraOfflineDurationUnitIn?: InputMaybe<Array<DurationUnit>>
  cameraOfflineDurationUnitNot?: InputMaybe<DurationUnit>
  cameraOfflineDurationUnitNotIn?: InputMaybe<Array<DurationUnit>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  emailReplyToAddress?: InputMaybe<Scalars['String']>
  emailReplyToAddressGt?: InputMaybe<Scalars['String']>
  emailReplyToAddressGte?: InputMaybe<Scalars['String']>
  emailReplyToAddressIn?: InputMaybe<Array<Scalars['String']>>
  emailReplyToAddressLike?: InputMaybe<Scalars['String']>
  emailReplyToAddressLt?: InputMaybe<Scalars['String']>
  emailReplyToAddressLte?: InputMaybe<Scalars['String']>
  emailReplyToAddressNot?: InputMaybe<Scalars['String']>
  emailReplyToAddressNotIn?: InputMaybe<Array<Scalars['String']>>
  emailReplyToName?: InputMaybe<Scalars['String']>
  emailReplyToNameGt?: InputMaybe<Scalars['String']>
  emailReplyToNameGte?: InputMaybe<Scalars['String']>
  emailReplyToNameIn?: InputMaybe<Array<Scalars['String']>>
  emailReplyToNameLike?: InputMaybe<Scalars['String']>
  emailReplyToNameLt?: InputMaybe<Scalars['String']>
  emailReplyToNameLte?: InputMaybe<Scalars['String']>
  emailReplyToNameNot?: InputMaybe<Scalars['String']>
  emailReplyToNameNotIn?: InputMaybe<Array<Scalars['String']>>
  emailSenderName?: InputMaybe<Scalars['String']>
  emailSenderNameGt?: InputMaybe<Scalars['String']>
  emailSenderNameGte?: InputMaybe<Scalars['String']>
  emailSenderNameIn?: InputMaybe<Array<Scalars['String']>>
  emailSenderNameLike?: InputMaybe<Scalars['String']>
  emailSenderNameLt?: InputMaybe<Scalars['String']>
  emailSenderNameLte?: InputMaybe<Scalars['String']>
  emailSenderNameNot?: InputMaybe<Scalars['String']>
  emailSenderNameNotIn?: InputMaybe<Array<Scalars['String']>>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  inboundPhoneNumber?: InputMaybe<Scalars['String']>
  inboundPhoneNumberGt?: InputMaybe<Scalars['String']>
  inboundPhoneNumberGte?: InputMaybe<Scalars['String']>
  inboundPhoneNumberIn?: InputMaybe<Array<Scalars['String']>>
  inboundPhoneNumberLike?: InputMaybe<Scalars['String']>
  inboundPhoneNumberLt?: InputMaybe<Scalars['String']>
  inboundPhoneNumberLte?: InputMaybe<Scalars['String']>
  inboundPhoneNumberNot?: InputMaybe<Scalars['String']>
  inboundPhoneNumberNotIn?: InputMaybe<Array<Scalars['String']>>
  minimumCameraOfflineDuration?: InputMaybe<Scalars['Int']>
  minimumCameraOfflineDurationGt?: InputMaybe<Scalars['Int']>
  minimumCameraOfflineDurationGte?: InputMaybe<Scalars['Int']>
  minimumCameraOfflineDurationIn?: InputMaybe<Array<Scalars['Int']>>
  minimumCameraOfflineDurationLike?: InputMaybe<Scalars['Int']>
  minimumCameraOfflineDurationLt?: InputMaybe<Scalars['Int']>
  minimumCameraOfflineDurationLte?: InputMaybe<Scalars['Int']>
  minimumCameraOfflineDurationNot?: InputMaybe<Scalars['Int']>
  minimumCameraOfflineDurationNotIn?: InputMaybe<Array<Scalars['Int']>>
  OR?: InputMaybe<Array<OrganizationSettingsFilterInput>>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
}

/** *Automatically generated.* */
export type PartialCreditCardFilterInput = {
  addressContain?: InputMaybe<Scalars['String']>
  addressDoNotContain?: InputMaybe<Scalars['String']>
  addressNull?: InputMaybe<Scalars['Boolean']>
  AND?: InputMaybe<Array<PartialCreditCardFilterInput>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  endOfNumber?: InputMaybe<Scalars['String']>
  endOfNumberGt?: InputMaybe<Scalars['String']>
  endOfNumberGte?: InputMaybe<Scalars['String']>
  endOfNumberIn?: InputMaybe<Array<Scalars['String']>>
  endOfNumberLike?: InputMaybe<Scalars['String']>
  endOfNumberLt?: InputMaybe<Scalars['String']>
  endOfNumberLte?: InputMaybe<Scalars['String']>
  endOfNumberNot?: InputMaybe<Scalars['String']>
  endOfNumberNotIn?: InputMaybe<Array<Scalars['String']>>
  expiryMonth?: InputMaybe<Scalars['Int']>
  expiryMonthGt?: InputMaybe<Scalars['Int']>
  expiryMonthGte?: InputMaybe<Scalars['Int']>
  expiryMonthIn?: InputMaybe<Array<Scalars['Int']>>
  expiryMonthLike?: InputMaybe<Scalars['Int']>
  expiryMonthLt?: InputMaybe<Scalars['Int']>
  expiryMonthLte?: InputMaybe<Scalars['Int']>
  expiryMonthNot?: InputMaybe<Scalars['Int']>
  expiryMonthNotIn?: InputMaybe<Array<Scalars['Int']>>
  expiryYear?: InputMaybe<Scalars['Int']>
  expiryYearGt?: InputMaybe<Scalars['Int']>
  expiryYearGte?: InputMaybe<Scalars['Int']>
  expiryYearIn?: InputMaybe<Array<Scalars['Int']>>
  expiryYearLike?: InputMaybe<Scalars['Int']>
  expiryYearLt?: InputMaybe<Scalars['Int']>
  expiryYearLte?: InputMaybe<Scalars['Int']>
  expiryYearNot?: InputMaybe<Scalars['Int']>
  expiryYearNotIn?: InputMaybe<Array<Scalars['Int']>>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  name?: InputMaybe<Scalars['String']>
  nameGt?: InputMaybe<Scalars['String']>
  nameGte?: InputMaybe<Scalars['String']>
  nameIn?: InputMaybe<Array<Scalars['String']>>
  nameLike?: InputMaybe<Scalars['String']>
  nameLt?: InputMaybe<Scalars['String']>
  nameLte?: InputMaybe<Scalars['String']>
  nameNot?: InputMaybe<Scalars['String']>
  nameNotIn?: InputMaybe<Array<Scalars['String']>>
  OR?: InputMaybe<Array<PartialCreditCardFilterInput>>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
}

/** *Automatically generated.* */
export type PermissionFilterInput = {
  AND?: InputMaybe<Array<PermissionFilterInput>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  id?: InputMaybe<Scalars['ID']>
  idIn?: InputMaybe<Array<Scalars['ID']>>
  idNot?: InputMaybe<Scalars['ID']>
  idNotIn?: InputMaybe<Array<Scalars['ID']>>
  name?: InputMaybe<Scalars['String']>
  nameGt?: InputMaybe<Scalars['String']>
  nameGte?: InputMaybe<Scalars['String']>
  nameIn?: InputMaybe<Array<Scalars['String']>>
  nameLike?: InputMaybe<Scalars['String']>
  nameLt?: InputMaybe<Scalars['String']>
  nameLte?: InputMaybe<Scalars['String']>
  nameNot?: InputMaybe<Scalars['String']>
  nameNotIn?: InputMaybe<Array<Scalars['String']>>
  OR?: InputMaybe<Array<PermissionFilterInput>>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
}

/**
 * A wrapper for a URL for HLS
 * ([HTTP Live Streaming](https://en.wikipedia.org/wiki/HTTP_Live_Streaming)).
 */
export type StreamingUrlWrapperHls = StreamingUrlWrapperInterface & {
  __typename?: 'StreamingUrlWrapperHls'
  /**
   * The time at which the URL will be invalid
   * and needs to be requested again.
   * Example: 2021-10-29T08:05:47.978Z.
   */
  expiryTime?: Maybe<Scalars['String']>
  url: Scalars['String']
}

/** *Automatically generated.* */
export type StreamingUrlWrapperHlsFilterInput = {
  AND?: InputMaybe<Array<StreamingUrlWrapperHlsFilterInput>>
  expiryTime?: InputMaybe<Scalars['String']>
  expiryTimeGt?: InputMaybe<Scalars['String']>
  expiryTimeGte?: InputMaybe<Scalars['String']>
  expiryTimeIn?: InputMaybe<Array<Scalars['String']>>
  expiryTimeLike?: InputMaybe<Scalars['String']>
  expiryTimeLt?: InputMaybe<Scalars['String']>
  expiryTimeLte?: InputMaybe<Scalars['String']>
  expiryTimeNot?: InputMaybe<Scalars['String']>
  expiryTimeNotIn?: InputMaybe<Array<Scalars['String']>>
  OR?: InputMaybe<Array<StreamingUrlWrapperHlsFilterInput>>
  url?: InputMaybe<Scalars['String']>
  urlGt?: InputMaybe<Scalars['String']>
  urlGte?: InputMaybe<Scalars['String']>
  urlIn?: InputMaybe<Array<Scalars['String']>>
  urlLike?: InputMaybe<Scalars['String']>
  urlLt?: InputMaybe<Scalars['String']>
  urlLte?: InputMaybe<Scalars['String']>
  urlNot?: InputMaybe<Scalars['String']>
  urlNotIn?: InputMaybe<Array<Scalars['String']>>
}

/** *Automatically generated.* */
export type StreamingUrlWrapperInterfaceFilterInput = {
  AND?: InputMaybe<Array<StreamingUrlWrapperInterfaceFilterInput>>
  expiryTime?: InputMaybe<Scalars['String']>
  expiryTimeGt?: InputMaybe<Scalars['String']>
  expiryTimeGte?: InputMaybe<Scalars['String']>
  expiryTimeIn?: InputMaybe<Array<Scalars['String']>>
  expiryTimeLike?: InputMaybe<Scalars['String']>
  expiryTimeLt?: InputMaybe<Scalars['String']>
  expiryTimeLte?: InputMaybe<Scalars['String']>
  expiryTimeNot?: InputMaybe<Scalars['String']>
  expiryTimeNotIn?: InputMaybe<Array<Scalars['String']>>
  OR?: InputMaybe<Array<StreamingUrlWrapperInterfaceFilterInput>>
  url?: InputMaybe<Scalars['String']>
  urlGt?: InputMaybe<Scalars['String']>
  urlGte?: InputMaybe<Scalars['String']>
  urlIn?: InputMaybe<Array<Scalars['String']>>
  urlLike?: InputMaybe<Scalars['String']>
  urlLt?: InputMaybe<Scalars['String']>
  urlLte?: InputMaybe<Scalars['String']>
  urlNot?: InputMaybe<Scalars['String']>
  urlNotIn?: InputMaybe<Array<Scalars['String']>>
}

/** *Automatically generated.* */
export type SystemActionFilterInput = {
  AND?: InputMaybe<Array<SystemActionFilterInput>>
  createdAt?: InputMaybe<Scalars['String']>
  createdAtGt?: InputMaybe<Scalars['String']>
  createdAtGte?: InputMaybe<Scalars['String']>
  createdAtIn?: InputMaybe<Array<Scalars['String']>>
  createdAtLike?: InputMaybe<Scalars['String']>
  createdAtLt?: InputMaybe<Scalars['String']>
  createdAtLte?: InputMaybe<Scalars['String']>
  createdAtNot?: InputMaybe<Scalars['String']>
  createdAtNotIn?: InputMaybe<Array<Scalars['String']>>
  OR?: InputMaybe<Array<SystemActionFilterInput>>
  systemMessage?: InputMaybe<Scalars['String']>
  systemMessageGt?: InputMaybe<Scalars['String']>
  systemMessageGte?: InputMaybe<Scalars['String']>
  systemMessageIn?: InputMaybe<Array<Scalars['String']>>
  systemMessageLike?: InputMaybe<Scalars['String']>
  systemMessageLt?: InputMaybe<Scalars['String']>
  systemMessageLte?: InputMaybe<Scalars['String']>
  systemMessageNot?: InputMaybe<Scalars['String']>
  systemMessageNotIn?: InputMaybe<Array<Scalars['String']>>
  updatedAt?: InputMaybe<Scalars['String']>
  updatedAtGt?: InputMaybe<Scalars['String']>
  updatedAtGte?: InputMaybe<Scalars['String']>
  updatedAtIn?: InputMaybe<Array<Scalars['String']>>
  updatedAtLike?: InputMaybe<Scalars['String']>
  updatedAtLt?: InputMaybe<Scalars['String']>
  updatedAtLte?: InputMaybe<Scalars['String']>
  updatedAtNot?: InputMaybe<Scalars['String']>
  updatedAtNotIn?: InputMaybe<Array<Scalars['String']>>
}

/** *Automatically generated.* */
export type UrlContentFilterInput = {
  AND?: InputMaybe<Array<UrlContentFilterInput>>
  minExpiryTime?: InputMaybe<Scalars['String']>
  minExpiryTimeGt?: InputMaybe<Scalars['String']>
  minExpiryTimeGte?: InputMaybe<Scalars['String']>
  minExpiryTimeIn?: InputMaybe<Array<Scalars['String']>>
  minExpiryTimeLike?: InputMaybe<Scalars['String']>
  minExpiryTimeLt?: InputMaybe<Scalars['String']>
  minExpiryTimeLte?: InputMaybe<Scalars['String']>
  minExpiryTimeNot?: InputMaybe<Scalars['String']>
  minExpiryTimeNotIn?: InputMaybe<Array<Scalars['String']>>
  OR?: InputMaybe<Array<UrlContentFilterInput>>
  url?: InputMaybe<Scalars['String']>
  urlGt?: InputMaybe<Scalars['String']>
  urlGte?: InputMaybe<Scalars['String']>
  urlIn?: InputMaybe<Array<Scalars['String']>>
  urlLike?: InputMaybe<Scalars['String']>
  urlLt?: InputMaybe<Scalars['String']>
  urlLte?: InputMaybe<Scalars['String']>
  urlNot?: InputMaybe<Scalars['String']>
  urlNotIn?: InputMaybe<Array<Scalars['String']>>
}
